import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BaseContainerGame from '../../../../components/shared/BaseContainerGame';
import BlackButton from '../../../../components/shared/BlackButton';
import CenterDiv from '../../../../components/shared/CenterDiv';
import MaxWidthAdjust from '../../../../components/shared/MaxWidthAdjust';
import { BODY_PADDING, collectionName, PAGE_HOME } from '../../../../consts';
import { FirebaseContext } from '../../../../contexts';
import { db } from '../../../../firebase';
import ResultGoal from '../../../Game/components/ResultGoal';
import ScoreLineChart from '../../../Game/components/ScoreLineChart';

const GameHistoryOne: React.FC = () => {
  const { user } = useContext(FirebaseContext);
  const history = useHistory();
  const location = useLocation();
  const [game, setGame] = useState<any>(null);

  const myId = location.pathname.split('/').slice(-2)[0];
  const gameId = location.pathname.split('/').slice(-1)[0];

  const formatTime = (date: any) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hh = ('0' + date.getHours()).slice(-2);
    const mm = ('0' + date.getMinutes()).slice(-2);
    const ss = ('0' + date.getSeconds()).slice(-2);

    return `${year}/${month}/${day} ${hh}:${mm}:${ss}`;
  };

  useEffect(() => {
    // メモリリーク抑止のためのアンマウントフラグ
    // https://qiita.com/daishi/items/4423878a1cd7a0ab69eb
    let unmounted = false;

    const getFinishedThisOne = async () => {
      if (
        !unmounted &&
        user?.email &&
        user.emailVerified &&
        myId === user.uid
      ) {
        try {
          const docRef = await db
            .collection(collectionName.finishedGames)
            .doc(gameId);
          docRef.get().then((doc) => {
            if (doc.exists && user.uid === doc.data()?.id) {
              setGame(doc.data());
            }
          });
        } catch (error) {
          alert(error);
        }
      } else {
        history.push(PAGE_HOME);
      }
    };
    getFinishedThisOne();

    // アンマウント時のクリーンアップ
    const cleanup = () => {
      unmounted = true;
    };
    return cleanup;
  }, [gameId, history, myId, user]);

  return (
    <>
      {game && (
        <BaseContainerGame
          // keyがないと Each child ... の警告が出る
          title={[
            game.options.mode === 's' ? 'シングルプレイ' : 'グループプレイ',
            <br key="break" />,
            formatTime(game.createdAt.toDate()),
          ]}
          marginBottom="L"
          turnScene={game.turnScene}
        >
          <ResultGoal game={game} />
          <ScoreLineChart game={game} activeScores={game.scores6} />
        </BaseContainerGame>
      )}

      <div
        style={{
          padding: BODY_PADDING,
        }}
      >
        <CenterDiv marginTop="M">
          <MaxWidthAdjust>
            <BlackButton onClick="toGameHistory">ゲーム履歴に戻る</BlackButton>
          </MaxWidthAdjust>
        </CenterDiv>
      </div>
    </>
  );
};

export default GameHistoryOne;
