import React from 'react';
import {
  COLOR_TURN_SCENE_LABEL,
  COLOR_WHITE,
  TURN_SCENE,
} from '../../../consts';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CenterDiv from '../../../components/shared/CenterDiv';

type Props = {
  turnScene: keyof typeof TURN_SCENE;
  marginTop?: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      padding: '5px 12px',
      borderRadius: 9999,
      fontSize: 14,
      color: COLOR_WHITE,
      fontWeight: 'bold',
    },
  })
);

const TurnSceneLabel: React.FC<Props> = ({ turnScene, marginTop }) => {
  const classes = useStyles();
  const label =
    turnScene === 'start'
      ? '人生目標カード選択'
      : turnScene === 'checkGoal'
      ? '人生目標カード確認'
      : turnScene === 'job'
      ? '仕事カード選択'
      : turnScene === 'private'
      ? 'プライベートカード選択'
      : turnScene === 'happening'
      ? 'ハプニングカード選択'
      : turnScene === 'checkHappening'
      ? 'ハプニングカード確認'
      : turnScene === 'social'
      ? '社会変化カード選択'
      : turnScene === 'checkSocial'
      ? '社会変化カード確認'
      : turnScene === 'result'
      ? '最終結果'
      : 'Error: unknown label';

  return (
    <CenterDiv marginTop="none">
      <div
        className={classes.label}
        style={{
          backgroundColor: COLOR_TURN_SCENE_LABEL[turnScene],
          marginTop: marginTop || 0,
        }}
      >
        {label}
      </div>
    </CenterDiv>
  );
};

export default TurnSceneLabel;
