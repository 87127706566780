import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Divider, MenuItem, TextField, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import BaseContainer from '../../../../components/shared/BaseContainer';
import BlackButton from '../../../../components/shared/BlackButton';
import CenterDiv from '../../../../components/shared/CenterDiv';
import WhiteButton from '../../../../components/shared/WhiteButton';
import MaxWidthAdjust from '../../../../components/shared/MaxWidthAdjust';
import {
  CARDS_SOC,
  CARDS_SOC_NAME,
  collectionName,
  COLOR_LIGHT_BLACK,
  COLOR_LINK,
  COLOR_MAIN,
  MARGIN_S,
  PAGE_CARDS_LIST,
  PAGE_MYPAGE,
  PAGE_MYPAGE_MANAGER_GROUP,
} from '../../../../consts';
import { FirebaseContext } from '../../../../contexts';
import { addGroup, db, readGroup, updateGroup } from '../../../../firebase';
import Loading from '../../../../components/shared/Loading';
import { GameGroupsRead } from '../../../../models';
import LeftDiv from '../../../../components/shared/LeftDiv';
import { getGroupTimeLimit } from '../../../../functions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkText: {
      color: COLOR_LINK,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    limit: {
      marginLeft: 25,
      color: COLOR_LIGHT_BLACK,
      fontSize: 12,
      display: 'inline',
    },
    divider: {
      marginTop: MARGIN_S,
    },
    contentTitle: {
      fontSize: 17,
      fontWeight: 'bold',
      letterSpacing: '0.4rem',
      textIndent: '0.4rem',
    },
    iconWrap: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 15,
    },
    icon: {
      color: COLOR_MAIN,
      cursor: 'pointer',
      fontSize: 28,
    },
    textCenter: {
      textAlign: 'center',
    },
  })
);

const socialChanges = CARDS_SOC.map((card, index) => ({
  value: card,
  label: CARDS_SOC_NAME[index],
}));

const randDigits = (min: number, max: number) => {
  const num = Math.floor(Math.random() * (max + 1 - min)) + min;
  return num;
};

const randChars = () => {
  const charLength = 3;
  // 判別しにくい文字（ijloqvy）は文字セットから除外
  const charSet = 'abcdefghkmnprstuwxz';
  let randChars = '';
  for (var i = 0; i < charLength; i++) {
    randChars += charSet[Math.floor(Math.random() * charSet.length)];
  }
  return randChars;
};

const Group: React.FC = () => {
  const { user } = useContext(FirebaseContext);
  const classes = useStyles();
  const history = useHistory();

  const [isManager, setIsManager] = useState<boolean>();
  const [activeGroups, setActiveGroups] = useState<GameGroupsRead[] | null>(
    null
  );
  const [groupName, setGroupName] = useState('');
  const [soc1, setSoc1] = useState('');
  const [soc2, setSoc2] = useState('');
  const [soc3, setSoc3] = useState('');
  // 多重クリック抑止
  // https://www.aizulab.com/blog/react-multiple-click-prevention/
  const processing = useRef(false);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      // 多重クリック抑止: 処理中(true)なら非同期処理せずに抜ける
      if (processing.current) return;
      // 多重クリック抑止: 処理中フラグを上げる
      processing.current = true;

      const groupObj = {
        groupName,
        soc1,
        soc2,
        soc3,
        creator: user?.uid,
        id: null,
        idInc: null,
        idNum: randDigits(100, 999),
        idChar: randChars(),
        aCode: randDigits(1000, 9999),
        isActive: true,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      setGroupName('');
      setSoc1('');
      setSoc2('');
      setSoc3('');
      await addGroup(collectionName.gameGroups, groupObj);

      // 多重クリック抑止: 処理中フラグを下げる
      processing.current = false;
    },
    [groupName, soc1, soc2, soc3, user]
  );

  const handleToDisable = useCallback(async (event, name, id) => {
    event.preventDefault();

    const check = window.confirm(
      `グループ [${name}] を終了しますか？\n※[OK]を押すとやり直しできません。`
    );
    if (check) {
      // 多重クリック抑止: 処理中(true)なら非同期処理せずに抜ける
      if (processing.current) return;
      // 多重クリック抑止: 処理中フラグを上げる
      processing.current = true;

      const data = {
        isActive: false,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      await updateGroup(collectionName.gameGroups, id, data);

      // 多重クリック抑止: 処理中フラグを下げる
      processing.current = false;
    }
  }, []);

  // validation
  const groupNameFormat = {
    maxStr: 32,
  };
  const groupNameHelperText =
    groupName === ''
      ? ''
      : groupNameFormat.maxStr < groupName.length
      ? '32文字以内でご入力ください'
      : '';
  const groupNameError =
    groupName === '' ? false : groupNameFormat.maxStr < groupName.length;

  // submit button disabled
  const submitBtnDisabled =
    groupName === '' ||
    groupNameError ||
    soc1 === '' ||
    soc2 === '' ||
    soc3 === '';

  useEffect(() => {
    if (user?.email && user.emailVerified) {
      db.collection(collectionName.manager)
        .where('email', '==', user.email)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length) {
            setIsManager(true);
          } else {
            setIsManager(false);
          }
        })
        .catch((error) => {
          alert(`Error getting documents: ${error}`);
        });
    }
    if (isManager === false) {
      history.push(PAGE_MYPAGE);
    }
  }, [history, isManager, user]);

  useEffect(() => {
    if (user?.email && isManager) {
      readGroup(collectionName.gameGroups, user.uid, setActiveGroups);
    }
  }, [isManager, user]);

  return (
    <>
      {isManager && (
        <BaseContainer title="グループ管理">
          <CenterDiv>
            <Typography variant="h6" className={classes.contentTitle}>
              登録
            </Typography>
          </CenterDiv>

          <form onSubmit={handleSubmit}>
            <CenterDiv>
              <MaxWidthAdjust>
                <TextField
                  // autoFocus={true}
                  name="group-name"
                  type="text"
                  fullWidth
                  label="グループ名（32文字以内）"
                  value={groupName}
                  onChange={(e) => {
                    setGroupName(e.target.value);
                  }}
                  helperText={groupNameHelperText}
                  error={groupNameError}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              </MaxWidthAdjust>
            </CenterDiv>

            <CenterDiv>
              <MaxWidthAdjust>
                <TextField
                  name="social-change1"
                  select
                  fullWidth
                  label="社会変化 1"
                  value={soc1}
                  onChange={(e) => {
                    setSoc1(e.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                >
                  {socialChanges.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </MaxWidthAdjust>
            </CenterDiv>

            <CenterDiv>
              <MaxWidthAdjust>
                <TextField
                  name="social-change2"
                  select
                  fullWidth
                  label="社会変化 2"
                  value={soc2}
                  onChange={(e) => {
                    setSoc2(e.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                >
                  {socialChanges.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </MaxWidthAdjust>
            </CenterDiv>

            <CenterDiv>
              <MaxWidthAdjust>
                <TextField
                  name="social-change3"
                  select
                  fullWidth
                  label="社会変化 3"
                  value={soc3}
                  onChange={(e) => {
                    setSoc3(e.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                >
                  {socialChanges.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </MaxWidthAdjust>
            </CenterDiv>

            <CenterDiv>
              <WhiteButton
                fullWidth={false}
                onClick="none"
                submit={true}
                disabled={submitBtnDisabled}
              >
                グループ登録
              </WhiteButton>
            </CenterDiv>
          </form>

          <CenterDiv marginTop="M">
            <Link
              to={PAGE_CARDS_LIST}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography className={classes.linkText}>
                カード一覧を表示
              </Typography>
            </Link>
          </CenterDiv>

          <CenterDiv>
            <MaxWidthAdjust>
              <Divider className={classes.divider} />
            </MaxWidthAdjust>
          </CenterDiv>

          <CenterDiv>
            <Typography variant="h6" className={classes.contentTitle}>
              確認
            </Typography>
          </CenterDiv>

          <CenterDiv>
            <MaxWidthAdjust>
              {activeGroups ? (
                activeGroups.map((activeGroup, index) => (
                  <LeftDiv key={index}>
                    {/* URLパラメーターの設定 */}
                    {/* https://zenn.dev/b1essk/articles/react-routing */}
                    <div>
                      <Link
                        to={`${PAGE_MYPAGE_MANAGER_GROUP}/${activeGroup.id}`}
                        className={classes.linkText}
                      >
                        {activeGroup.groupName}
                      </Link>
                      {activeGroup.createdAt && (
                        <span className={classes.limit}>
                          {/* https://date-fns.org/v2.28.0/docs/format */}
                          {`期限: ${getGroupTimeLimit(
                            activeGroup.createdAt.toDate()
                          )}`}
                        </span>
                      )}
                    </div>
                    <div
                      className={classes.iconWrap}
                      onClick={(e) =>
                        handleToDisable(
                          e,
                          activeGroup.groupName,
                          activeGroup.id
                        )
                      }
                    >
                      <DeleteIcon className={classes.icon} />
                    </div>
                  </LeftDiv>
                ))
              ) : (
                <Typography className={classes.textCenter}>
                  有効なグループは存在しません。
                </Typography>
              )}
            </MaxWidthAdjust>
          </CenterDiv>

          <CenterDiv marginTop="M">
            <MaxWidthAdjust>
              <BlackButton onClick="toManager">管理者に戻る</BlackButton>
            </MaxWidthAdjust>
          </CenterDiv>
        </BaseContainer>
      )}

      {!isManager && <Loading />}
    </>
  );
};

export default Group;
