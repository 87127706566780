import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  BODY_PADDING,
  MARGIN_XL,
  MARGIN_M,
  MARGIN_L,
  TURN_SCENE,
} from '../../consts';
import TurnSceneLabel from '../../pages/Game/components/TurnSceneLabel';

type Props = {
  // React TypeScript で Propsに HTMLタグを入れる方法
  // https://qiita.com/hiropy0123/items/e94a9c784e1d192145cd
  title?: string | Array<string | JSX.Element>;
  marginTop?: 'L' | 'XL';
  marginBottom?: 'L' | 'XL';
  children?: React.ReactNode;
  turnScene?: keyof typeof TURN_SCENE;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: BODY_PADDING,
    },
    titleWrap: {
      marginBottom: MARGIN_M,
    },
    title: {
      textAlign: 'center',
      fontWeight: 'bold',
    },
  })
);

const BaseContainerGame: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        marginTop:
          props.marginTop === 'XL'
            ? MARGIN_XL
            : props.marginTop === 'L'
            ? MARGIN_L
            : MARGIN_M,
        marginBottom:
          props.marginBottom === 'XL'
            ? MARGIN_XL
            : props.marginBottom === 'L'
            ? MARGIN_L
            : MARGIN_M,
      }}
    >
      <div className={classes.titleWrap}>
        <Typography variant="h5" className={classes.title}>
          {props.title}
        </Typography>
        {props.turnScene && (
          <TurnSceneLabel turnScene={props.turnScene} marginTop={8} />
        )}
      </div>
      {props.children}
    </div>
  );
};

export default BaseContainerGame;
