import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import * as consts from '../../consts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      position: 'relative',
      margin: '0 auto',
      width: '100%',
      maxWidth: 1000,
      [theme.breakpoints.down(1040)]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },

    profileContainer: {
      backgroundColor: consts.COLOR_WHITE,
      color: consts.COLOR_NAVY,
    },
    profileTitle: {
      marginTop: 67,
      fontSize: 32,
      fontWeight: 'bold',
      [theme.breakpoints.down(1040)]: {
        marginLeft: 16,
      },
      [theme.breakpoints.down(1001)]: {
        marginTop: 26,
        marginLeft: 0,
      },
    },
    profileBox: {
      marginTop: 45,
      [theme.breakpoints.up(1001)]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        marginLeft: 280,
        marginTop: 0,
        height: 177,
      },
    },
    profileName: {
      fontSize: 24,
      fontWeight: 'bold',
    },
    profileCareer: {
      marginTop: 15,
      fontSize: 16,
    },

    recommendImgBox: {
      position: 'absolute',
      bottom: -120,
      left: 20,
      [theme.breakpoints.down(1040)]: {
        left: 36,
      },
      [theme.breakpoints.down(1001)]: {
        position: 'static',
        bottom: 0,
        left: 0,
        marginTop: 42,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      },
    },
    recommendImg: {
      verticalAlign: 'top',
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },

    descContainer: {
      backgroundColor: consts.COLOR_NAVY,
      paddingBottom: 40,
    },
    descTitle: {
      color: consts.COLOR_WHITE,
      fontSize: 24,
      fontWeight: 'bold',
      marginLeft: 0,
      paddingTop: 24,
      paddingBottom: 16,
      [theme.breakpoints.up(1001)]: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 280,
        height: 120,
      },
    },
    descBody: {
      backgroundColor: consts.COLOR_WHITE,
      color: consts.COLOR_NAVY,
      padding: 24,
      borderRadius: 8,
      textAlign: 'justify',
      [theme.breakpoints.down(1001)]: {
        padding: 16,
      },
    },
    descTextBold: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: 1.7,
      textAlign: 'justify',
    },
    descTextNormal: {
      marginTop: 32,
      fontSize: 16,
      lineHeight: 1.8,
      textAlign: 'justify',
    },
  })
);

const Recommend: React.FC = () => {
  const classes = useStyles();

  const theme = useTheme();
  const is731Down = useMediaQuery(theme.breakpoints.down(731));

  return (
    <>
      <div className={classes.profileContainer}>
        <div className={classes.contentWrapper}>
          <div className={classes.profileTitle}>推薦者の声</div>
          <div className={classes.profileBox}>
            <div className={classes.profileName}>田中 研之輔</div>
            <Typography className={classes.profileCareer}>
              法政大学キャリアデザイン学部教授／
              {is731Down && <br />}
              一般社団法人プロティアン・キャリア協会 代表理事／
              <br />
              明光キャリアアカデミー学長
            </Typography>
          </div>
          <div className={classes.recommendImgBox}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/recommend/recommend.png`}
              alt="recommend-person"
              className={classes.recommendImg}
            />
          </div>
        </div>
      </div>
      <div className={classes.descContainer}>
        <div className={classes.contentWrapper}>
          <Typography className={classes.descTitle}>
            これからのキャリア形成で最も大切なことは、社会変化に適合しながら自ら主体的に行動し続ける持続力です。
          </Typography>
          <div className={classes.descBody}>
            <Typography className={classes.descTextBold}>
              キャリア形成の持続力を磨きあげるには、次のポイントがあります。
            </Typography>
            <Typography
              className={classes.descTextBold}
              style={{
                marginTop: 16,
              }}
            >
              ①キャリアに悩むのではなく、キャリアを考える機会を習慣化する。
              <br />
              ②キャリアを過去の実績ではなく、未来への羅針盤としてプランニングの練習を積み重ねる。
            </Typography>
            <Typography className={classes.descTextNormal}>
              音楽、スポーツ、囲碁将棋、茶道・舞踊、試験や入試、ありとあらゆる世界において、「人的資本を最大化」させる共通メソッドがあります。それは「本番」にできるだけ近い形での実践練習を模擬的に繰り返すことです。練習を繰り返すことで、洞察力・対応力・判断力・行動力が磨かれるのです。人生100年時代のキャリア形成においても、同じことがいえます。
              <br />
              「MIRAIZ」は、ビジネスキャリアとライフキャリアの各段階での意思決定を体験し、悩むことから考える主体へとキャリアをトランスフォームしていくことができます。私が提唱している「現代版プロティアン論」でも推奨している「キャリア資本」蓄積を模擬的かつ戦略的に学べることも大きな魅力です。
              <br />
              人生100年という筋書きのない人生を全うするために、「MIRAIZ」でキャリア形成の実践力を日頃から養っておくことにしましょう。より良き人生を、あなたの手でプロデュースしていくのです。
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default Recommend;
