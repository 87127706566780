import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { FirebaseContext } from './contexts';
import { ActiveGames, GameGroups, GameGroupsRead, Memo } from './models';
import Loading from './components/shared/Loading';
import {
  collectionName,
  finishedScoresDocId,
  DEFAULT_GAME,
  TURN_SCENE,
  PAGE_MYPAGE,
} from './consts';
import { isWithinDeadline } from './functions';

export const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
});
// メール文などを日本語に設定
// https://firebase.google.com/docs/auth/web/manage-users?hl=ja#send_a_user_a_verification_email
app.auth().languageCode = 'ja';
// メール アクションで状態 / 続行 URL を渡す
// https://firebase.google.com/docs/auth/web/passing-state-in-email-actions?hl=ja#passing_statecontinue_url_in_email_actions
const actionCodeSettings = {
  // url: 'http://localhost:3000/', // * 開発
  url: 'https://miraiz.new-one.co.jp', // ! 本番
};

export const db = app.firestore();

export const signupWithEmailAndNickname = async (
  email: string,
  password: string,
  nickname: string,
  birthday: Date,
  career: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);
    // Firebase でユーザーを管理する
    // https://firebase.google.com/docs/auth/web/manage-users?hl=ja
    // https://tenderfeel.xsrv.jp/javascript/3606/
    const { user } = await app
      .auth()
      .createUserWithEmailAndPassword(email, password);
    await user?.updateProfile({ displayName: nickname });
    await user?.sendEmailVerification(actionCodeSettings);
    // Authenticationで管理できないユーザープロフィールをFirestoreに保存
    await db
      .collection(collectionName.userProfile)
      .doc(user?.uid)
      .set({ birthday, career });
    // catch は引数に型を指定できない。以降に (error as any) とあるように型キャストを使う。
    // 型キャスト
    // https://note.com/bkc_1973/n/n8b8948f1ec5c
  } catch (error) {
    setLoading(false);
    // error codeの設定
    // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#createuserwithemailandpassword
    const errorCode = (error as any).code;
    const errorMessage = (error as any).message;
    if (errorCode === 'auth/email-already-in-use') {
      alert('そのメールアドレスはすでに登録されています。');
    } else {
      alert(errorMessage);
    }
  }
};

export const sendEmailVerify = async () => {
  try {
    const user = await app.auth().currentUser;
    await user?.sendEmailVerification(actionCodeSettings);
    alert('メールを送信しました。');
  } catch (error) {
    alert(error);
  }
};

export const loginWithEmailWithLoad = async (
  email: string,
  password: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);
    await app.auth().signInWithEmailAndPassword(email, password);
  } catch (error) {
    // error codeの設定
    // https://firebase.google.com/docs/reference/js/firebase.auth.Auth?hl=ja#signinwithemailandpassword
    setLoading(false);
    const errorCode = (error as any).code;
    const errorMessage = (error as any).message;
    if (errorCode === 'auth/user-not-found') {
      alert('登録されていないメールアドレスです。');
    } else if (errorCode === 'auth/wrong-password') {
      alert('パスワードが異なります。');
    } else {
      alert(errorMessage);
    }
  }
};

export const sendPasswordReset = async (
  email: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setEmail: React.Dispatch<React.SetStateAction<string>>
) => {
  try {
    setLoading(true);
    await app.auth().sendPasswordResetEmail(email, actionCodeSettings);
    alert('メールを送信しました。');
    setEmail('');
    setLoading(false);
  } catch (error) {
    setLoading(false);
    const errorCode = (error as any).code;
    const errorMessage = (error as any).message;
    if (errorCode === 'auth/user-not-found') {
      alert('登録されていないメールアドレスです。');
    } else {
      alert(errorMessage);
    }
  }
};

export const updateNickName = (nickName: string) => {
  const user = firebase.auth().currentUser;
  user
    ?.updateProfile({
      displayName: nickName,
    })
    .then(() => {
      window.location.reload();
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // 【Safari限定】なぜかこの処理で下記エラーが出る。ただし処理は正常に行われる。
      // A network error (such as timeout, interrupted connection or unreachable host) has occurred.
      // 暫定対応として、エラーメッセージの抑止のために日本語変換とする。
      // ! 【追記】上記の window.location.reload() を記入したところ、Safariのエラーも解消した。then() の後続処理が空だったことが要因?
      if (errorCode === 'auth/network-request-failed') {
        alert('ニックネームを変更しました。');
      } else {
        alert(errorMessage);
      }
    });
};

export const anonymouslyLogin = (
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const check = window.confirm(
    '新規登録できない方に向けた、匿名でのログイン機能です。\n実行しますか？'
  );
  if (check) {
    try {
      setLoading(true);
      app.auth().signInAnonymously();
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  }
};

export const deleteUserProfile = async (id: string) => {
  const docRef = await db.collection(collectionName.userProfile).doc(id);
  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        docRef.delete();
      }
    })
    .catch((error) => {
      alert(error);
    });
};

export const cancelMiraiz = async (user: firebase.User, id: string) => {
  try {
    await deleteUserProfile(id);
    await user.delete();
    alert('退会処理が完了しました。またのご利用、お待ちしております。');
  } catch (error) {
    // error codeの設定
    // https://firebase.google.com/docs/auth/ios/errors?hl=ja
    // https://qiita.com/kokogento/items/39f06941fb840b2e24ed
    const errorCode = (error as any).code;
    const errorMessage = (error as any).message;
    if (errorCode === 'auth/requires-recent-login') {
      alert(
        'ログイン認証の期限切れにより退会処理できませんでした。お手数ですが、ログアウトをしてから再度ログインをし、再実行してください。'
      );
    } else {
      alert(errorMessage);
    }
  }
};

export const addActiveGames = async (
  collectionName: string,
  activeGames: ActiveGames,
  setGame: React.Dispatch<React.SetStateAction<ActiveGames | null>>
) => {
  try {
    await db.collection(collectionName).doc(activeGames.id).set(activeGames);
    setGame(activeGames);
  } catch (error) {
    alert(error);
  }
};

export const updateActiveGames = async (
  collectionName: string,
  id: string,
  activeGames: {
    updatedAt: firebase.firestore.FieldValue;
    turn?: number;
    turnScene?: keyof typeof TURN_SCENE;
    lifeGoal?: string;
    scores0?: number[];
    scores1?: number[];
    scores2?: number[];
    scores3?: number[];
    scores4?: number[];
    scores5?: number[];
    scores6?: number[];
    cardJob?: string[];
    cardPrivate1?: string[];
    cardPrivate2?: string[];
    cardPrivate3?: string[];
    cardPrivate4?: string[];
    cardPrivate5?: string[];
    cardPrivate6?: string[];
    cardHappening?: string[];
    cardSocialChange?: string[];
    events1?: string[];
    events2?: string[];
    events3?: string[];
    events4?: string[];
    events5?: string[];
    events6?: string[];
    events7?: string[];
    options?: {
      mode: 's' | 'g';
      jobChanged: boolean;
      jobChange: number;
      jobKeep: number;
      medicalCheckUp: number;
      investmentLearnHigh: number;
      investmentLearnLow: number;
      moneyBoughtHome: number;
      boughtHomes: number;
      bankrupt: boolean;
      resultGoal: boolean | null;
      resultScore: number;
      groupId: string;
      groupSoc1: string;
      groupSoc2: string;
      groupSoc3: string;
      playerName: string;
    };
    createdAt?: firebase.firestore.FieldValue;
  }
) => {
  try {
    await db.collection(collectionName).doc(id).update(activeGames);
  } catch (error) {
    alert(error);
  }
};

export const readFinishedScores = async () => {
  const docRef = db
    .collection(collectionName.finishedScores)
    .doc(finishedScoresDocId.groupScores);

  return docRef
    .get()
    .then((doc) => {
      if (!doc.exists) {
        throw new Error('ドキュメントが存在しません。');
      } else {
        return doc.data();
      }
    })
    .catch((error) => {
      alert(error);
      return null;
    });
};

export const addFinishedGames = async (
  collectionName: string,
  finishedGame: ActiveGames
) => {
  try {
    await db.collection(collectionName).add(finishedGame);
  } catch (error) {
    alert(error);
  }
};

export const deleteActiveGame = async (collectionName: string, id: string) => {
  try {
    // delete()は、データが存在しなくてもエラーを返さない。
    await db.collection(collectionName).doc(id).delete();
  } catch (error) {
    alert(error);
  }
};

export const finishThisGame = async (
  // finishedGame: ActiveGames,
  game: ActiveGames | null,
  user: firebase.User | null,
  gameCollectionName: string,
  setIsLoading: (value: React.SetStateAction<boolean>) => void
) => {
  // https://firebase.google.cn/docs/firestore/manage-data/transactions?hl=ja#web-version-8
  const docId =
    game?.options.mode === 's'
      ? finishedScoresDocId.singleScores
      : finishedScoresDocId.groupScores;
  const docRef = db.collection(collectionName.finishedScores).doc(docId);

  return db
    .runTransaction((transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(docRef).then((doc) => {
        if (!doc.exists) {
          throw new Error('ドキュメントが存在しません。');
        }

        const countsGoal = [...doc.data()?.countsGoal];
        const goalIndex = Number(game?.lifeGoal?.slice(-2)) - 1;
        countsGoal[goalIndex] += 1;

        const scoresResult = [
          ...doc.data()?.scoresResult,
          game?.options.resultScore,
        ];
        const scoresMoney = [...doc.data()?.scoresMoney, game?.scores6![0]];
        const scoresHealth = [...doc.data()?.scoresHealth, game?.scores6![1]];
        const scoresPerformance = [
          ...doc.data()?.scoresPerformance,
          game?.scores6![2],
        ];
        const scoresManagement = [
          ...doc.data()?.scoresManagement,
          game?.scores6![3],
        ];
        const scoresNetwork = [...doc.data()?.scoresNetwork, game?.scores6![4]];
        const scoresLeisure = [...doc.data()?.scoresLeisure, game?.scores6![5]];

        transaction.update(docRef, {
          countsGoal,
          scoresResult,
          scoresMoney,
          scoresHealth,
          scoresPerformance,
          scoresManagement,
          scoresNetwork,
          scoresLeisure,
        });
      });
    })
    .then(() => {
      addFinishedGames(collectionName.finishedGames, {
        ...game!,
        options: user?.displayName
          ? { ...game!.options, playerName: 'named' }
          : { ...game!.options, playerName: 'anonymous' },
      });
    })
    .then(() => {
      deleteActiveGame(gameCollectionName, game?.id!);
    })
    .then(() => {
      return true;
    })
    .catch((error) => {
      setIsLoading(false);
      alert(error);
      return false;
    });
};

export const readMyFinishedGames = async (
  collectionName: string,
  uid: string,
  setGames: React.Dispatch<React.SetStateAction<any[] | null>>
) => {
  await db
    .collection(collectionName)
    .where('id', '==', uid)
    .orderBy('createdAt', 'desc')
    .get()
    .then((querySnapshot) => {
      let data: any[] = [];
      querySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), docId: doc.id });
      });
      setGames(data);
    })
    .catch((error) => {
      alert(`Error getting documents: ${error}`);
    });
};

export const addGroup = async (collectionName: string, group: GameGroups) => {
  // 参照
  // idのインクリメント
  // https://qiita.com/watsuyo_2/items/3f59cc44cc5c60cd208c
  // ドキュメントidを指定してset()する方法
  // https://qiita.com/takehanKosuke/items/2d12637159555807b916
  try {
    await db.runTransaction(async (transaction) => {
      const groupRef = await db.collection(collectionName);
      const groupRefDoc = await groupRef.doc();

      await transaction.get(groupRefDoc).then(async () => {
        // 最新のgroupを1件のみ取得
        const querySnapshot = await groupRef
          .orderBy('idInc', 'desc')
          .limit(1)
          .get();

        if (querySnapshot) {
          let newId!: number;

          await Promise.all(
            querySnapshot.docs.map(async (doc) => {
              const docData = await doc.data();

              // 新しいidを作る
              newId = (await docData.idInc) + 1;
            })
          );

          const newGroupData: GameGroups = {
            ...group,
            id: String(newId) + String(group.idNum) + group.idChar,
            idInc: newId,
          };

          // ドキュメントidを指定してgroupを新規追加
          await transaction.set(groupRef.doc(newGroupData.id!), newGroupData);
        }
      });
    });
  } catch (error) {
    alert(error);
  }
};

export const readGroup = async (
  collectionName: string,
  uid: string,
  setActiveGroups: React.Dispatch<React.SetStateAction<GameGroupsRead[] | null>>
) => {
  try {
    await db
      .collection(collectionName)
      // 複合インデックスの登録が必要
      // https://firebase.google.com/docs/firestore/query-data/index-overview?authuser=0#composite_indexes
      .where('creator', '==', uid)
      .where('isActive', '==', true)
      // .orderBy('createdAt', 'desc')
      .orderBy('idInc', 'desc')
      .onSnapshot((snapshot) => {
        if (snapshot.docs.length) {
          const data = snapshot.docs
            .filter((doc) => isWithinDeadline(doc.data().createdAt.toDate()))
            .map((doc) => ({
              groupName: doc.data().groupName,
              soc1: doc.data().soc1,
              soc2: doc.data().soc2,
              soc3: doc.data().soc3,
              creator: doc.data().creator,
              id: doc.data().id,
              idInc: doc.data().idInc,
              idNum: doc.data().idNum,
              idChar: doc.data().idChar,
              aCode: doc.data().aCode,
              isActive: doc.data().isActive,
              createdAt: doc.data().createdAt,
              updatedAt: doc.data().updatedAt,
            }));
          setActiveGroups(data);
        }
      });
  } catch (error) {
    alert(error);
  }
};

export const readActiveGroup = async (
  collectionName: string,
  uid: string,
  id: string,
  setActiveGroup: React.Dispatch<any>
) => {
  try {
    const docRef = await db.collection(collectionName).doc(id);
    docRef.get().then((doc) => {
      if (
        doc.exists &&
        uid === doc.data()?.creator &&
        doc.data()?.isActive === true &&
        isWithinDeadline(doc.data()?.createdAt.toDate()) // グループ期限check
      ) {
        setActiveGroup(doc.data());
      } else {
        window.location.href = PAGE_MYPAGE;
      }
    });
  } catch (error) {
    alert(error);
    window.location.href = PAGE_MYPAGE;
  }
};

export const updateGroup = async (
  collectionName: string,
  id: string,
  data: {
    isActive: boolean;
    updatedAt: firebase.firestore.FieldValue;
  }
) => {
  try {
    await db.collection(collectionName).doc(id).update(data);
    window.location.reload();
  } catch (error) {
    alert(error);
  }
};

export const startSingleGame = async (
  collectionGame: string,
  activeGameId: string,
  playerName: string,
  onGaming: () => Promise<void>
) => {
  try {
    await onGaming();
    await updateActiveGames(collectionGame, activeGameId, {
      options: {
        ...DEFAULT_GAME.options,
        playerName,
      },
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  } catch (error) {
    alert(error);
  }
};

export const readGroupFromGame = async (
  collectionGroup: string,
  collectionGame: string,
  groupId: string,
  accessCode: string,
  activeGameId: string,
  playerName: string,
  onGaming: () => Promise<void>
) => {
  try {
    const docRef = await db.collection(collectionGroup).doc(groupId);
    docRef.get().then(async (doc) => {
      if (
        doc.exists &&
        doc.data()?.aCode === Number(accessCode) &&
        doc.data()?.isActive === true &&
        isWithinDeadline(doc.data()?.createdAt.toDate()) // グループ期限check
      ) {
        await onGaming();
        await updateActiveGames(collectionGame, activeGameId, {
          options: {
            ...DEFAULT_GAME.options,
            mode: 'g',
            groupId,
            groupSoc1: doc.data()?.soc1,
            groupSoc2: doc.data()?.soc2,
            groupSoc3: doc.data()?.soc3,
            playerName,
          },
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } else {
        alert('IDまたはアクセスコードが不正です。');
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const readActiveGroupGames = async (
  collectionName: string,
  groupId: string,
  setGroupGames: React.Dispatch<React.SetStateAction<any[] | null>>
) => {
  await db
    .collection(collectionName)
    .where('options.groupId', '==', groupId)
    .get()
    .then((querySnapshot) => {
      let data: any[] = [];
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      setGroupGames(data);
    })
    .catch((error) => {
      alert(`Error getting documents: ${error}`);
    });
};

// deleteActiveGame()を有効化するため、この位置に記載。
// ※Hoistingでは正常に反応しなかったため。
export const logoutCheckAnonymous = async (user: firebase.User) => {
  const check = window.confirm('ログアウトしますか？');
  if (check) {
    if (user.providerData.length === 0 && !user.email) {
      // 匿名ユーザーはアカウントを削除
      try {
        await deleteActiveGame(collectionName.activeGames, user.uid);
        await deleteActiveGame(collectionName.activeGroupGames, user.uid);
        // 匿名ログインの場合、delete() の auth/requires-recent-login エラーは起きないため、エラーハンドリング不要。
        // https://firebase.google.com/docs/reference/js/firebase.User.html#delete
        await user.delete();
        alert('匿名でのログインを終了しました。');
      } catch (error) {
        alert(error);
      }
    } else {
      // 一般ユーザーは通常のログアウト
      await app.auth().signOut();
      alert('ログアウトしました。');
    }
  }
};

// ! 【使用禁止】参照専用
// // グループの有効期限機能を設定する時に、既存グループの有効期限を伸ばすために使用したもの。
// export const changeGroupDateAll = async () => {
//   const batch = db.batch();

//   const groupRef = await db
//     .collection(collectionName.gameGroups)
//     .where('isActive', '==', true)
//     // .orderBy('idInc', 'desc')
//     // .limit(2)
//     .get();
//   groupRef.docs.forEach((doc) => {
//     batch.update(doc.ref, {
//       createdAt: firebase.firestore.FieldValue.serverTimestamp(),
//       updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
//     });
//     // console.log(doc.ref.id);
//   });
//   await batch.commit();
//   // getData();
// };

// ! 【使用禁止】参照専用
// // ゲーム結果の順位や偏差値を表示するために、finishedScores コレクションを作成する時に使用したfunction。
// export const readFinishedGamesAndAddFinishedScores = async () => {
//   try {
//     // let arr: number[] = [];
//     let SCountsGoal: number[] = [];
//     let SScoresResult: number[] = [];
//     let SScoresMoney: number[] = [];
//     let SScoresHealth: number[] = [];
//     let SScoresPerformance: number[] = [];
//     let SScoresManagement: number[] = [];
//     let SScoresNetwork: number[] = [];
//     let SScoresLeisure: number[] = [];
//     let GCountsGoal: number[] = [];
//     let GScoresResult: number[] = [];
//     let GScoresMoney: number[] = [];
//     let GScoresHealth: number[] = [];
//     let GScoresPerformance: number[] = [];
//     let GScoresManagement: number[] = [];
//     let GScoresNetwork: number[] = [];
//     let GScoresLeisure: number[] = [];

//     await db
//       .collection(collectionName.finishedGames)
//       .get()
//       .then((snap) => {
//         const singles = snap.docs.filter(
//           (doc) => doc.data().options.mode === 's'
//         );
//         const groups = snap.docs.filter(
//           (doc) => doc.data().options.mode === 'g'
//         );
//         const countsSingleGoal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
//         const countsGroupGoal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

//         const goalsNumSingle = singles.map((doc) =>
//           doc.data().lifeGoal.slice(-2)
//         );
//         const goalsNumGroup = groups.map((doc) =>
//           doc.data().lifeGoal.slice(-2)
//         );

//         for (const num of goalsNumSingle) {
//           countsSingleGoal[num - 1] += 1;
//         }
//         for (const num of goalsNumGroup) {
//           countsGroupGoal[num - 1] += 1;
//         }

//         const scoresSingleResult: number[] = singles.map(
//           (doc) => doc.data().options.resultScore
//         );
//         const scoresSingleMoney: number[] = singles.map(
//           (doc) => doc.data().scores6[0]
//         );
//         const scoresSingleHealth: number[] = singles.map(
//           (doc) => doc.data().scores6[1]
//         );
//         const scoresSinglePerformance: number[] = singles.map(
//           (doc) => doc.data().scores6[2]
//         );
//         const scoresSingleManagement: number[] = singles.map(
//           (doc) => doc.data().scores6[3]
//         );
//         const scoresSingleNetwork: number[] = singles.map(
//           (doc) => doc.data().scores6[4]
//         );
//         const scoresSingleLeisure: number[] = singles.map(
//           (doc) => doc.data().scores6[5]
//         );

//         const scoresGroupResult: number[] = groups.map(
//           (doc) => doc.data().options.resultScore
//         );
//         const scoresGroupMoney: number[] = groups.map(
//           (doc) => doc.data().scores6[0]
//         );
//         const scoresGroupHealth: number[] = groups.map(
//           (doc) => doc.data().scores6[1]
//         );
//         const scoresGroupPerformance: number[] = groups.map(
//           (doc) => doc.data().scores6[2]
//         );
//         const scoresGroupManagement: number[] = groups.map(
//           (doc) => doc.data().scores6[3]
//         );
//         const scoresGroupNetwork: number[] = groups.map(
//           (doc) => doc.data().scores6[4]
//         );
//         const scoresGroupLeisure: number[] = groups.map(
//           (doc) => doc.data().scores6[5]
//         );

//         // console.log('snap', snap.docs)
//         // console.log('s', singles.map(doc => doc.data().lifeGoal))
//         // console.log('g', groups.map(doc => doc.data().options.resultScore))
//         console.log('★★ countsSingleGoal', countsSingleGoal)
//         console.log('★★ scoresSingleResult', scoresSingleResult)
//         console.log('★★ scoresSingleMoney', scoresSingleMoney)
//         console.log('★★ scoresSingleHealth', scoresSingleHealth)
//         console.log('★★ scoresSinglePerformance', scoresSinglePerformance)
//         console.log('★★ scoresSingleManagement', scoresSingleManagement)
//         console.log('★★ scoresSingleNetwork', scoresSingleNetwork)
//         console.log('★★ scoresSingleLeisure', scoresSingleLeisure)
//         console.log('★★ countsGroupGoal', countsGroupGoal)
//         console.log('★★ scoresGroupResult', scoresGroupResult)
//         console.log('★★ scoresGroupMoney', scoresGroupMoney)
//         console.log('★★ scoresGroupHealth', scoresGroupHealth)
//         console.log('★★ scoresGroupPerformance', scoresGroupPerformance)
//         console.log('★★ scoresGroupManagement', scoresGroupManagement)
//         console.log('★★ scoresGroupNetwork', scoresGroupNetwork)
//         console.log('★★ scoresGroupLeisure', scoresGroupLeisure)

//         // SCountsGoal = countsSingleGoal;
//         // SScoresResult = scoresSingleResult;
//         // SScoresMoney = scoresSingleMoney;
//         // SScoresHealth = scoresSingleHealth;
//         // SScoresPerformance = scoresSinglePerformance;
//         // SScoresManagement = scoresSingleManagement;
//         // SScoresNetwork = scoresSingleNetwork;
//         // SScoresLeisure = scoresSingleLeisure;
//         // GCountsGoal = countsGroupGoal;
//         // GScoresResult = scoresGroupResult;
//         // GScoresMoney = scoresGroupMoney;
//         // GScoresHealth = scoresGroupHealth;
//         // GScoresPerformance = scoresGroupPerformance;
//         // GScoresManagement = scoresGroupManagement;
//         // GScoresNetwork = scoresGroupNetwork;
//         // GScoresLeisure = scoresGroupLeisure;
//       });

//     // await db.runTransaction(async (transaction) => {
//     //   // ドキュメントidを指定して新規追加
//     //   await transaction.set(
//     //     db
//     //       .collection(collectionName.finishedScores)
//     //       .doc(finishedScoresDocId.singleScores),
//     //     {
//     //       [finishedScoresField.countsGoal]: SCountsGoal,
//     //       [finishedScoresField.scoresResult]: SScoresResult,
//     //       [finishedScoresField.scoresMoney]: SScoresMoney,
//     //       [finishedScoresField.scoresHealth]: SScoresHealth,
//     //       [finishedScoresField.scoresPerformance]: SScoresPerformance,
//     //       [finishedScoresField.scoresManagement]: SScoresManagement,
//     //       [finishedScoresField.scoresNetwork]: SScoresNetwork,
//     //       [finishedScoresField.scoresLeisure]: SScoresLeisure,
//     //     }
//     //   );
//     //   await transaction.set(
//     //     db
//     //       .collection(collectionName.finishedScores)
//     //       .doc(finishedScoresDocId.groupScores),
//     //     {
//     //       [finishedScoresField.countsGoal]: GCountsGoal,
//     //       [finishedScoresField.scoresResult]: GScoresResult,
//     //       [finishedScoresField.scoresMoney]: GScoresMoney,
//     //       [finishedScoresField.scoresHealth]: GScoresHealth,
//     //       [finishedScoresField.scoresPerformance]: GScoresPerformance,
//     //       [finishedScoresField.scoresManagement]: GScoresManagement,
//     //       [finishedScoresField.scoresNetwork]: GScoresNetwork,
//     //       [finishedScoresField.scoresLeisure]: GScoresLeisure,
//     //     }
//     //   );
//     // });
//   } catch (error) {
//     alert(error);
//   }
// };

// ++++----------------
// For Sample
export const signupWithEmail = async (email: string, password: string) => {
  try {
    await app.auth().createUserWithEmailAndPassword(email, password);
  } catch (error) {
    alert(error);
  }
};

export const loginWithEmail = async (email: string, password: string) => {
  try {
    await app.auth().signInWithEmailAndPassword(email, password);
  } catch (error) {
    // error codeの設定
    // https://firebase.google.com/docs/reference/js/firebase.auth.Auth?hl=ja#signinwithemailandpassword
    const errorCode = (error as any).code;
    const errorMessage = (error as any).message;
    if (errorCode === 'auth/user-not-found') {
      alert('登録されていないメールアドレスです。');
    } else if (errorCode === 'auth/wrong-password') {
      alert('パスワードが異なります。');
    } else {
      alert(errorMessage);
    }
  }
};

export const loginWithGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  try {
    await firebase.auth().signInWithPopup(provider);
  } catch (error) {
    alert(error);
  }
};

export const logout = () => {
  const check = window.confirm('ログアウトしますか？');
  if (check) {
    app.auth().signOut();
  }
};

export const addDoc = async (collectionName: string, memo: Memo) => {
  try {
    await db.collection(collectionName).add(memo);
  } catch (error) {
    alert(error);
  }
};

export const updateDoc = async (collectionName: string, memo: Memo) => {
  try {
    await db.collection(collectionName).doc(memo.id).set(memo);
  } catch (error) {
    alert(error);
  }
};

export const deleteDoc = async (collectionName: string, memo: Memo) => {
  try {
    await db.collection(collectionName).doc(memo.id).delete();
  } catch (error) {
    alert(error);
  }
};

// For Sample
// ----------------++++

export const FirebaseProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });
  }, []);

  if (loading) {
    // return <div className='loading'>ローディング中...</div>
    return <Loading />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
