import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { ActiveGames } from '../../../models';
import MainSelectButton from './MainSelectButton';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" timeout={900} ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  titleTurn: string;
  titleAge: string;
  game?: ActiveGames;
};

// SlideタイプのDialog
// https://material-ui.com/components/dialogs/#transitions
const TurnStartDialog: React.FC<Props> = ({
  open,
  setOpen,
  titleTurn,
  titleAge,
  // game,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      // borderRadiusの設定方法
      // https://github.com/mui-org/material-ui/issues/12701
      PaperProps={{
        style: {
          borderRadius: 15,
          width: '80%',
          maxWidth: 320,
          padding: '0.7rem 1rem 1.2rem',
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        style={{ textAlign: 'center' }}
      >
        <span style={{ fontWeight: 'bold' }}>
          {titleTurn}
          <br />
          {titleAge}
        </span>
      </DialogTitle>
      <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ flex: 1 }}>
          <MainSelectButton padding={14} onClick={handleClose}>
            開始
          </MainSelectButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default TurnStartDialog;
