import React, { useState, useEffect, useContext, useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { PAGE_HOME } from '../../consts';
import { loginWithEmailWithLoad } from '../../firebase';
import BaseContainer from '../../components/shared/BaseContainer';
import CenterDiv from '../../components/shared/CenterDiv';
import MaxWidthAdjust from '../../components/shared/MaxWidthAdjust';
import MainButton from '../../components/shared/MainButton';
import { FirebaseContext } from '../../contexts';
import LoginOption from './LoginOption/LoginOption';
import Loading from '../../components/shared/Loading';

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const { user } = useContext(FirebaseContext);

  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [loading, setLoading] = useState(false);

  // email validation
  const emailFormat = {
    maxStr: 256,
    regExp: /^[\w.!#$%&'*+/=?^`{|}~-]+@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
  };
  const emailHelperText =
    email === ''
      ? ' '
      : email.length > emailFormat.maxStr
      ? '256文字以内でご入力ください'
      : email.match(emailFormat.regExp) === null
      ? '登録済みのメールアドレスをご入力ください'
      : ' ';
  const emailError =
    email === ''
      ? false
      : email.length > emailFormat.maxStr ||
        email.match(emailFormat.regExp) === null;

  // password validation
  const passFormat = {
    // 6文字以上というのがFirebaseの仕様
    // 上限64文字は独自に設定
    minStr: 6,
    maxStr: 64,
    // [!-~]  => すべての半角文字 ※半角スペースは除外
    // https://www-creators.com/archives/5187
    regExp1: /^[!-~]+$/,
  };
  const passHelperText =
    pass === ''
      ? ' '
      : pass.match(passFormat.regExp1) === null
      ? '不正な文字が存在します（全角文字や空白）'
      : pass.length < passFormat.minStr || passFormat.maxStr < pass.length
      ? 'パスワードは6〜64文字です'
      : ' ';
  const passError =
    pass === ''
      ? false
      : pass.match(passFormat.regExp1) === null ||
        pass.length < passFormat.minStr ||
        passFormat.maxStr < pass.length;

  // submit button disabled
  const submitBtnDisabled =
    email === '' || pass === '' || emailError || passError;

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const { email, password } = event.target.elements;
      await loginWithEmailWithLoad(email.value, password.value, setLoading);
    },
    // eslint-disable-next-line
    [history]
  );

  useEffect(() => {
    user && history.push(PAGE_HOME);
  }, [history, user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <BaseContainer title="ログイン" marginBottom="L">
      <form onSubmit={handleSubmit}>
        <CenterDiv>
          <MaxWidthAdjust>
            <TextField
              autoFocus={true}
              name="email"
              type="email"
              fullWidth
              label="メールアドレス"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              helperText={emailHelperText}
              error={emailError}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </MaxWidthAdjust>
        </CenterDiv>

        <CenterDiv>
          <MaxWidthAdjust>
            <TextField
              name="password"
              type="password"
              fullWidth
              label="パスワード"
              value={pass}
              onChange={(e) => {
                setPass(e.target.value);
              }}
              helperText={passHelperText}
              error={passError}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </MaxWidthAdjust>
        </CenterDiv>

        <CenterDiv marginTop="M">
          <MaxWidthAdjust>
            <MainButton
              onClick="none"
              submit={true}
              disabled={submitBtnDisabled}
            >
              ログイン
            </MainButton>
          </MaxWidthAdjust>
        </CenterDiv>
      </form>

      <LoginOption setLoading={setLoading} />
    </BaseContainer>
  );
};

export default withRouter(Login);
