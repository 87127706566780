import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  CARDS_LIF_GOAL,
  COLOR_DARK_GRAY,
  COLOR_WHITE,
  MARGIN_S,
} from '../../../consts';

type Props = {
  lifeGoal: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: MARGIN_S,
      [theme.breakpoints.down('xs')]: {
        padding: '0 5% 28px',
        borderRadius: 5,
      },
      [theme.breakpoints.up('sm')]: {
        padding: '0 2% 2%',
        borderRadius: 10,
      },
    },
    paperTitle: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 20,
      '& h6': {
        fontSize: 17,
        fontWeight: 'bold',
        color: COLOR_WHITE,
        backgroundColor: COLOR_DARK_GRAY,
        padding: '4px 24px',
        borderRadius: '0 0 10px 10px',
      },
    },
    infoWrap: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'justify',
    },
  })
);

const AchieveGoal: React.FC<Props> = ({ lifeGoal }) => {
  const classes = useStyles();

  const goal =
    lifeGoal === 'l-01'
      ? CARDS_LIF_GOAL[0]
      : lifeGoal === 'l-02'
      ? CARDS_LIF_GOAL[1]
      : lifeGoal === 'l-03'
      ? CARDS_LIF_GOAL[2]
      : lifeGoal === 'l-04'
      ? CARDS_LIF_GOAL[3]
      : lifeGoal === 'l-05'
      ? CARDS_LIF_GOAL[4]
      : lifeGoal === 'l-06'
      ? CARDS_LIF_GOAL[5]
      : lifeGoal === 'l-07'
      ? CARDS_LIF_GOAL[6]
      : lifeGoal === 'l-08'
      ? CARDS_LIF_GOAL[7]
      : lifeGoal === 'l-09'
      ? CARDS_LIF_GOAL[8]
      : lifeGoal === 'l-10'
      ? CARDS_LIF_GOAL[9]
      : lifeGoal === 'l-11'
      ? CARDS_LIF_GOAL[10]
      : lifeGoal === 'l-12'
      ? CARDS_LIF_GOAL[11]
      : '※達成目標を表示できません。';

  return (
    <>
      <Paper elevation={3} className={classes.paper}>
        <div className={classes.paperTitle}>
          <Typography variant="h6">達成目標</Typography>
        </div>
        <div className={classes.infoWrap}>
          <Typography>{goal}</Typography>
        </div>
      </Paper>
    </>
  );
};

export default AchieveGoal;
