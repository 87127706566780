import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { COLOR_LIGHT_GRAY, COLOR_LINK, PAGE_TERMS } from '../../../consts';

type Props = {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionBox: {
      width: '100%',
      height: 325,
      marginTop: 10,
      marginBottom: 20,
      padding: '5px 15px',
      boxSizing: 'border-box',
      overflow: 'auto',
      border: 'solid 1px ' + COLOR_LIGHT_GRAY,
      [theme.breakpoints.up('sm')]: {
        height: 225,
      },
      '& .__description': {
        fontSize: '13px',
        textAlign: 'justify',
        '& .__indent': {
          paddingLeft: 32,
        },
        '& a': {
          color: COLOR_LINK,
          overflowWrap: 'break-word',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    checkbox: {
      display: 'flex',
      justifyContent: 'center',
    },
    check: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      fontSize: '13px',
    },
    agree: {
      marginLeft: 10,
    },
    terms: {
      color: COLOR_LINK,
      padding: '0 3px',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

const PersonalInfoAgree: React.FC<Props> = ({ checked, setChecked }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <div className={classes.descriptionBox}>
        <div className="__description">
          <p>
            ◆個人情報の取り扱い
            <br />
            株式会社NEWONE（以下「当社」と言います）は、ご提供頂く個人情報を以下のとおり取り扱います。
            <br />
            <br />
            1．利用目的について
            <br />
            当社は個人情報を下記の利用目的の達成ならびに必要な範囲で利用致します。
            <br />
            （1）MIRAIZ全般の情報提供のため
            <br />
            （2）システムに不具合などがあった際の連絡のため
            <br />
            （3）キャリア関連サービスの情報提供のため
            <br />
            ご提供は任意ですが、当社が求める個人情報をご提供いただけない場合、目的を遂行できない場合がございますので、ご了承下さい。
            <br />
            <br />
            2．個人情報の取り扱いについて
            <br />
            当社はご提供頂きました個人情報を上記利用目的の範囲内で正確・最新の内容に保つように努め、不正なアクセス、改竄、漏洩等を防ぐべく、必要かつ適切な安全管理措置を講じます。ご提供頂きました個人情報は特定の場所にて保管し、原則として返却致しません。お預かり致しました個人情報は特定の場所にて保管致します。
            <br />
            <br />
            また当社は、本人が容易に認識できない方法によって個人情報を取得することはございません。但し、当社が運営するウェブサイト（new-one.co.jp）では、セキュリティ確保・ユーザビリティ向上のため、アクセス・利用状況（個人情報を含みません）をCookieにより取得しています。Cookieの設定については、各ウェブブラウザのヘルプメニューをご確認ください。
            <br />
            <br />
            3．個人情報の第三者への提供
            <br />
            当社は、以下の場合を除き、個人情報をご本人様の同意なしで第三者へ提供致しません。
            <br />
            （1）法令の規定により開示が要求された場合
            <br />
            （2）人の生命、健康、財産等の重大な利益を保護するために必要な場合
            <br />
            （3）公共の利益を保護するために必要な場合
            <br />
            <br />
            4．委託等の管理
            <br />
            個人情報の取り扱いを外部に委託する場合は、当社が規定する個人情報管理基準を満たす企業を選定して委託を行い、適切な取り扱いが行われるよう監督します。
            <br />
            <br />
            5．個人情報の開示等について
            <br />
            当社に対してご提供頂いた個人情報に関して、ご本人は、利用目的の通知・開示・内容の訂正・追加又は削除・利用の停止・消去及び第三者への提供の停止(以下「開示等」と言います)を求める事ができます。
            <br />
            個人情報の取り扱いに関する苦情及び開示等の申し出は、個人情報相談窓口まで、電話かメールにてご連絡下さい。当社の手続き規定に従い、対応致します。
            <br />
            <br />
            6．開示等の求めに関する手続き
            <br />
            開示等の求めは、まず個人情報相談窓口へ電話又はメールでご連絡ください。以下の流れに沿って対応をさせていただきます。
            <br />
            （1）開示等の求めの受付
            <br />
            手続き用紙「個人情報開示等請求書」を郵送やメール等でお渡し致します。ご記入の上、当社にご提出ください。
            <br />
            （2）本人確認
            <br />
            開示等請求者の本人確認をさせていただきます。運転免許証、パスポート等の写真つき身分証明書の写しを、（1）と併せて当社へご提出ください。代理人の方が手続きする場合は、代理人の上記証明書と、代理権があることを証明する書面もご提出ください。
            <br />
            （3）内容確認、求めへの回答
            <br />
            ご提出いただいた手続き用紙と身分証明書を当社にて確認し、対応を検討致します。開示等求めへの回答は、請求者の連絡先へ、書面にて郵送致します。尚、郵送にかかる費用は開示手数料として請求者より徴収致します。
            <br />
            ※利用目的の通知・開示請求に関する手数料
            <br />
            〔参考〕25gまでの定形郵便物の場合の料金（2021年8月1日　消費税10％現在）
            <br />
            郵便料金84円＋書留料金435円＋本人限定受取郵便料金105円＝ 計624円
            <br />
            <br />
            7．認定個人情報保護団体の名称及び苦情の解決の申出先
            <br />
            （1）認定個人情報保護団体の名称：一般財団法人日本情報経済社会推進協会
            <br />
            （2）お問い合わせ先：個人情報保護苦情相談室
            <br />
            <span className="__indent"></span>
            住所：東京都港区六本木一丁9番9号六本木ファーストビル内
            <br />
            <span className="__indent"></span>TEL：03-5860-7565、0120-700-779
            <br />
            <span className="__indent"></span>
            <a
              href="https://www.jipdec.or.jp/protection_org/complaint_processing.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.jipdec.or.jp/protection_org/complaint_processing.html
            </a>
            <br />
            <br />
            個人情報相談窓口：株式会社NEWONE　個人情報保護管理者
            <br />
            （管理部門/取締役　　mail : info@new-one.co.jp）
          </p>
        </div>
      </div>

      <div className={classes.checkbox}>
        <label className={classes.check}>
          <input
            onChange={handleChange}
            type="checkbox"
            name="checkPersonalInfo"
            checked={checked}
          />
          <span className={classes.agree}>
            上記、個人情報の取り扱いおよび
            {isXsDown ? <br /> : null}
            <Link
              to={PAGE_TERMS}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.terms}
            >
              利用規約
            </Link>
            に同意する
          </span>
        </label>
      </div>
    </>
  );
};

export default PersonalInfoAgree;
