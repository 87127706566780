import React from 'react';
import { Box } from '@material-ui/core';
import { COLOR_LIGHT_GRAY } from '../../../../../../consts';

type TabPanelProps = {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{
        border: 'solid 0.7px ' + COLOR_LIGHT_GRAY,
        borderTop: 0,
      }}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
