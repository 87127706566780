import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Grid,
  Paper,
  useTheme,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import {
  AGES,
  collectionName,
  COLOR_DARK_GRAY,
  COLOR_FAILURE,
  COLOR_LIGHT_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_WARNING,
  COLOR_WHITE,
  DEFAULT_SCORE,
  MARGIN_XS,
  PAGE_MYPAGE,
  TURN_SCENE,
} from '../../../../../consts';
import { FirebaseContext } from '../../../../../contexts';
import {
  db,
  readActiveGroup,
  readActiveGroupGames,
} from '../../../../../firebase';
import Loading from '../../../../../components/shared/Loading';
import TabPanel from './components/TabPanel';
import BaseContainerGroupActive from '../../../../../components/shared/BaseContainerGroupActive';
import { getAverage5Scores, getCardName } from '../../../../../functions';
import CenterDiv from '../../../../../components/shared/CenterDiv';
import MaxWidthAdjust from '../../../../../components/shared/MaxWidthAdjust';
import TransparentButton from '../../../../Game/components/TransparentButton';
import ColorsLineChart from '../../../../Game/components/ScoreLineChart/ColorsLineChart';
import GroupGameList from './components/GroupGameList';
import NamedList from './components/NamedList';
import TurnSceneLabel from '../../../../Game/components/TurnSceneLabel';
import ActiveScores from '../../../../Game/components/ScoreLineChart/ActiveScores';
import SortList from './components/SortList';

const a11yProps = (index: any) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    tabs: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
    },
    access: {
      fontSize: 28,
      fontWeight: 'bold',
      '& span': {
        fontSize: 38,
        color: COLOR_WARNING,
      },
    },
    info: {
      '& span': {
        padding: '3px 12px',
        marginRight: 5,
        color: COLOR_WHITE,
        fontSize: 14,
        fontWeight: 'bold',
        backgroundColor: COLOR_DARK_GRAY,
        borderRadius: 9999,
      },
    },
    fontRed: {
      color: COLOR_FAILURE,
    },
    userCount: {
      fontSize: 14,
      '& span': {
        fontSize: 18,
        fontWeight: 'bold',
      },
    },
    gamesFormat: {
      marginTop: MARGIN_XS,
    },
    gridPaper: {
      borderRadius: 10,
      padding: '15px 6px',
    },
    labelWrap: {
      marginBottom: MARGIN_XS,
    },
    labelTitle: {
      padding: '5px 12px',
      color: COLOR_WHITE,
      fontSize: 14,
      fontWeight: 'bold',
      backgroundColor: COLOR_LIGHT_BLACK,
      borderRadius: 9999,
    },
    result: {
      marginBottom: MARGIN_XS,
    },
    resultLabel: {
      padding: '3px 12px',
      marginRight: 5,
      color: COLOR_WHITE,
      fontSize: 14,
      fontWeight: 'bold',
      backgroundColor: COLOR_DARK_GRAY,
      borderRadius: 9999,
    },
    avg: {
      marginBottom: 8,
    },
    userName: {
      borderBottom: '5px solid ' + COLOR_LIGHT_GRAY,
      lineHeight: 0.9,
    },
    tableWrapper: {
      width: '100%',
      maxWidth: 860,
      '& th': {
        whiteSpace: 'nowrap',
      },
    },
    tableCellHead: {
      fontWeight: 'bold',
    },
  })
);

export const pattern = {
  tableList: 'tableList',
  graph: 'graph',
  score: 'score',
  simple: 'simple',
};

const makeTitle = (turn: number) => ({
  turnStr: `${turn}ターン目`,
  turnAge: turn !== 0 ? ` / ${AGES[turn - 1]}` : '',
});

const makeActiveScores = (game: any) => {
  return game?.scores6
    ? game.scores6
    : game?.scores5
    ? game.scores5
    : game?.scores4
    ? game.scores4
    : game?.scores3
    ? game.scores3
    : game?.scores2
    ? game.scores2
    : game?.scores1
    ? game.scores1
    : game?.scores0
    ? game.scores0
    : DEFAULT_SCORE;
};

const makePreviousScores = (game: any) => {
  return game?.scores6
    ? game.scores5
    : game?.scores5
    ? game.scores4
    : game?.scores4
    ? game.scores3
    : game?.scores3
    ? game.scores2
    : game?.scores2
    ? game.scores1
    : game?.scores1
    ? game.scores0
    : DEFAULT_SCORE;
};

const makeLabel = (scene: string) => {
  return scene === 'start'
    ? '人生目標'
    : scene === 'checkGoal'
    ? '人生目標 確認'
    : scene === 'job'
    ? '仕事'
    : scene === 'private'
    ? 'プライベート'
    : scene === 'happening'
    ? 'ハプニング'
    : scene === 'checkHappening'
    ? 'ハプニング 確認'
    : scene === 'social'
    ? '社会変化'
    : scene === 'checkSocial'
    ? '社会変化 確認'
    : scene === 'result'
    ? '最終結果'
    : 'Error: unknown label';
};

const GroupActive: React.FC = () => {
  const { user } = useContext(FirebaseContext);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const [isManager, setIsManager] = useState<boolean>();
  const [activeGroup, setActiveGroup] = useState<any>(null);
  const [value, setValue] = useState(0);
  const [groupGames, setGroupGames] = useState<any[] | null>(null);
  const [format, setFormat] = useState(pattern.tableList);
  const [isNamed, setIsNamed] = useState(1);
  const [sortNum, setSortNum] = useState(2);

  const id = location.pathname.split('/').slice(-1)[0];

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const sortGames = (arr: any[]) => {
    return arr.sort((a, b) => {
      const aS = makeActiveScores(a);
      const bS = makeActiveScores(b);

      // 文字列ソート
      // https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
      // https://qiita.com/RAWSEQ/items/99f40ec11eef995dc174#%E8%BF%BD%E8%A8%98%E6%9B%B4%E3%81%AB%E8%89%AF%E3%81%84%E3%82%B3%E3%83%BC%E3%83%89
      // 文字列の比較演算
      // https://uxmilk.jp/46945
      const nameA = a.options.playerName
        // 大文字・小文字の区別をなくす。
        .toUpperCase()
        // 数字を正規表現で抽出し、0埋めして10桁で比較できるようにする。
        .replace(/(\d+)/g, (m: string) => ('000000000' + m).slice(-10));
      const nameB = b.options.playerName
        .toUpperCase()
        .replace(/(\d+)/g, (m: string) => ('000000000' + m).slice(-10));
      const nameComparison = nameA < nameB ? -1 : 1;

      return sortNum === 1
        ? // 最終スコア 降順
          b.options.resultScore - a.options.resultScore
        : sortNum === 2
        ? // 名前 昇順
          nameComparison
        : // 平均値 降順
          getAverage5Scores(bS) - getAverage5Scores(aS);
    });
  };

  const handleClickGroupGames = useCallback(async () => {
    await readActiveGroupGames(
      collectionName.activeGroupGames,
      id,
      setGroupGames
    );
  }, [id]);

  useEffect(() => {
    if (user?.email && user.emailVerified) {
      db.collection(collectionName.manager)
        .where('email', '==', user.email)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length) {
            setIsManager(true);
          } else {
            setIsManager(false);
          }
        })
        .catch((error) => {
          alert(`Error getting documents: ${error}`);
        });
    }
    if (isManager === false) {
      history.push(PAGE_MYPAGE);
    }
  }, [history, isManager, user]);

  useEffect(() => {
    // メモリリーク抑止のためのアンマウントフラグ
    // https://qiita.com/daishi/items/4423878a1cd7a0ab69eb
    let unmounted = false;

    if (!unmounted) {
      const getData = async () => {
        if (user?.email && isManager) {
          await readActiveGroup(
            collectionName.gameGroups,
            user.uid,
            id,
            setActiveGroup
          );
        }
      };
      getData();
    }

    // アンマウント時のクリーンアップ
    const cleanup = () => {
      unmounted = true;
    };
    return cleanup;
  }, [id, isManager, user]);

  return (
    <>
      {isManager && activeGroup && (
        <>
          <BaseContainerGroupActive title={activeGroup.groupName}>
            <div className={classes.root}>
              <div className={classes.tabs}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="group active tabs"
                  >
                    <Tab label="アクセス" {...a11yProps(0)} />
                    <Tab label="登録情報" {...a11yProps(1)} />
                    <Tab label="ゲーム進捗" {...a11yProps(2)} />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <Typography className={classes.access}>
                    ID
                    <br />
                    <span>
                      {activeGroup.idInc} - {activeGroup.idNum} -{' '}
                      {activeGroup.idChar}
                    </span>
                    <br />
                    <br />
                    アクセスコード
                    <br />
                    <span>{activeGroup.aCode}</span>
                  </Typography>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Typography className={classes.info}>
                    <span>グループ名</span>
                    <br />
                    {activeGroup.groupName}
                    <br />
                    <br />
                    <span>社会変化 1</span>
                    <br />
                    {getCardName(activeGroup.soc1)}
                    <br />
                    <br />
                    <span>社会変化 2</span>
                    <br />
                    {getCardName(activeGroup.soc2)}
                    <br />
                    <br />
                    <span>社会変化 3</span>
                    <br />
                    {getCardName(activeGroup.soc3)}
                  </Typography>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <CenterDiv marginTop="none">
                    <MaxWidthAdjust>
                      <TransparentButton
                        color="black"
                        padding={14}
                        onClick={handleClickGroupGames}
                      >
                        参加者のデータを取得
                      </TransparentButton>
                    </MaxWidthAdjust>
                  </CenterDiv>

                  {groupGames === null && (
                    <CenterDiv>
                      <Typography>
                        上記のボタンをクリックすると、参加者のデータを取得できます。
                      </Typography>
                    </CenterDiv>
                  )}
                  {groupGames && groupGames.length !== 0 && (
                    <>
                      <CenterDiv marginTop="none">
                        {isXsDown ? (
                          <Grid container justify="center">
                            <Grid item xs={6}>
                              <CenterDiv marginTop="none">
                                <GroupGameList
                                  format={format}
                                  setFormat={setFormat}
                                  pattern={pattern}
                                />
                              </CenterDiv>
                            </Grid>
                            <Grid item xs={6}>
                              <CenterDiv marginTop="none">
                                <NamedList
                                  isNamed={isNamed}
                                  setIsNamed={setIsNamed}
                                />
                              </CenterDiv>
                            </Grid>
                            <Grid item xs={6}>
                              <CenterDiv marginTop="none">
                                <SortList
                                  sortNum={sortNum}
                                  setSortNum={setSortNum}
                                />
                              </CenterDiv>
                            </Grid>
                          </Grid>
                        ) : (
                          <>
                            <GroupGameList
                              format={format}
                              setFormat={setFormat}
                              pattern={pattern}
                            />
                            <NamedList
                              isNamed={isNamed}
                              setIsNamed={setIsNamed}
                            />
                            <SortList
                              sortNum={sortNum}
                              setSortNum={setSortNum}
                            />
                          </>
                        )}
                      </CenterDiv>

                      <CenterDiv marginTop="XS">
                        <div className={classes.userCount}>
                          参加者<span> {groupGames.length} </span>名
                        </div>
                      </CenterDiv>

                      {format !== pattern.tableList && (
                        <Grid
                          container
                          spacing={2}
                          className={classes.gamesFormat}
                        >
                          {sortGames(groupGames).map((game) => (
                            <Grid item xs={12} sm={6} md={4} key={game.id}>
                              <Paper
                                elevation={3}
                                className={classes.gridPaper}
                              >
                                <div className={classes.labelWrap}>
                                  <CenterDiv marginTop="none">
                                    <div className={classes.labelTitle}>
                                      {makeTitle(game.turn).turnStr}
                                      {makeTitle(game.turn).turnAge}
                                    </div>
                                  </CenterDiv>
                                  <TurnSceneLabel turnScene={game.turnScene} />
                                </div>

                                {format === pattern.simple && (
                                  <CenterDiv marginTop="none">
                                    <div className={classes.avg}>
                                      【平均値】
                                      {getAverage5Scores(
                                        makeActiveScores(game)
                                      )}
                                    </div>
                                  </CenterDiv>
                                )}
                                {format === pattern.graph && (
                                  <ColorsLineChart game={game} />
                                )}
                                {format === pattern.score && (
                                  <CenterDiv marginTop="none">
                                    <ActiveScores
                                      game={game}
                                      activeScores={makeActiveScores(game)}
                                      previousScores={makePreviousScores(game)}
                                    />
                                  </CenterDiv>
                                )}
                                {game.turnScene === TURN_SCENE.result && (
                                  <div className={classes.result}>
                                    <CenterDiv marginTop="min">
                                      <div>
                                        <span className={classes.resultLabel}>
                                          人生目標
                                        </span>
                                        {game.options.resultGoal
                                          ? '達成'
                                          : '未達成'}
                                      </div>
                                    </CenterDiv>
                                    <CenterDiv marginTop="min">
                                      <div>
                                        <span className={classes.resultLabel}>
                                          最終スコア
                                        </span>
                                        {game.options.resultScore}
                                      </div>
                                    </CenterDiv>
                                  </div>
                                )}
                                {isNamed === 1 && (
                                  <CenterDiv marginTop="min">
                                    <div className={classes.userName}>
                                      {game.options.playerName ||
                                        '匿名ユーザー'}{' '}
                                      さん
                                    </div>
                                  </CenterDiv>
                                )}
                              </Paper>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                      {format === pattern.tableList && (
                        <CenterDiv marginTop="XS">
                          <div className={classes.tableWrapper}>
                            <TableContainer>
                              <Table size="small" aria-label="a dense table">
                                <TableHead>
                                  <TableRow>
                                    {isNamed === 1 && (
                                      <TableCell
                                        className={classes.tableCellHead}
                                      >
                                        名前
                                      </TableCell>
                                    )}
                                    <TableCell
                                      align={isNamed ? 'right' : 'inherit'}
                                      className={classes.tableCellHead}
                                    >
                                      ターン
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableCellHead}
                                    >
                                      フェーズ
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={classes.tableCellHead}
                                    >
                                      平均値
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={classes.tableCellHead}
                                    >
                                      最終スコア
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={classes.tableCellHead}
                                    >
                                      達成結果
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {sortGames(groupGames).map((game) => (
                                    <React.Fragment key={game.id}>
                                      <TableRow>
                                        {isNamed === 1 && (
                                          <>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {game.options.playerName ||
                                                '匿名ユーザー'}{' '}
                                              さん
                                            </TableCell>
                                            <TableCell align="right">
                                              {game.turn}
                                            </TableCell>
                                          </>
                                        )}
                                        {isNamed !== 1 && (
                                          <TableCell component="th" scope="row">
                                            {game.turn}
                                          </TableCell>
                                        )}
                                        <TableCell align="center">
                                          {makeLabel(game.turnScene)}
                                        </TableCell>
                                        <TableCell align="right">
                                          {getAverage5Scores(
                                            makeActiveScores(game)
                                          )}
                                        </TableCell>
                                        <TableCell align="right">
                                          {game.options.resultScore || '-'}
                                        </TableCell>
                                        <TableCell align="right">
                                          {game.options.resultGoal === null
                                            ? '-'
                                            : game.options.resultGoal === true
                                            ? '○'
                                            : '×'}
                                        </TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </CenterDiv>
                      )}
                    </>
                  )}
                  {groupGames && groupGames.length === 0 && (
                    <CenterDiv>
                      <Typography className={classes.fontRed}>
                        データが存在しません。
                      </Typography>
                    </CenterDiv>
                  )}
                </TabPanel>
              </div>
            </div>
          </BaseContainerGroupActive>
        </>
      )}

      {!isManager && <Loading />}
    </>
  );
};

export default GroupActive;
