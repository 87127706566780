import { format } from 'date-fns';
import {
  CARDS_HAP,
  CARDS_JOB,
  CARDS_PRI,
  CARDS_SOC,
  EVENTS_FAILURE_KEYS,
  EVENTS_INFO_KEYS,
  EVENTS_SUCCESS_KEYS,
} from './consts';
import { ActiveGames } from './models';

/**
 * スコア平均取得
 * ! 自由な時間はスコア平均に含まない
 */
export const getAverage5Scores = (arr: number[]) => {
  // 自由な時間を除外
  const checkedArr = arr.filter((el, index) => index !== 5);
  // 合計
  const sum = checkedArr.reduce((prev, current) => prev + current, 0);
  // 平均（小数第二位を四捨五入）
  return Math.round((sum / checkedArr.length) * 10) / 10;
};

/**
 * グループの有効期限を取得
 * ! 現在の設定 => 1ヶ月
 */
export const getGroupTimeLimit = (created: Date) => {
  // https://date-fns.org/v2.28.0/docs/format
  return format(created.setMonth(created.getMonth() + 1), 'yyyy/M/d');
};

/**
 * グループの有効期限の判定
 * ! 現在の設定 => 1ヶ月
 * ! 時刻はクリアし、日付基準で判定
 */
export const isWithinDeadline = (created: Date) => {
  const limitDay = new Date(getGroupTimeLimit(created)); // 期限の0時
  const today = new Date(format(new Date(), 'yyyy/M/d')); // 今日の0時
  // console.log('limit', limitDay);
  // console.log('今日', today);
  if (limitDay >= today) {
    return true;
  } else {
    return false;
  }
};

/**
 * カード履歴 表示名取得
 */
export const getCardName = (card: string) => {
  switch (card.split('-')[0]) {
    case 'h':
      const hap =
        card === CARDS_HAP[0]
          ? '0 介護'
          : card === CARDS_HAP[1]
          ? '1 けが'
          : card === CARDS_HAP[2]
          ? '2 病気'
          : card === CARDS_HAP[3]
          ? '3 穏やかな日常'
          : '[カード名が存在しません]';
      return hap;
    case 'j':
      const job =
        card === CARDS_JOB[0]
          ? '00 なにもしない'
          : card === CARDS_JOB[1]
          ? '01 大手日系企業A社'
          : card === CARDS_JOB[2]
          ? '02 大手外資系企業B社'
          : card === CARDS_JOB[3]
          ? '03 ベンチャー企業C社'
          : card === CARDS_JOB[4]
          ? '04 中小企業D社'
          : card === CARDS_JOB[5]
          ? '05 起業'
          : card === CARDS_JOB[6]
          ? '06 大学・大学院'
          : card === CARDS_JOB[7]
          ? '07 フリーランス'
          : card === CARDS_JOB[8]
          ? '08 気ままな旅'
          : card === CARDS_JOB[9]
          ? '09 公務員'
          : card === CARDS_JOB[10]
          ? '10 NPO・NGO'
          : '[カード名が存在しません]';
      return job;
    case 'p':
      const pri =
        card === CARDS_PRI[0]
          ? '00 なにもしない'
          : card === CARDS_PRI[1]
          ? '01 賃貸住宅への転居'
          : card === CARDS_PRI[2]
          ? '02 住宅購入'
          : card === CARDS_PRI[3]
          ? '03 結婚'
          : card === CARDS_PRI[4]
          ? '04 出産・育児'
          : card === CARDS_PRI[5]
          ? '05 異業種間交流'
          : card === CARDS_PRI[6]
          ? '06 健康診断'
          : card === CARDS_PRI[7]
          ? '07 海外旅行'
          : card === CARDS_PRI[8]
          ? '08 ボランティア活動'
          : card === CARDS_PRI[9]
          ? '09 国内旅行'
          : card === CARDS_PRI[10]
          ? '10 セミナー受講'
          : card === CARDS_PRI[11]
          ? '11 スポーツ'
          : card === CARDS_PRI[12]
          ? '12 資格取得'
          : card === CARDS_PRI[13]
          ? '13 アウトドア'
          : card === CARDS_PRI[14]
          ? '14 投資の勉強'
          : card === CARDS_PRI[15]
          ? '15 投資'
          : card === CARDS_PRI[16]
          ? '16 読書'
          : card === CARDS_PRI[17]
          ? '17 芸術鑑賞'
          : card === CARDS_PRI[18]
          ? '18 語学勉強'
          : card === CARDS_PRI[19]
          ? '19 楽器演奏'
          : card === CARDS_PRI[20]
          ? '20 自動車購入'
          : card === CARDS_PRI[21]
          ? '21 副業'
          : card === CARDS_PRI[22]
          ? '22 パート・アルバイト'
          : '[カード名が存在しません]';
      return pri;
    case 's':
      const soc =
        card === CARDS_SOC[0]
          ? '1 医療技術の進歩'
          : card === CARDS_SOC[1]
          ? '2 働き方改革'
          : card === CARDS_SOC[2]
          ? '3 大不況'
          : card === CARDS_SOC[3]
          ? '4 AI技術の進歩'
          : card === CARDS_SOC[4]
          ? '5 副業奨励'
          : card === CARDS_SOC[5]
          ? '6 スキルアップ奨励'
          : card === CARDS_SOC[6]
          ? '7 産業構造の変化'
          : '[カード名が存在しません]';
      return soc;
    default:
      return '[カード名が存在しません]';
  }
};

/**
 * スコア計算
 */
// フロー
// [1] 自由な時間のスコアを算出: アクティブターンで選んだ仕事カード・および一部のそれ以外のカードによって自由な時間が決まる。前ターンの自由な時間は影響しない。
// [2] 自由な時間と消費時間の差分: 消費時間の合計値を算出し、[1]との差分を求め、自由な時間が足りるかチェックする。
// [3] 他のスコアを算出: 自由な時間は、[1]を基準値とする。他は、前ターンのスコアを基準値とする。自由な時間で分岐するカードは[2]の値を参照する。
export const calcScores = (game: ActiveGames, previousScores: number[]) => {
  let scoreLeisure = 0; // 自由な時間のみ、前回のスコアに影響を受けない。
  let scoreMoneys = [previousScores[0]];
  let scoreHealths = [previousScores[1]];
  let scorePerformances = [previousScores[2]];
  let scoreManagements = [previousScores[3]];
  let scoreNetworks = [previousScores[4]];

  let scoreAverage = getAverage5Scores(previousScores);

  let events: string[] = [];
  // 倒産フラグをクリア
  let options = { ...game.options, bankrupt: false };

  const activeJob = game.cardJob![game.turn - 1];
  const activeHappening = game.cardHappening![game.turn - 1];
  const privateKey = `cardPrivate${game.turn}`;
  const activePrivates: string[] = game[privateKey];
  const activeSocialChange =
    game.turn === 2 || game.turn === 4 || game.turn === 6
      ? game.turn === 2
        ? game.cardSocialChange![0]
        : game.turn === 4
        ? game.cardSocialChange![1]
        : game.cardSocialChange![2]
      : '';

  // 0 <= ランダムな数値 < 100
  const rand = Math.floor(Math.random() * 100);

  const calc = () => {
    /**
     * ========================================
     * [1] 自由な時間のスコアを算出
     */

    // 仕事カード
    if (activeJob === CARDS_JOB[0]) {
      scoreLeisure = 100;
    }
    if (activeJob === CARDS_JOB[1]) {
      scoreLeisure = 30;
    }
    if (activeJob === CARDS_JOB[2]) {
      scoreLeisure = 20;
    }
    if (activeJob === CARDS_JOB[3]) {
      scoreLeisure = 20;
    }
    if (activeJob === CARDS_JOB[4]) {
      scoreLeisure = 50;
    }
    if (activeJob === CARDS_JOB[5]) {
      scoreLeisure = 10;
    }
    if (activeJob === CARDS_JOB[6]) {
      scoreLeisure = 60;
    }
    if (activeJob === CARDS_JOB[7]) {
      scoreLeisure = 40;
    }
    if (activeJob === CARDS_JOB[8]) {
      scoreLeisure = 100;
    }
    if (activeJob === CARDS_JOB[9]) {
      scoreLeisure = 50;
    }
    if (activeJob === CARDS_JOB[10]) {
      scoreLeisure = 50;
    }

    // 仕事カード以外で自由な時間に関わるカード
    if (activeHappening === CARDS_HAP[0]) {
      scoreLeisure -= 20;
    }
    if (activePrivates.some((pri) => pri === CARDS_PRI[1])) {
      scoreLeisure += 10;
    }
    if (activePrivates.some((pri) => pri === CARDS_PRI[3])) {
      scoreLeisure -= 10;
    }
    if (activePrivates.some((pri) => pri === CARDS_PRI[4])) {
      switch (activeJob) {
        case CARDS_JOB[1]:
        case CARDS_JOB[2]:
        case CARDS_JOB[3]:
        case CARDS_JOB[4]:
        case CARDS_JOB[9]:
        case CARDS_JOB[10]:
          scoreLeisure -= 15;
          break;
        case CARDS_JOB[5]:
        case CARDS_JOB[6]:
        case CARDS_JOB[7]:
        case CARDS_JOB[8]:
          scoreLeisure -= 25;
          break;
        default:
          break;
      }
    }
    if (activePrivates.some((pri) => pri === CARDS_PRI[20])) {
      scoreLeisure += 20;
    }
    // 社会変化「2 働き方改革」
    if (game.cardSocialChange?.some((soc) => soc === CARDS_SOC[1])) {
      scoreLeisure = scoreLeisure * 1.3;
    }

    // ターン補正
    if (game.turn === 2) {
      scoreLeisure *= 0.95;
    } else if (game.turn === 3) {
      scoreLeisure *= 0.9;
    } else if (game.turn === 4) {
      scoreLeisure *= 0.9;
    } else if (game.turn === 5) {
      scoreLeisure *= 0.95;
    }

    /**
     * ========================================
     * [2] 自由な時間と消費時間の差分
     */

    let consumedTime = 0;
    // プライベートカード
    if (activePrivates.some((pri) => pri === CARDS_PRI[3])) {
      consumedTime += 30;
    }
    if (activePrivates.some((pri) => pri === CARDS_PRI[5])) {
      consumedTime += 30;
    }
    if (activePrivates.some((pri) => pri === CARDS_PRI[7])) {
      consumedTime += 40;
    }
    if (activePrivates.some((pri) => pri === CARDS_PRI[8])) {
      consumedTime += 20;
    }
    if (activePrivates.some((pri) => pri === CARDS_PRI[9])) {
      consumedTime += 30;
    }
    if (activePrivates.some((pri) => pri === CARDS_PRI[11])) {
      consumedTime += 20;
    }
    if (activePrivates.some((pri) => pri === CARDS_PRI[13])) {
      consumedTime += 20;
    }
    if (activePrivates.some((pri) => pri === CARDS_PRI[14])) {
      consumedTime += 20;
    }
    if (activePrivates.some((pri) => pri === CARDS_PRI[17])) {
      consumedTime += 20;
    }
    if (activePrivates.some((pri) => pri === CARDS_PRI[19])) {
      consumedTime += 20;
    }
    if (activePrivates.some((pri) => pri === CARDS_PRI[21])) {
      consumedTime += 30;
    }
    if (activePrivates.some((pri) => pri === CARDS_PRI[22])) {
      consumedTime += 15;
    }
    // 社会変化カード
    if (activeSocialChange === CARDS_SOC[5]) {
      consumedTime += 40;
    }
    if (activeSocialChange === CARDS_SOC[6]) {
      consumedTime += 30;
    }
    // 差分算出
    const difference = scoreLeisure - consumedTime;

    /**
     * ========================================
     * [3] 他のスコアを算出
     */

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // 共有Factor

    // 業務遂行能力
    const commonPerformanceFactor = (x: number, y: number) => {
      const base = x - (game.turn - 1) * y;
      const limitChecked = base > 0.001 ? base : 0.001;
      return limitChecked;
    };
    // マネジメント
    const commonManagementFactor = (x: number, y: number) => {
      const base = (1 + game.options.jobKeep * x) * (1 + game.turn * y) - 1;
      const limitChecked = base < 1.25 ? base : 1.25;
      return limitChecked;
    };
    // 人脈・評判
    const commonNetworkFactor = (x: number, y: number) => {
      const base = x - (game.turn - 1) * y;
      const limitChecked = base > 0.001 ? base : 0.001;
      return limitChecked;
    };

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // 仕事カード
    if (activeJob === CARDS_JOB[1]) {
      const moneyFactor = (game.turn * 0.06 + game.options.jobKeep * 0.1) / 3;

      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * moneyFactor];
      scorePerformances = [
        ...scorePerformances,
        scorePerformances[0] * commonPerformanceFactor(0.2, 0.05),
      ];
      scoreManagements = [
        ...scoreManagements,
        scoreManagements[0] * commonManagementFactor(0.07, 0.02),
      ];
      scoreNetworks = [
        ...scoreNetworks,
        scoreNetworks[0] * commonNetworkFactor(0.1, 0.05),
      ];

      if (rand <= 2) {
        options = { ...options, bankrupt: true };
        events = [...events, EVENTS_FAILURE_KEYS.bankrupt];
      }
    }

    if (activeJob === CARDS_JOB[2]) {
      const factors = () => {
        const standards = [35, 45, 55, 60, 65, 70];
        const moneyHigh = 0.4;
        const moneyLow = 0.15;
        const performanceHigh = 0.37;
        const performanceLow = 0.27;

        switch (game.turn) {
          case 1:
            return {
              money:
                scorePerformances[0] >= standards[0] ? moneyHigh : moneyLow,
              performance:
                scorePerformances[0] >= standards[0]
                  ? performanceHigh
                  : performanceLow,
            };
          case 2:
            return {
              money:
                scorePerformances[0] >= standards[1] ? moneyHigh : moneyLow,
              performance:
                scorePerformances[0] >= standards[1]
                  ? performanceHigh
                  : performanceLow,
            };
          case 3:
            return {
              money:
                scorePerformances[0] >= standards[2] ? moneyHigh : moneyLow,
              performance:
                scorePerformances[0] >= standards[2]
                  ? performanceHigh
                  : performanceLow,
            };
          case 4:
            return {
              money:
                scorePerformances[0] >= standards[3] ? moneyHigh : moneyLow,
              performance:
                scorePerformances[0] >= standards[3]
                  ? performanceHigh
                  : performanceLow,
            };
          case 5:
            return {
              money:
                scorePerformances[0] >= standards[4] ? moneyHigh : moneyLow,
              performance:
                scorePerformances[0] >= standards[4]
                  ? performanceHigh
                  : performanceLow,
            };
          case 6:
            return {
              money:
                scorePerformances[0] >= standards[5] ? moneyHigh : moneyLow,
              performance:
                scorePerformances[0] >= standards[5]
                  ? performanceHigh
                  : performanceLow,
            };
          default:
            alert('Error: factors()');
            return { money: 0, performance: 0 };
        }
      };
      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * factors().money];
      scoreHealths = [...scoreHealths, scoreHealths[0] * -0.02];
      scorePerformances = [
        ...scorePerformances,
        scorePerformances[0] * factors().performance,
      ];
      scoreManagements = [
        ...scoreManagements,
        scoreManagements[0] * commonManagementFactor(0.08, 0.02),
      ];
      scoreNetworks = [
        ...scoreNetworks,
        scoreNetworks[0] * commonNetworkFactor(0.205, 0.065),
      ];

      if (rand <= 2) {
        options = { ...options, bankrupt: true };
        events = [...events, EVENTS_FAILURE_KEYS.bankrupt];
      }
    }

    if (activeJob === CARDS_JOB[3]) {
      const makeMoneyFactor = () => {
        if (rand > 25) {
          return (
            (scorePerformances[0] * 0.0045 + scoreNetworks[0] * 0.0045) / 2
          );
        } else {
          options = { ...options, bankrupt: true };
          events = [
            ...events,
            EVENTS_FAILURE_KEYS.venture,
            EVENTS_FAILURE_KEYS.bankrupt,
          ];
          return -0.2;
        }
      };
      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * makeMoneyFactor()];
      scorePerformances = [
        ...scorePerformances,
        scorePerformances[0] * commonPerformanceFactor(0.255, 0.065),
      ];
      scoreManagements = [
        ...scoreManagements,
        scoreManagements[0] * commonManagementFactor(0.09, 0.02),
      ];
      scoreNetworks = [
        ...scoreNetworks,
        scoreNetworks[0] * commonManagementFactor(0.155, 0.065),
      ];
    }

    if (activeJob === CARDS_JOB[4]) {
      const factors =
        scorePerformances[0] >= scoreAverage * 0.7 ||
        scoreManagements[0] >= scoreAverage * 0.7 ||
        scoreNetworks[0] >= scoreAverage * 0.7
          ? {
              moneyFactor:
                ((scorePerformances[0] +
                  scoreManagements[0] +
                  scoreNetworks[0]) *
                  0.003) /
                3,
              performanceFactor: commonPerformanceFactor(0.085, 0.065),
              networkFactor: commonNetworkFactor(0.105, 0.065),
            }
          : {
              moneyFactor:
                ((scorePerformances[0] +
                  scoreManagements[0] +
                  scoreNetworks[0]) *
                  0.0025) /
                3,
              performanceFactor: commonPerformanceFactor(0.035, 0.065),
              networkFactor: commonNetworkFactor(0.055, 0.065),
            };

      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * factors.moneyFactor];
      scorePerformances = [
        ...scorePerformances,
        scorePerformances[0] * factors.performanceFactor,
      ];
      scoreManagements = [
        ...scoreManagements,
        scoreManagements[0] * commonManagementFactor(0.08, 0.02),
      ];
      scoreNetworks = [
        ...scoreNetworks,
        scoreNetworks[0] * factors.networkFactor,
      ];

      if (rand <= 5) {
        options = { ...options, bankrupt: true };
        events = [...events, EVENTS_FAILURE_KEYS.bankrupt];
      }
    }

    if (activeJob === CARDS_JOB[5]) {
      const threeScores =
        scorePerformances[0] + scoreManagements[0] + scoreNetworks[0];
      const failureRate =
        threeScores / 3 >= 60 ? 10 : threeScores / 3 >= 40 ? 30 : 50;
      const makeFactors = () => {
        if (rand > failureRate) {
          events = [...events, EVENTS_SUCCESS_KEYS.startBusiness];
          return {
            moneyFactor: threeScores * 0.003,
            healthFactor: 0.05,
            networkFactor: 0.2,
          };
        } else {
          options = { ...options, bankrupt: true };
          events = [
            ...events,
            EVENTS_FAILURE_KEYS.startBusiness,
            EVENTS_FAILURE_KEYS.bankrupt,
          ];
          return {
            moneyFactor: threeScores * 0.003 - 0.7,
            healthFactor: -0.2,
            networkFactor: -0.05,
          };
        }
      };
      const { moneyFactor, healthFactor, networkFactor } = makeFactors();

      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * moneyFactor];
      scoreHealths = [...scoreHealths, scoreHealths[0] * healthFactor];
      scorePerformances = [...scorePerformances, scorePerformances[0] * 0.15];
      scoreManagements = [...scoreManagements, scoreManagements[0] * 0.3];
      scoreNetworks = [...scoreNetworks, scoreNetworks[0] * networkFactor];
    }

    if (activeJob === CARDS_JOB[6]) {
      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.05];
      scorePerformances = [...scorePerformances, scorePerformances[0] * 0.3];
      scoreNetworks = [...scoreNetworks, scoreNetworks[0] * 0.05];
    }

    if (activeJob === CARDS_JOB[7]) {
      const factors =
        scorePerformances[0] >= scoreAverage * 0.7 &&
        scoreNetworks[0] >= scoreAverage * 0.7
          ? { moneyFactor: 0.4, networkFactor: 0.1 }
          : scorePerformances[0] < scoreAverage * 0.7 &&
            scoreNetworks[0] >= scoreAverage * 0.7
          ? { moneyFactor: 0.3, networkFactor: -0.05 }
          : scorePerformances[0] >= scoreAverage * 0.7 &&
            scoreNetworks[0] < scoreAverage * 0.7
          ? { moneyFactor: 0.2, networkFactor: 0.12 }
          : { moneyFactor: 0.1, networkFactor: -0.02 };

      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * factors.moneyFactor];
      scorePerformances = [...scorePerformances, scorePerformances[0] * 0.05];
      scoreNetworks = [
        ...scoreNetworks,
        scoreNetworks[0] * factors.networkFactor,
      ];
    }

    if (activeJob === CARDS_JOB[8]) {
      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.05];
      scoreHealths = [...scoreHealths, scoreHealths[0] * 0.3];
      scoreNetworks = [...scoreNetworks, scoreNetworks[0] * 0.15];
    }

    if (activeJob === CARDS_JOB[9]) {
      const moneyFactor = (game.turn * 0.05 + game.options.jobKeep * 0.09) / 3;

      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * moneyFactor];
      scorePerformances = [
        ...scorePerformances,
        scorePerformances[0] * commonPerformanceFactor(0.15, 0.07),
      ];
      scoreManagements = [
        ...scoreManagements,
        scoreManagements[0] * commonManagementFactor(0.05, 0.02),
      ];
      scoreNetworks = [
        ...scoreNetworks,
        scoreNetworks[0] * commonNetworkFactor(0.08, 0.07),
      ];
    }

    if (activeJob === CARDS_JOB[10]) {
      const threeScores =
        scorePerformances[0] + scoreManagements[0] + scoreNetworks[0];
      const moneyFactor = (threeScores / 3) * 0.0025;

      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * moneyFactor];
      scorePerformances = [
        ...scorePerformances,
        scorePerformances[0] * commonPerformanceFactor(0.15, 0.05),
      ];
      scoreManagements = [
        ...scoreManagements,
        scoreManagements[0] * commonManagementFactor(0.07, 0.02),
      ];
      scoreNetworks = [
        ...scoreNetworks,
        scoreNetworks[0] * commonNetworkFactor(0.16, 0.04),
      ];
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // プライベートカード
    for (const activePrivate of activePrivates) {
      if (activePrivate === CARDS_PRI[1]) {
        const moneyFactor =
          activeJob === CARDS_JOB[1] ||
          activeJob === CARDS_JOB[2] ||
          activeJob === CARDS_JOB[3] ||
          activeJob === CARDS_JOB[4] ||
          activeJob === CARDS_JOB[5] ||
          activeJob === CARDS_JOB[9] ||
          activeJob === CARDS_JOB[10]
            ? 0
            : -0.05;
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * moneyFactor];
      }

      if (activePrivate === CARDS_PRI[2]) {
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.4];
        scoreHealths = [...scoreHealths, scoreHealths[0] * 0.1];
        options.boughtHomes += 1;
      }

      if (activePrivate === CARDS_PRI[3]) {
        const healthFactor = difference >= 0 ? 0.2 : 0.05;
        scoreHealths = [...scoreHealths, scoreHealths[0] * healthFactor];
      }

      if (activePrivate === CARDS_PRI[4]) {
        const moneyFactor = () => {
          switch (activeJob) {
            case CARDS_JOB[1]:
            case CARDS_JOB[2]:
            case CARDS_JOB[3]:
            case CARDS_JOB[4]:
            case CARDS_JOB[9]:
            case CARDS_JOB[10]:
            case CARDS_JOB[5]:
              return -0.05;
            case CARDS_JOB[6]:
            case CARDS_JOB[7]:
            case CARDS_JOB[8]:
              return -0.15;
            default:
              return 0;
          }
        };
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * moneyFactor()];
      }

      if (activePrivate === CARDS_PRI[5]) {
        const networkFactor = difference >= 0 ? 0.05 : 0.03;
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.05];
        scoreNetworks = [...scoreNetworks, scoreNetworks[0] * networkFactor];
      }

      if (activePrivate === CARDS_PRI[6]) {
        const makeMoneyFactor = () => {
          switch (activeJob) {
            case CARDS_JOB[6]:
            case CARDS_JOB[7]:
            case CARDS_JOB[8]:
            case CARDS_JOB[0]:
              return -0.1;
            default:
              return 0;
          }
        };
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * makeMoneyFactor()];
        scoreHealths = [...scoreHealths, scoreHealths[0] * 0.1];
        options = {
          ...options,
          medicalCheckUp: game.options.medicalCheckUp + 1,
        };
      }

      if (activePrivate === CARDS_PRI[7]) {
        const healthFactor = difference >= 0 ? 0.1 : 0.05;
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.15];
        scoreHealths = [...scoreHealths, scoreHealths[0] * healthFactor];
      }

      if (activePrivate === CARDS_PRI[8]) {
        const factors =
          difference >= 0
            ? { money: 0, health: 0.1 }
            : { money: -0.05, health: 0.05 };
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * factors.money];
        scoreHealths = [...scoreHealths, scoreHealths[0] * factors.health];
        scoreNetworks = [...scoreNetworks, scoreNetworks[0] * 0.025];
      }

      if (activePrivate === CARDS_PRI[9]) {
        const healthFactor = difference >= 0 ? 0.05 : 0.025;
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.05];
        scoreHealths = [...scoreHealths, scoreHealths[0] * healthFactor];
      }

      if (activePrivate === CARDS_PRI[10]) {
        const makeMoneyFactor = () => {
          switch (activeJob) {
            case CARDS_JOB[6]:
            case CARDS_JOB[7]:
            case CARDS_JOB[8]:
            case CARDS_JOB[0]:
              return -0.02;
            default:
              return 0;
          }
        };
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * makeMoneyFactor()];
        scorePerformances = [...scorePerformances, scorePerformances[0] * 0.03];
        scoreManagements = [...scoreManagements, scoreManagements[0] * 0.03];
        scoreNetworks = [...scoreNetworks, scoreNetworks[0] * 0.03];
      }

      if (activePrivate === CARDS_PRI[11]) {
        const healthFactor = difference >= 0 ? 0.08 : 0.03;
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.02];
        scoreHealths = [...scoreHealths, scoreHealths[0] * healthFactor];
        scoreNetworks = [...scoreNetworks, scoreNetworks[0] * 0.03];
      }

      if (activePrivate === CARDS_PRI[12]) {
        const makeMoneyFactor = () => {
          switch (activeJob) {
            case CARDS_JOB[6]:
            case CARDS_JOB[7]:
            case CARDS_JOB[8]:
            case CARDS_JOB[0]:
              return -0.05;
            default:
              return 0;
          }
        };
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * makeMoneyFactor()];
        scorePerformances = [...scorePerformances, scorePerformances[0] * 0.03];
      }

      if (activePrivate === CARDS_PRI[13]) {
        const healthFactor = difference >= 0 ? 0.08 : 0.03;
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.02];
        scoreHealths = [...scoreHealths, scoreHealths[0] * healthFactor];
        scoreNetworks = [...scoreNetworks, scoreNetworks[0] * 0.03];
      }

      if (activePrivate === CARDS_PRI[14]) {
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.02];
        if (difference >= 0) {
          let { investmentLearnHigh } = { ...options };
          investmentLearnHigh += 1;
          options = { ...options, investmentLearnHigh };
        } else {
          let { investmentLearnLow } = { ...options };
          investmentLearnLow += 1;
          options = { ...options, investmentLearnLow };
        }
      }

      if (activePrivate === CARDS_PRI[15]) {
        const makeMoneyFactor = () => {
          if (rand > 50) {
            return {
              moneyFactor:
                0.1 +
                game.options.investmentLearnHigh * 0.015 +
                game.options.investmentLearnLow * 0.01,
              result: 's',
            };
          } else {
            return {
              moneyFactor:
                -0.1 +
                game.options.investmentLearnHigh * 0.015 +
                game.options.investmentLearnLow * 0.01,
              result: 'f',
            };
          }
        };
        const { moneyFactor, result } = makeMoneyFactor();

        events = [
          ...events,
          result === 's'
            ? EVENTS_SUCCESS_KEYS.investment
            : EVENTS_FAILURE_KEYS.investment,
        ];
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * moneyFactor];
      }

      if (activePrivate === CARDS_PRI[16]) {
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.02];
        scorePerformances = [...scorePerformances, scorePerformances[0] * 0.03];
        scoreManagements = [...scoreManagements, scoreManagements[0] * 0.03];
      }

      if (activePrivate === CARDS_PRI[17]) {
        const healthFactor = difference >= 0 ? 0.08 : 0.03;
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.02];
        scoreHealths = [...scoreHealths, scoreHealths[0] * healthFactor];
        scoreNetworks = [...scoreNetworks, scoreNetworks[0] * 0.03];
      }

      if (activePrivate === CARDS_PRI[18]) {
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.02];
        scorePerformances = [...scorePerformances, scorePerformances[0] * 0.03];
      }

      if (activePrivate === CARDS_PRI[19]) {
        const healthFactor = difference >= 0 ? 0.08 : 0.03;
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.02];
        scoreHealths = [...scoreHealths, scoreHealths[0] * healthFactor];
        scoreNetworks = [...scoreNetworks, scoreNetworks[0] * 0.03];
      }

      if (activePrivate === CARDS_PRI[20]) {
        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.2];
        scoreHealths = [...scoreHealths, scoreHealths[0] * 0.1];
      }

      if (activePrivate === CARDS_PRI[21]) {
        const makeFactors = () => {
          // 社会変化「5 副業奨励」
          if (game.cardSocialChange?.some((soc) => soc === CARDS_SOC[4])) {
            return {
              money: difference >= 0 ? 0.05 * 1.07 : 0.02 * 1.07,
              performance: difference >= 0 ? 0.1 : 0.05,
              network: difference >= 0 ? 0.1 : 0.05,
            };
          } else {
            return {
              money: difference >= 0 ? 0.05 : 0.02,
              performance: difference >= 0 ? 0.1 : 0.05,
              network: difference >= 0 ? 0.1 : 0.05,
            };
          }
        };
        const { money, performance, network } = makeFactors();

        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * money];
        scorePerformances = [
          ...scorePerformances,
          scorePerformances[0] * performance,
        ];
        scoreNetworks = [...scoreNetworks, scoreNetworks[0] * network];
      }

      if (activePrivate === CARDS_PRI[22]) {
        const moneyFactor = difference >= 0 ? 0.04 : 0.015;
        const performancesFactor = difference >= 0 ? 0.05 : 0.02;
        const networkFactor = difference >= 0 ? 0.05 : 0.02;

        scoreMoneys = [...scoreMoneys, scoreMoneys[0] * moneyFactor];
        scorePerformances = [
          ...scorePerformances,
          scorePerformances[0] * performancesFactor,
        ];
        scoreNetworks = [...scoreNetworks, scoreNetworks[0] * networkFactor];
      }
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // ハプニングカード
    if (activeHappening === CARDS_HAP[0]) {
      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.05];
      scoreHealths = [...scoreHealths, scoreHealths[0] * -0.05];
    }

    if (activeHappening === CARDS_HAP[1]) {
      const makeFactors = () => {
        if (
          activeJob === CARDS_JOB[1] ||
          activeJob === CARDS_JOB[2] ||
          activeJob === CARDS_JOB[3] ||
          activeJob === CARDS_JOB[4] ||
          activeJob === CARDS_JOB[5] ||
          activeJob === CARDS_JOB[9] ||
          activeJob === CARDS_JOB[10]
        ) {
          return {
            moneyFactor: 0,
            healthFactor: scoreHealths[0] >= 50 ? -0.02 : -0.1,
          };
        } else {
          return {
            moneyFactor: -0.2,
            healthFactor: scoreHealths[0] >= 50 ? -0.02 : -0.1,
          };
        }
      };
      const { moneyFactor, healthFactor } = makeFactors();

      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * moneyFactor];
      scoreHealths = [...scoreHealths, scoreHealths[0] * healthFactor];
    }

    if (activeHappening === CARDS_HAP[2]) {
      const medicalFactor = game.options.medicalCheckUp * 0.01;
      const makeFactors = () => {
        if (
          activeJob === CARDS_JOB[1] ||
          activeJob === CARDS_JOB[2] ||
          activeJob === CARDS_JOB[3] ||
          activeJob === CARDS_JOB[4] ||
          activeJob === CARDS_JOB[5] ||
          activeJob === CARDS_JOB[9] ||
          activeJob === CARDS_JOB[10]
        ) {
          return {
            moneyFactor: 0,
            healthFactor:
              scoreHealths[0] >= 70
                ? -0.1 + medicalFactor
                : -0.2 + medicalFactor,
          };
        } else {
          return {
            moneyFactor: -0.2,
            healthFactor:
              scoreHealths[0] >= 70
                ? -0.1 + medicalFactor
                : -0.2 + medicalFactor,
          };
        }
      };
      const { moneyFactor, healthFactor } = makeFactors();

      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * moneyFactor];
      scoreHealths = [...scoreHealths, scoreHealths[0] * healthFactor];
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // 社会変化カード
    // ※パラメーターに直接影響を与えるカードは下記2枚のみ

    // 社会変化「6 スキルアップ奨励」
    if (activeSocialChange === CARDS_SOC[5]) {
      const performancesFactor = difference >= 0 ? 0.15 : 0;

      scorePerformances = [
        ...scorePerformances,
        scorePerformances[0] * performancesFactor,
      ];
    }

    // 社会変化「7 産業構造の変化」
    if (activeSocialChange === CARDS_SOC[6]) {
      const moneyFactor =
        difference >= 0 && scorePerformances[0] >= scoreAverage * 0.8
          ? 0.2
          : difference >= 0 || scorePerformances[0] >= scoreAverage * 0.8
          ? 0.1
          : 0;

      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * moneyFactor];
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // 連続勤務補正 / 転職補正

    // 連続勤務
    if (
      game.turn !== 1 &&
      activeJob !== CARDS_JOB[0] &&
      options.jobChanged === false
    ) {
      const factor = 0.01;
      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * factor];
      scoreHealths = [...scoreHealths, scoreHealths[0] * factor];
      scorePerformances = [...scorePerformances, scorePerformances[0] * factor];
      scoreManagements = [...scoreManagements, scoreManagements[0] * factor];
      scoreNetworks = [...scoreNetworks, scoreNetworks[0] * factor];
    }

    // 転職
    if (game.turn !== 1 && options.jobChanged === true) {
      if (rand <= 25) {
        scoreHealths = [...scoreHealths, scoreHealths[0] * -0.1];
      }
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // 規定イベントの追加 / ターン補正
    if (game.turn === 1) {
      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * 0.08];
      scorePerformances = [...scorePerformances, scorePerformances[0] * 0.01];
      scoreManagements = [...scoreManagements, scoreManagements[0] * -0.03];
      scoreNetworks = [...scoreNetworks, scoreNetworks[0] * 0.05];
    } else if (game.turn === 2) {
      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * 0.13];
      scorePerformances = [...scorePerformances, scorePerformances[0] * 0.03];
      scoreManagements = [...scoreManagements, scoreManagements[0] * -0.03];
      scoreNetworks = [...scoreNetworks, scoreNetworks[0] * 0.07];
    } else if (game.turn === 3) {
      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * 0.19];
      scorePerformances = [...scorePerformances, scorePerformances[0] * 0.05];
      scoreManagements = [...scoreManagements, scoreManagements[0] * -0.02];
      scoreNetworks = [...scoreNetworks, scoreNetworks[0] * 0.05];
    } else if (game.turn === 4) {
      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * 0.26];
      scoreHealths = [...scoreHealths, scoreHealths[0] * -0.05];
      scorePerformances = [...scorePerformances, scorePerformances[0] * 0.02];
      scoreManagements = [...scoreManagements, scoreManagements[0] * 0.05];
      scoreNetworks = [...scoreNetworks, scoreNetworks[0] * 0.05];

      // events = [...events, EVENTS_INFO_KEYS.childbirthChildcare];
    } else if (game.turn === 5) {
      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.02];
      scoreHealths = [...scoreHealths, scoreHealths[0] * -0.07];
      scorePerformances = [...scorePerformances, scorePerformances[0] * -0.01];

      // events = [...events, EVENTS_INFO_KEYS.childbirthChildcare];
    } else if (game.turn === 6) {
      scoreMoneys = [...scoreMoneys, scoreMoneys[0] * -0.04];
      scoreHealths = [...scoreHealths, scoreHealths[0] * -0.1];
      scorePerformances = [...scorePerformances, scorePerformances[0] * -0.02];
      scoreManagements = [...scoreManagements, scoreManagements[0] * -0.01];
    }

    if (game.turn !== 6) {
      // 効果が継続するイベントを再登録
      if (game.cardSocialChange?.some((soc) => soc === CARDS_SOC[0])) {
        events = [...events, EVENTS_INFO_KEYS.socialMedical];
      }
      if (game.cardSocialChange?.some((soc) => soc === CARDS_SOC[1])) {
        events = [...events, EVENTS_INFO_KEYS.socialWorkReform];
      }
      if (
        activeSocialChange === CARDS_SOC[2] ||
        (game.cardSocialChange !== null &&
          game.cardSocialChange[game.cardSocialChange?.length - 1] ===
            CARDS_SOC[2] &&
          (game.turn === 2 || game.turn === 4))
      ) {
        events = [...events, EVENTS_INFO_KEYS.socialRecession];
      }
      if (game.cardSocialChange?.some((soc) => soc === CARDS_SOC[3])) {
        events = [...events, EVENTS_INFO_KEYS.socialAi];
      }
      if (game.cardSocialChange?.some((soc) => soc === CARDS_SOC[4])) {
        events = [...events, EVENTS_INFO_KEYS.socialSideJob];
      }

      // 共通イベント
      events = [...events, EVENTS_INFO_KEYS.buyHome, EVENTS_INFO_KEYS.buyCar];
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // スコア合計 / 上限・下限チェック
    const checkedScores = [
      scoreMoneys.reduce((prev, current) => prev + current, 0),
      scoreHealths.reduce((prev, current) => prev + current, 0),
      scorePerformances.reduce((prev, current) => prev + current, 0),
      scoreManagements.reduce((prev, current) => prev + current, 0),
      scoreNetworks.reduce((prev, current) => prev + current, 0),
      scoreLeisure, // It's only Not Array.
    ].map((score) => {
      // 上限・下限Check
      const limitScore = score > 100 ? 100 : score < 0 ? 0 : score;
      // 小数第二位を四捨五入
      const scoreRound = Math.round(limitScore * 10) / 10;
      return scoreRound;
    });

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // 住宅購入の評価額
    // ※最終ターン終了時に、* 0.2 した値をもらえる。
    for (const activePrivate of activePrivates) {
      if (activePrivate === CARDS_PRI[2]) {
        options.moneyBoughtHome += checkedScores[0];
      }
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // スコア確定
    const newScores =
      game.turn === 6
        ? checkedScores.map((score, index) => {
            if (index === 0) {
              // 最終ターンで、住宅購入の評価額を追加
              const addedValueScore = score + options.moneyBoughtHome * 0.2;
              const roundScore = Math.round(addedValueScore * 10) / 10;
              // 上限Check
              return roundScore > 100 ? 100 : roundScore;
            } else {
              return score;
            }
          })
        : checkedScores;

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // 目標達成チェック / 最終スコア算出
    if (game.turn === 6) {
      // 目標達成チェック
      const result = () => {
        if (game.lifeGoal === 'l-01') {
          if (newScores[0] >= 80) {
            return true;
          }
        } else if (game.lifeGoal === 'l-02') {
          if (newScores[2] >= 80) {
            return true;
          }
        } else if (game.lifeGoal === 'l-03') {
          if (newScores[4] >= 80) {
            return true;
          }
        } else if (game.lifeGoal === 'l-04') {
          if (
            (game.scores0![5] +
              game.scores1![5] +
              game.scores2![5] +
              game.scores3![5] +
              game.scores4![5] +
              game.scores5![5] +
              newScores[5]) /
              7 >=
            50
          ) {
            return true;
          }
        } else if (game.lifeGoal === 'l-05') {
          if (newScores[1] >= 80) {
            return true;
          }
        } else if (game.lifeGoal === 'l-06') {
          if (
            (game.cardJob![0] === CARDS_JOB[1] ||
              game.cardJob![0] === CARDS_JOB[2] ||
              game.cardJob![0] === CARDS_JOB[3] ||
              game.cardJob![0] === CARDS_JOB[4] ||
              game.cardJob![0] === CARDS_JOB[9]) &&
            game.cardJob![0] === game.cardJob![1] &&
            game.cardJob![1] === game.cardJob![2] &&
            game.cardJob![2] === game.cardJob![3] &&
            game.cardJob![3] === game.cardJob![4] &&
            game.cardJob![5] === CARDS_JOB[0]
          ) {
            return true;
          }
        } else if (game.lifeGoal === 'l-07') {
          if (
            getAverage5Scores(newScores) >=
            getAverage5Scores(game.scores2!) * 2
          ) {
            return true;
          }
        } else if (game.lifeGoal === 'l-08') {
          if (
            [
              ...game.events1!,
              ...game.events2!,
              ...game.events3!,
              ...game.events4!,
              ...game.events5!,
              ...game.events6!,
              ...events,
            ].some((event) => event === EVENTS_SUCCESS_KEYS.startBusiness)
          ) {
            return true;
          }
        } else if (game.lifeGoal === 'l-09') {
          if (newScores[3] >= 80) {
            return true;
          }
        } else if (game.lifeGoal === 'l-10') {
          let count = 0;
          for (const job of game.cardJob!) {
            if (
              job === CARDS_JOB[0] ||
              job === CARDS_JOB[7] ||
              job === CARDS_JOB[8]
            ) {
              count += 1;
            }
          }
          if (count >= 3) {
            return true;
          }
        } else if (game.lifeGoal === 'l-11') {
          if (options.jobChange >= 3) {
            return true;
          }
        } else if (game.lifeGoal === 'l-12') {
          let count = 0;
          const privates = [
            ...game.cardPrivate1!,
            ...game.cardPrivate2!,
            ...game.cardPrivate3!,
            ...game.cardPrivate4!,
            ...game.cardPrivate5!,
            ...game.cardPrivate6!,
          ];
          for (const pri of privates) {
            if (pri !== CARDS_PRI[0]) {
              count += 1;
            }
          }
          if (count >= 14) {
            return true;
          }
        }
        return false;
      };

      const resultGoal = result();
      const allScoresAverage =
        Math.round(
          ((getAverage5Scores(game.scores0!) +
            getAverage5Scores(game.scores1!) +
            getAverage5Scores(game.scores2!) +
            getAverage5Scores(game.scores3!) +
            getAverage5Scores(game.scores4!) +
            getAverage5Scores(game.scores5!) +
            getAverage5Scores(newScores)) /
            7) *
            10
        ) / 10;
      const newScoresAverage = getAverage5Scores(newScores);
      const addScore = resultGoal ? 20 : 0;
      // 最終スコア算出
      const resultScore =
        Math.round((allScoresAverage + newScoresAverage + addScore) * 10) / 10;

      options = { ...options, resultGoal, resultScore };
    }

    return { newScores, events, options };
  };

  return calc();
};
