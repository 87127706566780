import React from 'react';
import { Button } from '@material-ui/core';
import { COLOR_BLACK2, COLOR_GRAY, COLOR_WHITE } from '../../consts';

type Props = {
  onClick?: any;
  submit?: boolean;
  disabled?: boolean;
  border?: 'w1';
  fontSize?: number;
  height?: number;
};

const MainFreeButton: React.FC<Props> = (props) => {
  return (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: props.disabled ? COLOR_GRAY : COLOR_BLACK2,
          fontSize: props.fontSize ?? undefined,
          fontWeight: 'bold',
          color: COLOR_WHITE,
          border:
            props.border === 'w1' ? 'solid 1px ' + COLOR_WHITE : undefined,
          borderRadius: 8,
          height: props.height ? props.height + 'px' : undefined,
        }}
        fullWidth
        size="large"
        onClick={props.onClick}
        type={props.submit ? 'submit' : 'button'}
        disabled={props.disabled ?? false}
      >
        {props.children}
      </Button>
    </div>
  );
};

export default MainFreeButton;
