import React, { useEffect, useState, useContext } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import BaseContainer from '../../../components/shared/BaseContainer';
import BlackButton from '../../../components/shared/BlackButton';
import CenterDiv from '../../../components/shared/CenterDiv';
import MaxWidthAdjust from '../../../components/shared/MaxWidthAdjust';
import {
  collectionName,
  COLOR_LINK,
  PAGE_MYPAGE_GAMEHISTORY,
} from '../../../consts';
import { FirebaseContext } from '../../../contexts';
import { readMyFinishedGames } from '../../../firebase';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkToModal: {
      color: COLOR_LINK,
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
  })
);

const GameHistory: React.FC = () => {
  const { user } = useContext(FirebaseContext);
  const classes = useStyles();
  const [games, setGames] = useState<any[] | null>(null);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted && user?.uid) {
      const getData = async () => {
        await readMyFinishedGames(
          collectionName.finishedGames,
          user.uid,
          setGames
        );
      };
      getData();
    }

    const cleanup = () => {
      unmounted = true;
    };
    return cleanup;
    // eslint-disable-next-line
  }, []);

  return (
    <BaseContainer title="ゲーム履歴">
      {games &&
        games.map((game, index) => {
          const mode = game.options.mode === 's' ? 'シングル' : 'グループ';
          // Firestoreの日付データを文字列に変換
          // https://firebase.google.com/docs/reference/js/v9/firestore_.timestamp#timestamptodate
          // https://chaika.hatenablog.com/entry/2021/04/18/083000
          const createdAt = game.createdAt.toDate();
          const year = createdAt.getFullYear();
          const month = ('0' + (createdAt.getMonth() + 1)).slice(-2);
          const day = ('0' + createdAt.getDate()).slice(-2);
          const hh = ('0' + createdAt.getHours()).slice(-2);
          const mm = ('0' + createdAt.getMinutes()).slice(-2);
          const ss = ('0' + createdAt.getSeconds()).slice(-2);

          const formatTime = `${year}/${month}/${day} ${hh}:${mm}:${ss}`;

          return (
            <React.Fragment key={index}>
              <CenterDiv marginTop="min">
                <Link
                  to={`${PAGE_MYPAGE_GAMEHISTORY}/${game.id}/${game.docId}`}
                >
                  <div className={classes.linkToModal}>
                    {mode}&emsp;{formatTime}
                  </div>
                </Link>
              </CenterDiv>
            </React.Fragment>
          );
        })}
      <CenterDiv marginTop="M">
        <MaxWidthAdjust>
          <BlackButton onClick="toMyPage">マイページに戻る</BlackButton>
        </MaxWidthAdjust>
      </CenterDiv>
    </BaseContainer>
  );
};

export default GameHistory;
