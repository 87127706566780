import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { BIG_CARD_WIDTH } from '../../consts';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: BIG_CARD_WIDTH,
    },
  })
);

const MaxWidthAdjust: React.FC = (props) => {
  const classes = useStyles();

  return <div className={classes.root}>{props.children}</div>;
};

export default MaxWidthAdjust;
