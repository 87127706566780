import React from 'react';
import DescContents from './components/DescContents';

const Desc: React.FC = () => {
  return (
    <>
      <DescContents />
      <div
        style={{
          paddingTop: 100,
        }}
      ></div>
    </>
  );
};

export default Desc;
