import React from 'react';
import { Button } from '@material-ui/core';
import {
  COLOR_DARK_GRAY2,
  COLOR_LIGHT_BLACK,
  COLOR_WHITE,
} from '../../../consts';

type Props = {
  onClick?: any;
  color?: 'black' | 'gray';
  padding?: 14;
};

const TransparentButton: React.FC<Props> = (props) => {
  return (
    <div>
      <Button
        variant="outlined"
        style={{
          fontWeight: 'bold',
          color:
            props.color === 'black'
              ? COLOR_LIGHT_BLACK
              : props.color === 'gray'
              ? COLOR_DARK_GRAY2
              : COLOR_WHITE,
          borderRadius: 8,
          border:
            props.color === 'black'
              ? `1px solid ${COLOR_LIGHT_BLACK}`
              : props.color === 'gray'
              ? `1px solid ${COLOR_DARK_GRAY2}`
              : `1px solid ${COLOR_WHITE}`,
          padding: props.padding + 'px 0' ?? undefined,
        }}
        fullWidth
        size="large"
        onClick={props.onClick}
      >
        {props.children}
      </Button>
    </div>
  );
};

export default TransparentButton;
