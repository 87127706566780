import React from 'react';
import HTMLComponent from '../../HTMLComponent';

const htmlString = `
<div id="miraiz-top">
  <!--==============CSSを読み込み===============-->
  <link href="/assets/componentsHtml/MIRAIZTOP/css/reset.css" rel="stylesheet">
  <!--layout-->
  <link href="/assets/componentsHtml/MIRAIZTOP/css/layout.css" rel="stylesheet">
  <!--ui-->
  <link href="/assets/componentsHtml/MIRAIZTOP/css/ui.css" rel="stylesheet">
  <!--fadeup-->
  <link href="/assets/componentsHtml/MIRAIZTOP/css/fadeup.css" rel="stylesheet">
  <!--pagetop-->
  <link href="/assets/componentsHtml/MIRAIZTOP/css/pagetop.css" rel="stylesheet">

  <main>
    <section id="box1">
      <div class="inner">
        <h2 class="title">カードゲームで多様な人生を体感しよう</h2>
        <p class="message">
          我々は今まさに人生100年時代に突入しようとしています。<br>それに合わせて我々には、この時代を生き抜くための新たなキャリアプランが求められつつあります。<br>不確定要素の多いこの時代におけるキャリアには何が重要なのでしょうか？
        </p>
      </div>
    </section>

    <section id="box2">
      <div class="inner">
        <h2 class="title imgIcon">5種類のカードを使ったこのカードゲームでその答えを共に探していきましょう。</h2>
        <ul class="fiveCardList fadeUpTrigger">
          <li class="cardTrigger"><img src="/assets/componentsHtml/MIRAIZTOP/img/Happening.svg" alt="ハプニング"></li>
          <li class="cardTrigger"><img src="/assets/componentsHtml/MIRAIZTOP/img/Lifegoal.svg" alt="人生目標"></li>
          <li class="cardTrigger"><img src="/assets/componentsHtml/MIRAIZTOP/img/Socialchange.svg" alt="社会変化"></li>
          <li class="cardTrigger"><img src="/assets/componentsHtml/MIRAIZTOP/img/Work.svg" alt="仕事"></li>
          <li class="cardTrigger"><img src="/assets/componentsHtml/MIRAIZTOP/img/Private.svg" alt="プライベート"></li>
        </ul>
        <p class="btn fadeUpTrigger"><a href="https://miraiz.new-one.co.jp/cards" target="_blank" rel="noopener noreferrer">カード一覧はこちら</a></p>
      </div>
    </section>

    <section id="box3">
      <div class="inner">
        <h2 class="infoTitle">ゲーム説明<span class="infoTxt">カードを選択し、より高いスコアを目指すゲームです。</span></h2>


        <h3 class="heading" data-number="1">ゲームの流れ</h3>
        <div class="wrap flowanime">
          <ul class="flow waku fadeUpTrigger">
            <li class="list">
              <p class="txt">1.目標選択</p>
              <img src="/assets/componentsHtml/MIRAIZTOP/img/flow1_1.svg" alt="1.目標選択">
            </li>
            <li class="list">
              <p class="txt">2.カード選択</p>
              <img src="/assets/componentsHtml/MIRAIZTOP/img/flow1_2.svg" alt="2.カード選択">
            </li>

            <li class="list">
              <p class="txt">3.結果表示</p>
              <img src="/assets/componentsHtml/MIRAIZTOP/img/flow1_3.svg" alt="3.結果表示">
              <p class="infotxt sp">※ 2ターンに一度社会変化</p>
            </li>

            <li class="list">
              <p class="txt">4.最終結果表示</p>
              <img src="/assets/componentsHtml/MIRAIZTOP/img/flow1_4.svg" alt="4.最終結果表示">
            </li>
          </ul>

        </div>

        <h3 class="heading" data-number="2">目標選択</h3>
        <div class="wrap">
          <p>複数の目標の中で気に入ったものを選びましょう。（選んだカードが、ゲーム内でのあなたの目標になります）</p>
          <ul class="flow second fadeUpTrigger">
            <li class="">
              <img src="/assets/componentsHtml/MIRAIZTOP/img/flow1_1.svg" alt="1.目標選択">
            </li>
            <li class="margin"><img src="/assets/componentsHtml/MIRAIZTOP/img/arrow.svg" alt="arrow"></li>
            <li class="">
              <img src="/assets/componentsHtml/MIRAIZTOP/img/flow2.svg" alt="2.目標選択">
            </li>
          </ul>

        </div>

        <h3 class="heading" data-number="3">カードの種類とカード選択</h3>
        <div class="wrap">
          <p>選択できるカードの種類は2種類。仕事カード1枚、プライベートは3枚まで選択できます。</p>
          <ul class="flow tard fadeUpTrigger">
            <li class="">
              <p class="txt">仕事カード</p>
              <img src="/assets/componentsHtml/MIRAIZTOP/img/flow3_1.webp" alt="仕事カード">
            </li>
            <li class="">
              <p class="txt">プライベートカード</p>
              <img src="/assets/componentsHtml/MIRAIZTOP/img/flow3_2.webp" alt="プライベートカード">
            </li>
          </ul>
        </div>

        <h3 class="heading" data-number="4">カードの見方</h3>
        <div class="wrap">
          <p>選ぶカードによって効果が異なります。</p>
          <ul class="flow forth">
            <li class="fadeUpTrigger">
              <img class="pc" src="/assets/componentsHtml/MIRAIZTOP/img/flow4.webp" alt="仕事カード">
              <img class="sp" src="/assets/componentsHtml/MIRAIZTOP/img/flow4_sp.webp" alt="仕事カード">
            </li>
            <li class="">
              <dl class="cardTrigger">
                <dt>数字とタイトル</dt>
                <dd>カード番号とカードの名前が記されています。</dd>
                <dt>解説文</dt>
                <dd>カードの詳細が記されています。</dd>
                <dt>変化するパラーメーター</dt>
                <dd>そのカードを実行することで変化するパラメーターが何かを表します。±は状況によってプラスになることもマイナスになることもあります。</dd>
                <dt>影響するパラーメーター</dt>
                <dd>パラメーターの変化に影響を与えるパラメーターです。</dd>
                <dt>この仕事を選んだ時の自由な時間</dt>
                <dd>この仕事を選んだ時の自由な時間の量を示します。</dd>
              </dl>
            </li>
          </ul>

        </div>

        <h3 class="heading col" data-number="5">社会変化カード・ハプニングカード</h3>
        <div class="wrap">
          <p>毎ターンランダムで、ハプニングが起きます。また、2ターンに一度社会変化が起こります。<br>社会変化は、ゲームになんらかの変化をもたらします。</p>
          <ul class="flow tard fadeUpTrigger">
            <li class="">
              <img src="/assets/componentsHtml/MIRAIZTOP/img/flow5_1.webp" alt="仕事カード">
            </li>
            <li class="">
              <img src="/assets/componentsHtml/MIRAIZTOP/img/flow5_2.webp" alt="プライベートカード">
            </li>
          </ul>


        </div>

        <h3 class="heading" data-number="6">パラメーター</h3>

        <div class="wrap">
          <p>パラメーターは、カード選択などに影響されて変化します。以下の6つのスコアの合計が人生の充実度を表しています。</p>
          <ul class="flow six fadeUpTrigger">
            <li class="imgbox">
              <img class="pc" src="/assets/componentsHtml/MIRAIZTOP/img/flow6_1.webp" alt="1.パラメーター">
              <img class="sp" src="/assets/componentsHtml/MIRAIZTOP/img/flow6_1_sp.webp" alt="1.パラメーター">
            </li>
            <li class="margin"><img src="/assets/componentsHtml/MIRAIZTOP/img/arrow.svg" alt="arrow"></li>
            <li class="kakudai">
              <img src="/assets/componentsHtml/MIRAIZTOP/img/flow6_2.svg" alt="2.パラメーター">
            </li>
          </ul>

        </div>

        <h3 class="heading" data-number="7">最終スコア</h3>
        <div class="wrap">
          <p>最終スコアは以下の3つのスコアを合計したものになります。</p>
          <ul class="flow seven fadeUpTrigger">
            <li class="list">
              <p class="txt">ゲーム全体の<br>各パラメーターの平均値<span class="txts">(自由な時間を除く)</span></p>
            </li>
            <li class="list">
              <p class="txt">ゲーム終了時の<br>各パラメーターの平均値<span class="txts">(自由な時間を除く)</span></p>
            </li>

            <li class="list">
              <p class="txt">人生の目標カードに<br>書かれていることを達成<span class="txts">(+20ポイント)</span></p>
            </li>
          </ul>
        </div>

        <h3 class="heading" data-number="8">影響しあうパラメーター</h3>
        <div class="wrap mars">
          <p>パラメーターとカードが影響し合い、下記のようなことが起こり得ます。<br>影響する要素は、「影響するパラメーター」や「影響を受けるもの」という項目でカードに記載されています。</p>
          <ul class="flow eight fadeUpTrigger">
            <li>業務遂行能力のない状態でフリーランスになると人脈・評判が下がってしまう</li>
            <li>仕事にまつわる能力の高低が起業の成功や結果の大きさに影響する</li>
            <li>プライベートにかかる時間が自由な時間よりも多いと、プライベートに十分な時間が取れず、本来の効果を得ることができない。</li>
          </ul>
        </div>

        <h3 class="heading col" data-number="9">パラメーターに影響を与える要因</h3>
        <div class="wrap">
          <p>パラメーターは、カード選択以外の要因にも影響を受けます。</p>
          <ul class="flow nine fadeUpTrigger">
            <li class="">
              <img src="/assets/componentsHtml/MIRAIZTOP/img/flow9_1.webp" alt="年齢">
            </li>
            <li class="">
              <img src="/assets/componentsHtml/MIRAIZTOP/img/flow9_2.webp" alt="社会変化">
            </li>
            <li class="">
              <img src="/assets/componentsHtml/MIRAIZTOP/img/flow9_3.webp" alt="転職">
            </li>
          </ul>
        </div>

        <h3 class="heading" data-number="10">推奨されるゲームの仕方</h3>
        <div class="wrap">
          <p>複数名で、ビデオ通話などによるコミュニケーションを取りながらゲームを行うことを推奨します。<br>カードを選んだ理由や、それぞれのパラメーター変化の傾向などを共有しながら進めると、
            より楽しく学びの深いものとなるでしょう。</p>
          <div class="fadeUpTrigger"><img class="bound" src="/assets/componentsHtml/MIRAIZTOP/img/flow10.svg" alt="推奨されるゲームの仕方"></div>
        </div>

      </div>
    </section>
  </main>
</div>
`;

const MiraizTop: React.FC = () => {
  return <HTMLComponent htmlString={htmlString} />;
};

export default MiraizTop;
