import React, { useState } from 'react';
import {
  Grid,
  Modal,
  Backdrop,
  Fade,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  BIG_CARD_WIDTH,
  CARDS_JOB,
  CARDS_PRI,
  CARD_SHADOW,
  MARGIN_S,
  PATH_JOB,
  PATH_PRI,
} from '../../consts';
import TransparentButton from '../Game/components/TransparentButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 2% 60px',
    },
    cardsBlock: {
      marginTop: MARGIN_S,
    },
    cardBox: {
      '& img': {
        cursor: 'pointer',
        width: '100%',
        // border: `solid 0.1px rgba(0, 0, 0, 0.1)`, // shadowに変更
        boxShadow: CARD_SHADOW,
        [theme.breakpoints.down('xs')]: {
          borderRadius: 5,
        },
        [theme.breakpoints.up('sm')]: {
          borderRadius: 10,
        },
      },
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      '& .__paper': {
        outline: 0,
        width: '95%',
        maxWidth: BIG_CARD_WIDTH,
        maxHeight: '95%', // この設定によりoverflowが有効となる。
        overflow: 'auto',
        '& .__imgBox': {
          marginBottom: 10,
          '& .__img': {
            margin: 0,
            padding: 0,
            width: '100%',
            [theme.breakpoints.down('xs')]: {
              borderRadius: 10,
            },
            [theme.breakpoints.up('sm')]: {
              borderRadius: 15,
            },
          },
        },
        '& .__buttonsBox': {
          display: 'flex',
          '& .__button1': {
            flex: 1,
          },
        },
      },
    },
  })
);

const cardsArr = [[...CARDS_JOB], [...CARDS_PRI]];

const CardsList: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const [open, setOpen] = useState(false);
  const [card, setCard] = useState('');

  const path =
    card.split('-')[0] === 'j'
      ? PATH_JOB
      : card.split('-')[0] === 'p'
      ? PATH_PRI
      : '';

  const handleOpen = (card: string) => {
    setCard(card);
    setOpen(true);
  };

  const handleClose = () => {
    setCard('');
    setOpen(false);
  };

  return (
    <>
      {cardsArr.map((cards, index) => {
        const path = index === 0 ? PATH_JOB : index === 1 ? PATH_PRI : '';

        return (
          <div key={index} className={classes.root}>
            <Grid
              container
              className={classes.cardsBlock}
              spacing={isXsDown ? 2 : 4}
            >
              {cards.map((c) => {
                return (
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    md={2}
                    key={c}
                    className={classes.cardBox}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}${path}${c}.svg`}
                      alt={c}
                      onClick={() => handleOpen(c)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        );
      })}

      <Modal
        aria-labelledby="card-modal"
        aria-describedby="card-modal-for-expand"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="__paper">
            <div className="__imgBox">
              <img
                className="__img"
                src={`${process.env.PUBLIC_URL}${path}${card}.svg`}
                alt={card}
              />
            </div>
            <div className="__buttonsBox">
              <div className="__button1">
                <TransparentButton onClick={handleClose}>
                  閉じる
                </TransparentButton>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default CardsList;
