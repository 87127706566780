import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  CARDS_LIF_GOAL,
  COLOR_DARK_GRAY,
  COLOR_DARK_GRAY2,
  COLOR_FAILURE,
  COLOR_SUCCESS,
  COLOR_WHITE,
  MARGIN_S,
} from '../../../consts';
import { ActiveGames } from '../../../models';
import CenterDiv from '../../../components/shared/CenterDiv';
// import { readFinishedGamesAndAddFinishedScores } from '../../../firebase';

type Props = {
  game: ActiveGames;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: MARGIN_S,
      [theme.breakpoints.down('xs')]: {
        padding: '0 5% 28px',
        borderRadius: 5,
      },
      [theme.breakpoints.up('sm')]: {
        padding: '0 2% 2%',
        borderRadius: 10,
      },
    },
    paperTitle: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 20,
      '& h6': {
        fontSize: 17,
        fontWeight: 'bold',
        color: COLOR_WHITE,
        backgroundColor: COLOR_DARK_GRAY,
        padding: '4px 24px',
        borderRadius: '0 0 10px 10px',
      },
    },
    contentTitle: {
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 'bold',
      marginBottom: 10,
    },
    contentTitleValue: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 24,
    },
    contentSuccess: {
      display: 'flex',
      justifyContent: 'center',
      color: COLOR_SUCCESS,
      marginBottom: 24,
    },
    contentFailure: {
      display: 'flex',
      justifyContent: 'center',
      color: COLOR_FAILURE,
      marginBottom: 24,
    },
    contentResultScore: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: 18,
    },
    contentResultScoreSub: {
      fontSize: 13,
      color: COLOR_DARK_GRAY2,
    },
  })
);

const ResultGoal: React.FC<Props> = ({ game }) => {
  const classes = useStyles();

  // React.useEffect(() => {
  //   readFinishedGamesAndAddFinishedScores();
  // }, []);

  const goal =
    game.lifeGoal === 'l-01'
      ? CARDS_LIF_GOAL[0]
      : game.lifeGoal === 'l-02'
      ? CARDS_LIF_GOAL[1]
      : game.lifeGoal === 'l-03'
      ? CARDS_LIF_GOAL[2]
      : game.lifeGoal === 'l-04'
      ? CARDS_LIF_GOAL[3]
      : game.lifeGoal === 'l-05'
      ? CARDS_LIF_GOAL[4]
      : game.lifeGoal === 'l-06'
      ? CARDS_LIF_GOAL[5]
      : game.lifeGoal === 'l-07'
      ? CARDS_LIF_GOAL[6]
      : game.lifeGoal === 'l-08'
      ? CARDS_LIF_GOAL[7]
      : game.lifeGoal === 'l-09'
      ? CARDS_LIF_GOAL[8]
      : game.lifeGoal === 'l-10'
      ? CARDS_LIF_GOAL[9]
      : game.lifeGoal === 'l-11'
      ? CARDS_LIF_GOAL[10]
      : game.lifeGoal === 'l-12'
      ? CARDS_LIF_GOAL[11]
      : '※達成目標を表示できません。';

  return (
    <>
      <Paper elevation={3} className={classes.paper}>
        <div className={classes.paperTitle}>
          <Typography variant="h6">ゲーム結果</Typography>
        </div>
        <div>
          <Typography className={classes.contentTitle}>
            【 達成目標 】
          </Typography>
        </div>
        <div>
          <Typography className={classes.contentTitleValue}>{goal}</Typography>
        </div>
        <div>
          <Typography className={classes.contentTitle}>
            【 達成結果 】
          </Typography>
        </div>
        {game.options.resultGoal && (
          <div>
            <Typography className={classes.contentSuccess}>
              おめでとうございます！　目標を達成しました。
            </Typography>
          </div>
        )}
        {game.options.resultGoal === false && (
          <div>
            <Typography className={classes.contentFailure}>
              残念ですが、今回は目標を達成できませんでした。
              <br />
              また次回、チャレンジしてください。
            </Typography>
          </div>
        )}
        <div>
          <Typography className={classes.contentTitle}>
            【 最終スコア 】
          </Typography>
        </div>
        <div>
          <Typography className={classes.contentResultScore}>
            {game.options.resultScore}
          </Typography>
          <CenterDiv marginTop="min">
            <Typography className={classes.contentResultScoreSub}>
              スコアの平均値と、目標達成の成否をもとに、計算されます。
            </Typography>
          </CenterDiv>
        </div>
      </Paper>
    </>
  );
};

export default ResultGoal;
