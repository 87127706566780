import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CenterDiv from '../../../components/shared/CenterDiv';

type Props = {
  center?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gameDescription: {
      '& span': {
        color: 'red',
      },
    },
  })
);

const GameDescription: React.FC<Props> = ({ center, children }) => {
  const classes = useStyles();

  return (
    <CenterDiv>
      <Typography
        className={classes.gameDescription}
        style={{
          textAlign: center ? 'center' : 'justify',
        }}
      >
        {children}
      </Typography>
    </CenterDiv>
  );
};

export default GameDescription;
