import React, {
  useState,
  useCallback,
  //  useEffect
} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import CenterDiv from '../../../components/shared/CenterDiv';
import { ActiveGames } from '../../../models';
import MainSelectButton from './MainSelectButton';
import MaxWidthAdjust from '../../../components/shared/MaxWidthAdjust';
import {
  PATH_LIF,
  TURN_SCENE,
  DEFAULT_SCORE,
  PATH_HAP,
  PATH_SOC,
  CARD_SHADOW,
  BIG_CARD_WIDTH,
} from '../../../consts';
import { calcScores } from '../../../functions';
import { updateActiveGames } from '../../../firebase';
import CheckboxText from './CheckboxText';

type Props = {
  game: ActiveGames;
  gameCollectionName: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imgBox: {
      width: '100%',
      maxWidth: BIG_CARD_WIDTH,
      '& img': {
        width: '100%',
        // border: `solid 0.5px ${COLOR_GRAY}`,
        boxShadow: CARD_SHADOW,
        [theme.breakpoints.down('xs')]: {
          borderRadius: 10,
        },
        [theme.breakpoints.up('sm')]: {
          borderRadius: 15,
        },
      },
    },
  })
);

const CardCheck: React.FC<Props> = ({ game, gameCollectionName }) => {
  const classes = useStyles();

  const [checked, setChecked] = useState(false);

  const activeScoresKey = `scores${game.turn}`;
  const activeEventsKey = `events${game.turn + 1}`;
  const previousScoresKey = `scores${game.turn - 1}`;
  const sceneObj: { path: string; file: string } =
    game.turnScene === 'checkGoal'
      ? { path: PATH_LIF, file: game.lifeGoal! }
      : game.turnScene === 'checkHappening'
      ? {
          path: PATH_HAP,
          file: game.cardHappening![game.cardHappening?.length! - 1],
        }
      : game.turnScene === 'checkSocial'
      ? {
          path: PATH_SOC,
          file: game.cardSocialChange![game.cardSocialChange?.length! - 1],
        }
      : { path: '', file: '' };

  const handleClick = useCallback(
    async (event) => {
      event.preventDefault();
      if (game.turnScene === TURN_SCENE.checkGoal) {
        await updateActiveGames(gameCollectionName, game.id, {
          turn: 1,
          turnScene: TURN_SCENE.job,
          scores0: DEFAULT_SCORE,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } else if (game.turnScene === TURN_SCENE.checkHappening) {
        const { newScores, events, options } = calcScores(
          game,
          game[previousScoresKey]
        );
        const updateObj = {
          turn: game.turn === 6 ? game.turn : game.turn + 1,
          turnScene:
            game.turn === 6
              ? TURN_SCENE.result
              : game.turn === 1 || game.turn === 3 || game.turn === 5
              ? TURN_SCENE.social
              : TURN_SCENE.job,
          // game[previousScoresKey] このブラケット表記を有効にするため、src/models.ts の ActiveGames に [key: string]: any; を追記。
          [activeScoresKey]: newScores,
          [activeEventsKey]: events.length === 0 ? null : events,
          options: options,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        };
        await updateActiveGames(gameCollectionName, game.id, updateObj);
      } else if (game.turnScene === TURN_SCENE.checkSocial) {
        await updateActiveGames(gameCollectionName, game.id, {
          turnScene: TURN_SCENE.job,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } else {
        alert('Error: turnScene is incorrect.');
      }
    },
    [
      activeEventsKey,
      activeScoresKey,
      game,
      gameCollectionName,
      previousScoresKey,
    ]
  );

  // // ! ロジックテスト用
  // useEffect(() => {
  //   if (game.turn !== 0 && game.turnScene === TURN_SCENE.checkHappening) {
  //     const score = calcScores(game, game[previousScoresKey]);
  //     console.log(score.newScores);
  //     console.log(score.events);
  //     console.log(score.options);
  //   }
  //   // eslint-disable-next-line
  // }, []);
  // // ! ロジックテスト用

  return (
    <>
      <CenterDiv>
        <div className={classes.imgBox}>
          <img
            src={`${process.env.PUBLIC_URL}${sceneObj.path}${sceneObj.file}.svg`}
            alt={sceneObj.file}
          />
        </div>
      </CenterDiv>
      {game.options.mode === 's' ? (
        <CenterDiv marginTop="M">
          <MaxWidthAdjust>
            <MainSelectButton padding={14} onClick={handleClick}>
              次へ
            </MainSelectButton>
          </MaxWidthAdjust>
        </CenterDiv>
      ) : (
        <>
          <CheckboxText checked={checked} setChecked={setChecked} />
          <CenterDiv>
            <MaxWidthAdjust>
              <MainSelectButton
                padding={14}
                onClick={handleClick}
                disabled={!checked}
              >
                次へ
              </MainSelectButton>
            </MaxWidthAdjust>
          </CenterDiv>
        </>
      )}
    </>
  );
};

export default CardCheck;
