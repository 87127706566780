import React from 'react';
import { COLOR_LIGHT_BLACK } from '../../consts';
import BasicButton, { CustomProps } from './atoms/BasicButton';

type Props = CustomProps;

const BlackButton: React.FC<Props> = (props) => {
  return (
    <div>
      <BasicButton
        variant="contained"
        bgColor={COLOR_LIGHT_BLACK}
        square
        padding={14}
        {...props}
      >
        {props.children}
      </BasicButton>
    </div>
  );
};

export default BlackButton;
