import React from 'react';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import {
  BIG_CARD_WIDTH,
  CARDS_SOC,
  CARD_SHADOW,
  EVENTS_INFO_KEYS,
  MARGIN_S,
  TURN_SCENE,
} from '../../../consts';
import { updateActiveGames } from '../../../firebase';
import { ActiveGames } from '../../../models';

type Props = {
  cards: string[];
  path: string;
  file: string;
  game: ActiveGames;
  gameCollectionName: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsBlock: {
      marginTop: MARGIN_S,
    },
    cardBox: {
      '& img': {
        cursor: 'pointer',
        width: '100%',
        // border: `solid 0.1px rgba(0, 0, 0, 0.1)`, // shadowに変更
        boxShadow: CARD_SHADOW,
        [theme.breakpoints.down('xs')]: {
          borderRadius: 5,
        },
        [theme.breakpoints.up('sm')]: {
          borderRadius: 10,
        },
      },
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      '& .__paper': {
        outline: 0,
        width: '95%',
        maxWidth: BIG_CARD_WIDTH,
        maxHeight: '95%', // この設定によりoverflowが有効となる。
        overflow: 'auto',
        '& .__imgBox': {
          marginBottom: 10,
          '& .__img': {
            margin: 0,
            padding: 0,
            width: '100%',
            [theme.breakpoints.down('xs')]: {
              borderRadius: 10,
            },
            [theme.breakpoints.up('sm')]: {
              borderRadius: 15,
            },
          },
        },
        '& .__buttonsBox': {
          display: 'flex',
          '& .__button1': {
            flex: 1,
            marginRight: 5,
          },
          '& .__button2': {
            flex: 1,
            marginLeft: 5,
          },
        },
      },
    },
  })
);

const CardsBlockBack: React.FC<Props> = ({
  cards,
  path,
  file,
  game,
  gameCollectionName,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const activeEventsKey = `events${game.turn}`;

  // 配列を理論上偏りなくシャッフル
  // https://www.nxworld.net/js-array-shuffle.html
  const shuffle = ([...array]) => {
    for (let i = array.length - 1; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const makeSocialEvents = (card: string, events: string[] | null) => {
    const event =
      card === CARDS_SOC[0]
        ? EVENTS_INFO_KEYS.socialMedical
        : card === CARDS_SOC[1]
        ? EVENTS_INFO_KEYS.socialWorkReform
        : card === CARDS_SOC[2]
        ? EVENTS_INFO_KEYS.socialRecession
        : card === CARDS_SOC[3]
        ? EVENTS_INFO_KEYS.socialAi
        : card === CARDS_SOC[4]
        ? EVENTS_INFO_KEYS.socialSideJob
        : null;

    if (events !== null && event !== null) {
      return [event, ...events];
    } else if (events !== null && event === null) {
      return [...events];
    } else if (events === null && event !== null) {
      return [event];
    } else {
      return null;
    }
  };

  const handleSelect = async (card: string) => {
    const check = window.confirm(
      '選んだカードをめくりますか？　※[OK]を押すとやり直しできません。'
    );
    if (check) {
      const updateObj =
        game.turnScene === 'happening'
          ? {
              turnScene: TURN_SCENE.checkHappening,
              cardHappening: game.cardHappening
                ? [...game.cardHappening, card]
                : [card],
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            }
          : game.turnScene === 'social'
          ? {
              turnScene: TURN_SCENE.checkSocial,
              cardSocialChange: game.cardSocialChange
                ? [...game.cardSocialChange, card]
                : [card],
              [activeEventsKey]: makeSocialEvents(card, game[activeEventsKey]), // 社会変化のイベントを登録
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            }
          : {
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            };
      await updateActiveGames(gameCollectionName, game.id, updateObj);
    }
  };

  return (
    <>
      <Grid
        container
        className={classes.cardsBlock}
        justify="center"
        spacing={isXsDown ? 2 : 4}
      >
        {shuffle(cards).map((card, index) => (
          <Grid
            item
            xs={4}
            sm={3}
            md={2}
            key={index}
            className={classes.cardBox}
          >
            <img
              src={`${process.env.PUBLIC_URL}${path}${file}`}
              alt={`card-${index}`}
              onClick={() => handleSelect(card)}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default CardsBlockBack;
