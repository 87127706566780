import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Hidden, Typography } from '@material-ui/core';
import CenterDiv from '../../../components/shared/CenterDiv';
import MaxWidthAdjust from '../../../components/shared/MaxWidthAdjust';

type Props = {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isResult?: boolean;
  checked2?: boolean;
  setChecked2?: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warningText: {
      fontSize: '14px',
    },
    check: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      color: 'red',
    },
    checkResult: {
      marginTop: 12,
      marginBottom: 10,
    },
  })
);

const CheckboxText: React.FC<Props> = ({
  checked,
  setChecked,
  isResult,
  checked2,
  setChecked2,
}) => {
  const classes = useStyles();

  const handleChange = () => {
    setChecked(!checked);
  };
  const handleChange2 = () => {
    if (checked2 !== undefined && setChecked2 !== undefined) {
      setChecked2(!checked2);
    }
  };

  return (
    <CenterDiv marginTop="M">
      <MaxWidthAdjust>
        {isResult && (
          <>
            <Typography className={classes.warningText}>
              ファシリテーターとの振り返りが終わりましたら、下記項目をチェックし、「ゲームを終了する」ボタンを押してください。
            </Typography>
            <div>
              <label className={`${classes.check} ${classes.checkResult}`}>
                <input
                  onChange={handleChange2}
                  type="checkbox"
                  name="checkPersonalInfo2"
                  checked={checked2}
                />
                <span>
                  &ensp;ゲームの振り返りが終了したら
                  <Hidden smUp>
                    <br />
                    &ensp;
                  </Hidden>
                  チェックしてください。
                </span>
              </label>
            </div>
          </>
        )}
        <div>
          <label className={classes.check}>
            <input
              onChange={handleChange}
              type="checkbox"
              name="checkPersonalInfo"
              checked={checked}
            />
            <span>
              &ensp;ファシリテーターから案内があったら
              <Hidden smUp>
                <br />
                &ensp;
              </Hidden>
              チェックしてください。
            </span>
          </label>
        </div>
      </MaxWidthAdjust>
    </CenterDiv>
  );
};

export default CheckboxText;
