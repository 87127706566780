import React, { useState, useCallback, useContext } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import BaseContainer from '../../../components/shared/BaseContainer';
import BlackButton from '../../../components/shared/BlackButton';
import CenterDiv from '../../../components/shared/CenterDiv';
import MaxWidthAdjust from '../../../components/shared/MaxWidthAdjust';
import { FirebaseContext } from '../../../contexts';
import { updateNickName } from '../../../firebase';
import { COLOR_DARK_GRAY, COLOR_WHITE, VALIDATIONS } from '../../../consts';
import MainFreeButton from '../../../components/shared/MainFreeButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexDiv: {
      display: 'flex',
    },
    textBox: {
      flex: 1,
    },
    submitButton: {
      marginLeft: 7,
    },
    contentTitle: {
      marginBottom: 5,
      textAlign: 'center',
      '& span': {
        padding: '3px 12px',
        color: COLOR_WHITE,
        fontSize: 14,
        fontWeight: 'bold',
        backgroundColor: COLOR_DARK_GRAY,
        borderRadius: 9999,
      },
    },
    content: {
      textAlign: 'center',
    },
  })
);

const UserInfo: React.FC = () => {
  const { user } = useContext(FirebaseContext);
  const classes = useStyles();
  const [nickname, setNickname] = useState<string>(
    user!.displayName ? user!.displayName : ''
  );

  const nicknameFormat = {
    maxStr: VALIDATIONS.maxNickname,
  };
  const nicknameHelperText =
    nickname === ''
      ? ' '
      : nicknameFormat.maxStr < nickname!.length
      ? '24文字以内でご入力ください'
      : ' ';
  const nicknameError =
    nickname === '' ? false : nicknameFormat.maxStr < nickname!.length;
  const submitNicknameDisabled = nickname === '' || nicknameError;

  const handleSubmitNickname = useCallback(
    async (event) => {
      event.preventDefault();

      await updateNickName(nickname!);
    },
    [nickname]
  );

  return (
    <BaseContainer title="登録情報">
      <CenterDiv marginTop="M">
        <MaxWidthAdjust>
          <Typography className={classes.contentTitle}>
            <span>ニックネーム</span>
          </Typography>
          <Typography className={classes.content}>
            {user?.displayName}
          </Typography>
        </MaxWidthAdjust>
      </CenterDiv>

      <CenterDiv>
        <MaxWidthAdjust>
          <div className={classes.flexDiv}>
            <div className={classes.textBox}>
              <TextField
                // autoFocus={true}
                name="newNickname"
                type="text"
                fullWidth
                label="ニックネーム変更（24文字以内）"
                value={nickname}
                onChange={(e) => {
                  setNickname(e.target.value);
                }}
                helperText={nicknameHelperText}
                error={nicknameError}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </div>
            <div className={classes.submitButton}>
              <MainFreeButton
                onClick={handleSubmitNickname}
                disabled={submitNicknameDisabled}
                height={54}
              >
                変更
              </MainFreeButton>
            </div>
          </div>
        </MaxWidthAdjust>
      </CenterDiv>

      <CenterDiv marginTop="M">
        <MaxWidthAdjust>
          <BlackButton onClick="toMyPage">マイページに戻る</BlackButton>
        </MaxWidthAdjust>
      </CenterDiv>
    </BaseContainer>
  );
};

export default UserInfo;
