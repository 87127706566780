import { ActiveGames } from './models';

export const collectionName = {
  memos: 'memos', // sample
  activeGames: 'activeGames',
  activeGroupGames: 'activeGroupGames',
  finishedGames: 'finishedGames',
  manager: 'manager',
  gameGroups: 'gameGroups',
  finishedScores: 'finishedScores',
  userProfile: 'userProfile',
};

export const finishedScoresDocId = {
  singleScores: 'singleScores',
  groupScores: 'groupScores',
};

export const finishedScoresField = {
  countsGoal: 'countsGoal',
  scoresResult: 'scoresResult',
  scoresMoney: 'scoresMoney',
  scoresHealth: 'scoresHealth',
  scoresPerformance: 'scoresPerformance',
  scoresManagement: 'scoresManagement',
  scoresNetwork: 'scoresNetwork',
  scoresLeisure: 'scoresLeisure',
};

// 平均値
const average = (numArr: number[]) => {
  // 合計
  const sum = numArr.reduce((prev, current) => prev + current, 0);
  // 平均
  return sum / numArr.length;
};
// 標準偏差
const standardDeviation = (numArr: number[], avg: number) => {
  let n = numArr.length;
  let sum = 0;
  // let i = 0;
  for (let i: number = 0; i < n; i++) {
    sum += Math.pow(numArr[i] - avg, 2);
  }
  return Math.sqrt(sum / n);
};
// 偏差値　※配列の末尾の要素（自身のスコア）の偏差値を返す。
export const standardScore = (numArr: number[]) => {
  const avg = average(numArr);
  const sd = standardDeviation(numArr, avg);
  // 小数第二位を四捨五入
  return (
    Math.round(((10 * (numArr[numArr.length - 1] - avg)) / sd + 50) * 10) / 10
  );
};

// 順位
export const getRank = (arr: number[], numRank: number) => {
  return [...arr, numRank].sort((a, b) => b - a).indexOf(numRank) + 1;
};

export const COLOR_MAIN = '#2CBBC6';
export const COLOR_SECOND = '#FCCF00';
export const COLOR_BLACK = '#30343f';
export const COLOR_BLACK2 = '#071932';
export const COLOR_LIGHT_BLACK = '#545871';
export const COLOR_WHITE_GRAY = '#f5f5f5';
export const COLOR_LIGHT_GRAY = '#DBDBDB';
export const COLOR_GRAY = '#c9c9c9';
export const COLOR_DARK_GRAY = '#a8a8a8';
export const COLOR_DARK_GRAY2 = '#9b9b9b';
export const COLOR_WHITE = '#ffffff';
export const COLOR_GHOST_WHITE = 'ghostwhite';
export const COLOR_WARNING = 'tomato';
export const COLOR_LINK = '#247cff';
export const COLOR_SUCCESS = '#2cb73a';
export const COLOR_FAILURE = '#ce2525';
export const COLOR_HAP = '#804037';
export const COLOR_JOB = '#b7ddf7';
export const COLOR_PRI = '#77D9A8';
export const COLOR_SOC = '#990099';
export const COLOR_START = '#FCCF00';
export const COLOR_CHECK_GOAL = '#d4e02c';
export const COLOR_CHECK_HAPPENING = '#725750';
export const COLOR_CHECK_SOCIAL = '#8a47b6';
export const COLOR_RESULT = 'coral';
export const COLOR_NAVY = '#0B1931';

export const COLOR_CARDS = {
  hap: COLOR_HAP,
  job: COLOR_JOB,
  pri: COLOR_PRI,
  soc: COLOR_SOC,
};

// validations
export const VALIDATIONS = {
  maxNickname: 24,
};

export const COLOR_TURN_SCENE_LABEL = {
  start: COLOR_START,
  checkGoal: COLOR_CHECK_GOAL,
  job: COLOR_JOB,
  private: COLOR_PRI,
  happening: COLOR_HAP,
  checkHappening: COLOR_CHECK_HAPPENING,
  social: COLOR_SOC,
  checkSocial: COLOR_CHECK_SOCIAL,
  result: COLOR_RESULT,
};

export const FONT_SIZE_M = '14px';

export const BIG_CARD_WIDTH = '470px';
export const CARD_BORDER = '#dfdfdf';
export const CARD_SHADOW =
  '0 3px 3px -2px rgba(0, 0, 0, 0.2),0 3px 4px 0 rgba(0, 0, 0, 0.14),  0 1px 8px 0 rgba(0, 0, 0, 0.12)';

export const MARGIN_XS = '15px';
export const MARGIN_S = '25px';
export const MARGIN_M = '40px';
export const MARGIN_L = '60px';
export const MARGIN_XL = '80px';

export const BODY_PADDING = '0 4%';

export const PAGE_HOME = '/';
export const PAGE_REGISTER = '/register';
export const PAGE_LOGIN = '/login';
export const PAGE_PASSRESET = '/passreset';
export const PAGE_TERMS = '/terms';
export const PAGE_HELP = '/help';
export const PAGE_MYPAGE = '/mypage';
export const PAGE_MYPAGE_USERINFO = '/mypage/userinfo';
export const PAGE_MYPAGE_GAMEHISTORY = '/mypage/gamehistory';
export const PAGE_MYPAGE_GAMEHISTORY_ONE = '/mypage/gamehistory/:myId/:gameId';
export const PAGE_MYPAGE_MANAGER = '/mypage/manager';
export const PAGE_MYPAGE_MANAGER_GROUP = '/mypage/manager/group';
export const PAGE_MYPAGE_MANAGER_GROUP_ACTIVE = '/mypage/manager/group/:id';
export const PAGE_MYPAGE_MANAGER_DATAVIEW = '/mypage/manager/dataview';
export const PAGE_GAME = '/game';
export const PAGE_GAME_GROUP = '/gamegroup';
export const PAGE_CARDS_LIST = '/cardslist'; // 管理者用 全カード表示
export const PAGE_CARDS = '/cards'; // ユーザー用 一部カード非表示
export const PAGE_DESC = '/description';
export const PAGE_CANCEL = '/cancel';

export const PATH_HAP = '/assets/svg/card-hap/';
export const PATH_JOB = '/assets/svg/card-job/';
export const PATH_LIF = '/assets/svg/card-lif/';
export const PATH_LIF_MSG = '/assets/svg/card-lif-msg/';
export const PATH_PRI = '/assets/svg/card-pri/';
export const PATH_SOC = '/assets/svg/card-soc/';

export const CARDS_HAP = [
  'h-00',
  'h-01',
  'h-02',
  // Defaultの「穏やかな日常」を6枚に設定
  'h-03',
  'h-03',
  'h-03',
  'h-03',
  'h-03',
  'h-03',
];
// ゲームではなくカード一覧表示用
export const CARDS_HAP_LIST = ['h-00', 'h-01', 'h-02', 'h-03'];
export const CARDS_JOB = [
  'j-00',
  'j-01',
  'j-02',
  'j-03',
  'j-04',
  'j-05',
  'j-06',
  'j-07',
  'j-08',
  'j-09',
  'j-10',
];
export const CARDS_LIF = [
  'l-01',
  'l-02',
  'l-03',
  'l-04',
  'l-05',
  'l-06',
  'l-07',
  'l-08',
  'l-09',
  'l-10',
  'l-11',
  'l-12',
];
export const CARDS_LIF_MSG = [
  'l-01',
  'l-02',
  'l-03',
  'l-04',
  'l-05',
  'l-06',
  'l-07',
  'l-08',
  'l-09',
  'l-10',
  'l-11',
  'l-12',
];
export const CARDS_LIF_GOAL = [
  'ゲーム終了時お金80以上',
  'ゲーム終了時業務遂行能力80以上',
  'ゲーム終了時人脈・評判80以上',
  'ゲーム全体の自由な時間平均50以上',
  'ゲーム終了時健康80以上',
  '1～5ターン目まで、仕事カード①、②、③、④、⑨のうちのいずれかを選択し続け、6ターン目は仕事カード⓪を選択する',
  '60代～70代のパラメーター平均値を、20代後半のパラメーター平均値の倍にする',
  'ゲームの中で最低でも1回起業に成功する',
  'ゲーム終了時マネジメント能力80以上',
  'ゲーム中に仕事カード⓪、⑦、⑧のいずれかを合計3回以上選択する',
  '仕事カードを3回以上変更する',
  'プライベートカード（⓪以外）を14枚以上使用する',
];
export const CARDS_PRI = [
  'p-00',
  'p-01',
  'p-02',
  'p-03',
  'p-04',
  'p-05',
  'p-06',
  'p-07',
  'p-08',
  'p-09',
  'p-10',
  'p-11',
  'p-12',
  'p-13',
  'p-14',
  'p-15',
  'p-16',
  'p-17',
  'p-18',
  'p-19',
  'p-20',
  'p-21',
  'p-22',
];
export const CARDS_PRI_DECK = [
  'p-00',
  'p-00',
  'p-00',
  'p-01',
  'p-02',
  'p-03',
  'p-04',
  'p-05',
  'p-06',
  'p-07',
  'p-08',
  'p-09',
  'p-10',
  'p-11',
  'p-12',
  'p-13',
  'p-14',
  'p-15',
  'p-16',
  'p-17',
  'p-18',
  'p-19',
  'p-20',
  'p-21',
  'p-22',
];
export const CARDS_SOC = [
  's-01',
  's-02',
  's-03',
  's-04',
  's-05',
  's-06',
  's-07',
];
export const CARDS_SOC_NAME = [
  '1 医療技術の進歩',
  '2 働き方改革',
  '3 大不況',
  '4 AI技術の進歩',
  '5 副業奨励',
  '6 スキルアップ奨励',
  '7 産業構造の変化',
];
export const CARD_HAP_BACK = 'h-back.svg';
export const CARD_SOC_BACK = 's-back.svg';
export const CARD_DISABLE = '/assets/svg/card-disable.svg';
export const CARD_SELECTED = '/assets/svg/card-selected.svg';
export const CARD_SELECTED_1 = '/assets/svg/card-selected-1.svg';
export const CARD_SELECTED_2 = '/assets/svg/card-selected-2.svg';
export const CARD_BANKRUPT = '/assets/svg/card-bankrupt.svg';

export const TURN_SCENE: {
  start: 'start';
  checkGoal: 'checkGoal';
  job: 'job';
  private: 'private';
  happening: 'happening';
  checkHappening: 'checkHappening';
  social: 'social';
  checkSocial: 'checkSocial';
  result: 'result';
} = {
  start: 'start',
  checkGoal: 'checkGoal',
  job: 'job',
  private: 'private',
  happening: 'happening',
  checkHappening: 'checkHappening',
  social: 'social',
  checkSocial: 'checkSocial',
  result: 'result',
};

export const AGES = [
  '20代前半',
  '20代後半',
  '30代',
  '40代',
  '50代',
  '60〜70代',
];

export const EVENTS_SUCCESS_KEYS = {
  startBusiness: 'se01',
  investment: 'se02',
};

export const EVENTS_FAILURE_KEYS = {
  startBusiness: 'fe01',
  investment: 'fe02',
  venture: 'fe50',
  bankrupt: 'fe51',
};

export const EVENTS_INFO_KEYS = {
  // childbirthChildcare: 'ie01',
  buyHome: 'ie02',
  buyCar: 'ie03',
  socialMedical: 'ie04',
  socialWorkReform: 'ie05',
  socialRecession: 'ie06',
  socialAi: 'ie07',
  socialSideJob: 'ie08',
  prohibitedPriSideJob: 'ie09',
  prohibitedPriPartTimeJob: 'ie10',
};

export const DEFAULT_SCORE = [30, 80, 30, 30, 20, 50];
export const DEFAULT_GAME = {
  id: '',
  turn: 0,
  turnScene: TURN_SCENE.start,
  lifeGoal: '',
  scores0: null,
  scores1: null,
  scores2: null,
  scores3: null,
  scores4: null,
  scores5: null,
  scores6: null,
  cardJob: null,
  cardPrivate1: null,
  cardPrivate2: null,
  cardPrivate3: null,
  cardPrivate4: null,
  cardPrivate5: null,
  cardPrivate6: null,
  cardHappening: null,
  cardSocialChange: null,
  events1: [EVENTS_INFO_KEYS.buyHome, EVENTS_INFO_KEYS.buyCar],
  events2: null,
  events3: null,
  events4: null,
  events5: null,
  events6: null,
  events7: null,
  options: {
    mode: 's',
    jobChanged: false, // 直近の転職フラグ
    jobChange: 0, // 転職回数
    jobKeep: 1, // 同じ仕事の連続選択回数
    medicalCheckUp: 0,
    investmentLearnHigh: 0,
    investmentLearnLow: 0,
    moneyBoughtHome: 0, // 住宅購入カードを選んだターンの、money合計値。最終ターン終了時に、* 0.2 した値をもらえる。
    boughtHomes: 0, // 住宅購入カウント（最大2回の条件チェックに利用）
    bankrupt: false, // 倒産フラグ
    resultGoal: null, // 目標達成フラグ
    resultScore: 0, // 最終スコア
    groupId: '', // グループのドキュメントID
    groupSoc1: '', // グループ規定の社会変化カード1
    groupSoc2: '', // グループ規定の社会変化カード2
    groupSoc3: '', // グループ規定の社会変化カード3
    playerName: '', // プレイヤー名
  },
  createdAt: null,
  updatedAt: null,
} as ActiveGames;

export const SCORE_KEYS = [
  { en: 'money', ja: 'お金' },
  { en: 'health', ja: '健康' },
  { en: 'performance', ja: '業務遂行能力' },
  { en: 'management', ja: 'マネジメント能力' },
  { en: 'network', ja: '人脈・評判' },
  { en: 'leisure', ja: '自由な時間' },
  { en: 'average', ja: '【平均値】' },
];

export const PARAM_COLORS = {
  money: '#ecb100',
  health: '#08eb00',
  performance: '#3a13e9',
  management: '#ec79f7',
  network: '#e02d00',
  leisure: '#148f22',
  average: '#30343f',
  warning: '#ff0000',
};
