import 'date-fns';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  TextField,
  ThemeProvider,
  createMuiTheme,
  MenuItem,
  Divider,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import ja from 'date-fns/locale/ja';
import { PAGE_HOME, VALIDATIONS, COLOR_BLACK2 } from '../../consts';
import { signupWithEmailAndNickname } from '../../firebase';
import BaseContainer from '../../components/shared/BaseContainer';
import MaxWidthAdjust from '../../components/shared/MaxWidthAdjust';
import CenterDiv from '../../components/shared/CenterDiv';
import MainButton from '../../components/shared/MainButton';
import { FirebaseContext } from '../../contexts';
import Loading from '../../components/shared/Loading';
import PersonalInfoAgree from '../../components/shared/Home/PersonalInfoAgree';

// カレンダーの表示形式をカスタマイズ
class ExtendedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: Date) {
    return format(date, 'MM/dd', { locale: this.locale });
  }
  getCalendarHeaderText(date: Date) {
    return format(date, 'yyyy/MM', { locale: this.locale });
  }
}

// カレンダーのstyleを設定
const calendarTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: COLOR_BLACK2,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: COLOR_BLACK2,
      },
    },
  },
});

const careersUniversity = [
  { value: 'cu-01', label: '就職活動以前' },
  { value: 'cu-02', label: '就職活動中' },
  { value: 'cu-03', label: '就職先は決まっている' },
];
const careersSociety = [
  { value: 'cs-01', label: '社会人1年目' },
  { value: 'cs-02', label: '2年目' },
  { value: 'cs-03', label: '3年目' },
  { value: 'cs-04', label: '4年目' },
  { value: 'cs-05', label: '5年目～10年目' },
  { value: 'cs-06', label: '11年目以上' },
];
const careersOther = [{ value: 'co-01', label: 'その他' }];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    careerLabel: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 12,
      padding: '8px',
    },
    menuItem: {
      // fontSize: 14,
    },
    divider: {
      marginTop: 8,
      marginBottom: 8,
    },
  })
);

const Register: React.FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const { user } = useContext(FirebaseContext);

  const [nickname, setNickname] = useState('');
  const [birthday, setBirthday] = useState<Date | null>(null);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [rePass, setRePass] = useState('');
  const [career, setCareer] = useState('');
  const [loading, setLoading] = useState(false);
  const [agreeChecked, setAgreeChecked] = useState(false);

  // nickname validation
  const nicknameFormat = {
    maxStr: VALIDATIONS.maxNickname,
  };
  const nicknameHelperText =
    nickname === ''
      ? ' '
      : nicknameFormat.maxStr < nickname.length
      ? '24文字以内でご入力ください'
      : ' ';
  const nicknameError =
    nickname === '' ? false : nicknameFormat.maxStr < nickname.length;

  // birthday validation
  const birthdayHelperText =
    birthday === null
      ? ' '
      : Number.isNaN(birthday.getTime())
      ? '有効な日付をご入力ください'
      : birthday > new Date()
      ? '過去日をご入力ください'
      : birthday < new Date('1900/1/1')
      ? '1900/01/01 以降の日付をご入力ください'
      : ' ';
  const birthdayError =
    birthday === null
      ? false
      : Number.isNaN(birthday.getTime()) ||
        birthday > new Date() ||
        birthday < new Date('1900/1/1');

  // email validation
  const emailFormat = {
    maxStr: 256,
    regExp: /^[\w.!#$%&'*+/=?^`{|}~-]+@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
  };
  const emailHelperText =
    email === ''
      ? ' '
      : email.length > emailFormat.maxStr
      ? '256文字以内でご入力ください'
      : email.match(emailFormat.regExp) === null
      ? '有効なメールアドレスをご入力ください'
      : ' ';
  const emailError =
    email === ''
      ? false
      : email.length > emailFormat.maxStr ||
        email.match(emailFormat.regExp) === null;

  // password validation
  const passFormat = {
    // 6文字以上というのがFirebaseの仕様
    // 上限64文字は独自に設定
    minStr: 6,
    maxStr: 64,
    // [!-~]  => すべての半角文字 ※半角スペースは除外
    // https://www-creators.com/archives/5187
    regExp1: /^[!-~]+$/,
    // https://stackoverflow.com/questions/14850553/javascript-regex-for-password-containing-at-least-8-characters-1-number-1-uppe
    regExp2: /^(?=.*\d)(?=.*[a-zA-Z]).+$/,
  };
  const passHelperText =
    pass === ''
      ? ' '
      : pass.match(passFormat.regExp1) === null
      ? '不正な文字が存在します（全角文字や空白）'
      : pass.match(passFormat.regExp2) === null
      ? '半角の英字・数字を混在させてください'
      : pass.length < passFormat.minStr || passFormat.maxStr < pass.length
      ? '6〜64文字でご入力ください'
      : ' ';
  const passError =
    pass === ''
      ? false
      : pass.match(passFormat.regExp1) === null ||
        pass.match(passFormat.regExp2) === null ||
        pass.length < passFormat.minStr ||
        passFormat.maxStr < pass.length;

  // password(to confirm) validation
  const rePassHelperText =
    rePass === '' ? ' ' : rePass !== pass ? 'パスワードが不一致です' : ' ';
  const rePassError = rePass === '' ? false : pass !== rePass;

  // submit button disabled
  const submitBtnDisabled =
    nickname === '' ||
    birthday === null ||
    email === '' ||
    pass === '' ||
    rePass === '' ||
    career === '' ||
    nicknameError ||
    birthdayError ||
    emailError ||
    passError ||
    !agreeChecked ||
    rePassError;

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const { email, password, nickname, birthday, career } =
        event.target.elements;
      await signupWithEmailAndNickname(
        email.value,
        password.value,
        nickname.value,
        birthday.value,
        career.value,
        setLoading
      );
    },
    // eslint-disable-next-line
    [history]
  );

  useEffect(() => {
    user && history.push(PAGE_HOME);
  }, [history, user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <BaseContainer title="新規登録" marginBottom="L">
      <form onSubmit={handleSubmit}>
        <CenterDiv>
          <MaxWidthAdjust>
            <TextField
              autoFocus={true}
              name="nickname"
              type="text"
              fullWidth
              label="ニックネーム（24文字以内）"
              value={nickname}
              onChange={(e) => {
                setNickname(e.target.value);
              }}
              helperText={nicknameHelperText}
              error={nicknameError}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </MaxWidthAdjust>
        </CenterDiv>

        <CenterDiv>
          <MaxWidthAdjust>
            <MuiPickersUtilsProvider utils={ExtendedUtils} locale={ja}>
              <ThemeProvider theme={calendarTheme}>
                <KeyboardDatePicker
                  // autoOk
                  name="birthday"
                  fullWidth
                  disableFuture
                  openTo="year"
                  format="yyyy/MM/dd"
                  label="生年月日（例: 1990/01/01）"
                  views={['year', 'month', 'date']}
                  value={birthday}
                  onChange={(date) => setBirthday(date)}
                  helperText={birthdayHelperText}
                  InputLabelProps={{ shrink: true }}
                  inputVariant="outlined"
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </MaxWidthAdjust>
        </CenterDiv>

        <CenterDiv>
          <MaxWidthAdjust>
            <TextField
              name="email"
              type="email"
              fullWidth
              label="メールアドレス"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              helperText={emailHelperText}
              error={emailError}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </MaxWidthAdjust>
        </CenterDiv>

        <CenterDiv>
          <MaxWidthAdjust>
            <TextField
              name="password"
              type="password"
              fullWidth
              label="パスワード（半角・英数字混在・6〜64文字）"
              value={pass}
              onChange={(e) => {
                setPass(e.target.value);
              }}
              helperText={passHelperText}
              error={passError}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </MaxWidthAdjust>
        </CenterDiv>

        <CenterDiv>
          <MaxWidthAdjust>
            <TextField
              name="rePassword"
              type="password"
              fullWidth
              label="パスワード（再入力）"
              value={rePass}
              onChange={(e) => {
                setRePass(e.target.value);
              }}
              helperText={rePassHelperText}
              error={rePassError}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </MaxWidthAdjust>
        </CenterDiv>

        <CenterDiv>
          <MaxWidthAdjust>
            <TextField
              id="select-career"
              select
              name="career"
              fullWidth
              label="職歴"
              value={career}
              onChange={(e) => {
                setCareer(e.target.value);
              }}
              helperText=" "
              error={rePassError}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            >
              <div className={classes.careerLabel}>大学・大学院生</div>
              {careersUniversity.map((option) => (
                <MenuItem
                  value={option.value}
                  className={classes.menuItem}
                  key={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
              <Divider className={classes.divider} />
              <div className={classes.careerLabel}>社会人</div>
              {careersSociety.map((option) => (
                <MenuItem
                  value={option.value}
                  className={classes.menuItem}
                  key={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
              <Divider className={classes.divider} />
              <div className={classes.careerLabel}>上記以外</div>
              {careersOther.map((option) => (
                <MenuItem
                  value={option.value}
                  className={classes.menuItem}
                  key={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </MaxWidthAdjust>
        </CenterDiv>

        <CenterDiv>
          <MaxWidthAdjust>
            <PersonalInfoAgree
              checked={agreeChecked}
              setChecked={setAgreeChecked}
            />
          </MaxWidthAdjust>
        </CenterDiv>

        <CenterDiv marginTop="M">
          <MaxWidthAdjust>
            <MainButton
              onClick="none"
              submit={true}
              disabled={submitBtnDisabled}
            >
              新規登録
            </MainButton>
          </MaxWidthAdjust>
        </CenterDiv>
      </form>
    </BaseContainer>
  );
};

export default withRouter(Register);
