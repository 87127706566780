import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  COLOR_BLACK,
  COLOR_DARK_GRAY,
  COLOR_FAILURE,
  COLOR_SUCCESS,
  COLOR_WHITE,
  MARGIN_S,
} from '../../../consts';
import { ActiveGames } from '../../../models';

type Props = {
  game: ActiveGames;
  infoLabel: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: MARGIN_S,
      [theme.breakpoints.down('xs')]: {
        padding: '0 5% 28px',
        borderRadius: 5,
      },
      [theme.breakpoints.up('sm')]: {
        padding: '0 2% 2%',
        borderRadius: 10,
      },
    },
    paperTitle: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 20,
      '& h6': {
        fontSize: 17,
        fontWeight: 'bold',
        color: COLOR_WHITE,
        backgroundColor: COLOR_DARK_GRAY,
        padding: '4px 24px',
        borderRadius: '0 0 10px 10px',
      },
    },
    infoWrap: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'justify',
    },
    infoList: {
      margin: 0,
      paddingLeft: 20,
      '& li': {
        fontSize: 16,
        '& span': {
          fontWeight: 'bold',
        },
      },
    },
  })
);

export const EVENTS_MSG: {
  [key: string]: string | Array<string | JSX.Element>;
} = {
  // success
  se01: ['前回のターンで', <span key="span1">起業</span>, 'に成功しました。'],
  se02: ['前回のターンで', <span key="span1">投資</span>, 'に成功しました。'],
  // failure
  fe01: ['前回のターンで', <span key="span1">起業</span>, 'に失敗しました。'],
  fe02: ['前回のターンで', <span key="span1">投資</span>, 'に失敗しました。'],
  fe50: [
    '前回のターンで',
    <span key="span1">ベンチャー企業</span>,
    'の事業が失敗しました。',
  ],
  fe51: [
    '前回のターンで',
    <span key="span1">倒産</span>,
    'により仕事を失いました。転職が必要です。',
  ],
  // info
  // ie01: [
  //   '5ターン目以降、プライベート',
  //   <span key="span1">「出産・育児」</span>,
  //   'は選択できません。',
  // ],
  ie02: [
    'プライベート',
    <span key="span1">「住宅購入」</span>,
    'は、お金が60以上の時に選択できます（最大2回まで）。',
  ],
  ie03: [
    'プライベート',
    <span key="span1">「自動車購入」</span>,
    'は、お金が50以上の時に選択できます。',
  ],
  ie04: [
    '社会変化',
    <span key="span1">「医療技術の進歩」</span>,
    'により、ハプニングカードにおける',
    <span key="span2">「穏やかな日常」</span>,
    'の枚数が増加します。',
  ],
  ie05: [
    '社会変化',
    <span key="span1">「働き方改革」</span>,
    'により、自由な時間が増加します。',
  ],
  ie06: [
    '社会変化',
    <span key="span1">「大不況」</span>,
    'により、転職が制限されます。ただしパラメーターが基準値を満たせば、影響を受けません。',
  ],
  ie07: [
    '社会変化',
    <span key="span1">「AI技術の進歩」</span>,
    'により、選べる仕事が制限されます。ただしパラメーターが基準値を満たせば、影響を受けません。',
  ],
  ie08: [
    '社会変化',
    <span key="span1">「副業奨励」</span>,
    'により、プライベート',
    <span key="span2">「副業」</span>,
    'によるお金の増加率がアップします。',
  ],
  ie09: [
    '選んだ仕事の影響により、プライベート',
    <span key="span1">「副業」</span>,
    'は選択できません。',
  ],
  ie10: [
    '選んだ仕事の影響により、プライベート',
    <span key="span1">「パート・アルバイト」</span>,
    'は選択できません。',
  ],
};

const Information: React.FC<Props> = ({ game, infoLabel }) => {
  const classes = useStyles();

  return (
    <>
      <Paper elevation={3} className={classes.paper}>
        <div className={classes.paperTitle}>
          <Typography variant="h6">インフォメーション</Typography>
        </div>
        <div className={classes.infoWrap}>
          <ul className={classes.infoList}>
            {game[infoLabel].map((key: string, index: number) => {
              return (
                <li
                  key={index}
                  style={{
                    color:
                      key.slice(0, 1) === 's'
                        ? COLOR_SUCCESS
                        : key.slice(0, 1) === 'f'
                        ? COLOR_FAILURE
                        : COLOR_BLACK,
                    marginBottom: 7,
                  }}
                >
                  {EVENTS_MSG[key]}
                </li>
              );
            })}
          </ul>
        </div>
      </Paper>
    </>
  );
};

export default Information;
