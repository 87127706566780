import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, Typography } from '@material-ui/core';
import BaseContainer from '../../components/shared/BaseContainer';
import CenterDiv from '../../components/shared/CenterDiv';
import MaxWidthAdjust from '../../components/shared/MaxWidthAdjust';
import Loading from '../../components/shared/Loading';
import { FirebaseContext } from '../../contexts';
import { PAGE_HOME } from '../../consts';
import MainButton from '../../components/shared/MainButton';
import { sendPasswordReset } from '../../firebase';

const PasswordReset: React.FC = () => {
  const { user } = useContext(FirebaseContext);
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  // email validation
  const emailFormat = {
    maxStr: 256,
    regExp: /^[\w.!#$%&'*+/=?^`{|}~-]+@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
  };
  const emailHelperText =
    email === ''
      ? ' '
      : email.length > emailFormat.maxStr
      ? '256文字以内でご入力ください'
      : email.match(emailFormat.regExp) === null
      ? '有効なメールアドレスをご入力ください'
      : ' ';
  const emailError =
    email === ''
      ? false
      : email.length > emailFormat.maxStr ||
        email.match(emailFormat.regExp) === null;

  const submitBtnDisabled = email === '' || emailError;

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const { email } = event.target.elements;
      await sendPasswordReset(email.value, setLoading, setEmail);
    },
    // eslint-disable-next-line
    [history]
  );

  useEffect(() => {
    user && history.push(PAGE_HOME);
  }, [history, user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <BaseContainer title="パスワード再設定">
      <CenterDiv>
        <MaxWidthAdjust>
          <div>
            <Typography>
              登録済みのメールアドレスを入力して「送信」ボタンを押してください。
              <br />
              届いたメールの本文にあるリンクからパスワードを再設定できます。
            </Typography>
          </div>
        </MaxWidthAdjust>
      </CenterDiv>

      <form onSubmit={handleSubmit}>
        <CenterDiv marginTop="L">
          <MaxWidthAdjust>
            <TextField
              name="email"
              type="email"
              fullWidth
              label="メールアドレス"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              helperText={emailHelperText}
              error={emailError}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </MaxWidthAdjust>
        </CenterDiv>

        <CenterDiv marginTop="M">
          <MaxWidthAdjust>
            <MainButton
              onClick="none"
              submit={true}
              disabled={submitBtnDisabled}
            >
              送信
            </MainButton>
          </MaxWidthAdjust>
        </CenterDiv>
      </form>
    </BaseContainer>
  );
};

export default PasswordReset;
