import React from 'react';
import dompurify from 'dompurify';

const sanitizer = dompurify.sanitize;

const HTMLComponent: React.FC<{ htmlString: string }> = ({ htmlString }) => {
  /**
   * ! スクリプトタグを含んだHTMLを埋め込む場合は、コメントアウトのとおり実装する。
   * 参照
   * https://zenn.dev/akira_miyake/articles/0f5a00b035f9fe2d93e2
   */
  // ========================================
  // const divRef = useRef<HTMLDivElement>(null);

  // useLayoutEffect(() => {
  //   if (!divRef.current) {
  //     return;
  //   }

  //   const fragment = document
  //     .createRange()
  //     .createContextualFragment(htmlString);

  //   divRef.current.appendChild(fragment);
  // }, [htmlString]);

  // return <div ref={divRef} />;
  // ========================================

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: sanitizer(htmlString, {
          // https://github.com/cure53/DOMPurify/tree/main/demos#what-is-this
          ADD_TAGS: ['link'], // 許可するタグを追加
          ADD_ATTR: ['target', 'rel'], // 許可する属性を追加
        }),
      }}
    />
  );
};

export default HTMLComponent;
