import React, { useContext } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuButton from './MenuButton';
import MenuButtonLogout from './MenuButtonLogout';
import { FirebaseContext } from '../../contexts';
import {
  COLOR_WHITE,
  PAGE_CARDS,
  PAGE_CARDS_LIST,
  PAGE_DESC,
  PAGE_GAME,
  PAGE_GAME_GROUP,
  PAGE_HOME,
} from '../../consts';
import ActiveGameMenu from './Header/ActiveGameMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: COLOR_WHITE,
    },
    toolBar: {
      [theme.breakpoints.down('xs')]: {
        paddingRight: 3,
        paddingLeft: 8,
      },
      [theme.breakpoints.up('sm')]: {
        paddingRight: 13,
        paddingLeft: 20,
      },
      alignItems: 'center',
      '& .__title': {
        flexGrow: 1,
        height: 32,
        lineHeight: '32px',
        fontSize: 20,
        '& img': {
          verticalAlign: 'middle',
          cursor: 'pointer',
          [theme.breakpoints.down('xs')]: {
            width: '100px',
          },
          [theme.breakpoints.up('sm')]: {
            width: '140px',
          },
        },
      },
    },
    headerSpace: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: 57,
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: 65,
      },
    },
  })
);

const Header = () => {
  const { user } = useContext(FirebaseContext);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  if (
    location.pathname === PAGE_GAME ||
    location.pathname === PAGE_GAME_GROUP ||
    location.pathname === PAGE_CARDS_LIST ||
    location.pathname === PAGE_CARDS ||
    location.pathname === PAGE_DESC
  ) {
    return (
      <>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Typography variant="h6" className="__title">
              <img
                src={`${process.env.PUBLIC_URL}/assets/svg/miraiz-logo.svg`}
                alt="logo"
                // style={{ cursor: 'default' }}
                onClick={() => history.push(PAGE_HOME)}
              />
            </Typography>

            <ActiveGameMenu />
          </Toolbar>
        </AppBar>
        <div className={classes.headerSpace}></div>
      </>
    );
  }

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Typography variant="h6" className="__title">
            <img
              src={`${process.env.PUBLIC_URL}/assets/svg/miraiz-logo.svg`}
              alt="logo"
              onClick={() => history.push(PAGE_HOME)}
            />
          </Typography>

          <MenuButton onClick="toHome" fontSize={13}>
            ホーム
          </MenuButton>
          {/* ログアウト時 */}
          {!user && (
            <>
              <MenuButton onClick="toRegister" marginRight="5px" fontSize={13}>
                新規登録
              </MenuButton>
              <MenuButton onClick="toLogin" fontSize={13}>
                ログイン
              </MenuButton>
            </>
          )}
          {/* ログイン時 */}
          {user && user?.emailVerified && location.pathname === PAGE_HOME && (
            <MenuButton onClick="toMyPage" marginRight="8px" fontSize={13}>
              マイページ
            </MenuButton>
          )}
          {user && location.pathname === PAGE_HOME && <MenuButtonLogout />}
        </Toolbar>
      </AppBar>
      <div className={classes.headerSpace}></div>
    </>
  );
};

export default Header;
