import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import Fade from '@material-ui/core/Fade';

interface Props {
  children: React.ReactElement;
  anchorId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: 20,
      right: 20,
    },
    fab: {
      width: 80,
      height: 80,
    },
    buttonImg: {
      width: '100%',
      height: '100%',
    },
  })
);

function ScrollTop(props: Props) {
  const { children, anchorId } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 300,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector(`#${anchorId}`);

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Fade in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Fade>
  );
}

export default function BackToTop(props: { anchorId: string }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <ScrollTop anchorId={props.anchorId}>
        <Fab aria-label="scroll back to top" className={classes.fab}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/componentsHtml/MIRAIZTOP/img/pagetop.svg`}
            alt="ページトップ"
            title="ページトップ"
            className={classes.buttonImg}
          />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}
