import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';
import { FirebaseProvider } from './firebase';
import Header from './components/shared/Header';
import Home from './pages/Home';
import Footer from './components/shared/Footer';
import * as consts from './consts';
import Terms from './pages/Terms/Terms';
import Help from './pages/Help/Help';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import Register from './pages/Register/Register';
import Login from './pages/Login/Login';
import MyPage from './pages/MyPage/MyPage';
import GameHistory from './pages/MyPage/GameHistory/GameHistory';
import UserInfo from './pages/MyPage/UserInfo/UserInfo';
import Manager from './pages/MyPage/Manager/Manager';
import Group from './pages/MyPage/Manager/Group/Group';
// import DataView from './pages/MyPage/Manager/DataView/DataView';
import AuthRouter from './components/shared/AuthRouter';
import Game from './pages/Game/Game';
import GameGroup from './pages/Game/GameGroup';
import CardsList from './pages/CardsList/CardsList';
import GroupActive from './pages/MyPage/Manager/Group/GroupActive/GroupActive';
import GameHistoryOne from './pages/MyPage/GameHistory/GameHistoryOne/GameHistoryOne';
import Cancel from './pages/Cancel/Cancel';
import Cards from './pages/Cards/Cards';
import Desc from './pages/Desc/Desc';

// レスポンシブフォントサイズ
// https://material-ui.com/ja/customization/typography/#responsive-font-sizes
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const App: React.FC = () => {
  return (
    <FirebaseProvider>
      <Router>
        <ThemeProvider theme={theme}>
          <Header />
          <Switch>
            <Route exact path={consts.PAGE_HOME} component={Home} />
            <Route exact path={consts.PAGE_TERMS} component={Terms} />
            <Route exact path={consts.PAGE_HELP} component={Help} />
            <Route exact path={consts.PAGE_CARDS} component={Cards} />
            <Route exact path={consts.PAGE_DESC} component={Desc} />

            {/* Not Auth */}
            {/* コンポーネントごとに、useEffectでログイン時のアクセスを制御。 */}
            <Route exact path={consts.PAGE_REGISTER} component={Register} />
            <Route exact path={consts.PAGE_LOGIN} component={Login} />
            <Route
              exact
              path={consts.PAGE_PASSRESET}
              component={PasswordReset}
            />

            {/* Auth */}
            {/* 下記 AuthRouter に含むと匿名アクセスがプレイできないので、useEffectで個別に設定 */}
            <Route exact path={consts.PAGE_GAME} component={Game} />
            <Route exact path={consts.PAGE_GAME_GROUP} component={GameGroup} />

            {/* Auth */}
            {/* Eメール未認証 or 匿名 のアクセスを抑止 */}
            <AuthRouter>
              <Route exact path={consts.PAGE_MYPAGE} component={MyPage} />
              <Route
                exact
                path={consts.PAGE_MYPAGE_USERINFO}
                component={UserInfo}
              />
              <Route
                exact
                path={consts.PAGE_MYPAGE_GAMEHISTORY}
                component={GameHistory}
              />
              <Route
                exact
                path={consts.PAGE_MYPAGE_GAMEHISTORY_ONE}
                component={GameHistoryOne}
              />
              <Route
                exact
                path={consts.PAGE_MYPAGE_MANAGER}
                component={Manager}
              />
              <Route
                exact
                path={consts.PAGE_MYPAGE_MANAGER_GROUP}
                component={Group}
              />
              <Route
                exact
                path={consts.PAGE_MYPAGE_MANAGER_GROUP_ACTIVE}
                component={GroupActive}
              />
              {/* <Route
                exact
                path={consts.PAGE_MYPAGE_MANAGER_DATAVIEW}
                component={DataView}
              /> */}
              <Route exact path={consts.PAGE_CANCEL} component={Cancel} />
              <Route
                exact
                path={consts.PAGE_CARDS_LIST}
                component={CardsList}
              />
            </AuthRouter>

            {/* Not Found */}
            {/* <AuthRouter>の if else に引っかかって処理されるので、下記は不要（codeの上から処理されるため）。 */}
            {/* <Route component={() => <Redirect to="/" />} /> */}
          </Switch>
          <Footer />
        </ThemeProvider>
      </Router>
    </FirebaseProvider>
  );
};

export default App;
