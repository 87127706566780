import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FirebaseContext } from '../../contexts';
import { PAGE_CANCEL, PAGE_HOME, PAGE_LOGIN } from '../../consts';

const AuthRouter: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(FirebaseContext);

  useEffect(() => {
    const checkAuth = () => {
      if (user?.email && user.emailVerified) {
        setLoading(false);
      } else if (location.pathname === PAGE_CANCEL) {
        history.push(PAGE_LOGIN);
      } else {
        history.push(PAGE_HOME);
      }
    };
    checkAuth();
  }, [history, location.pathname, user]);

  return <>{!loading && children}</>;
};

export default AuthRouter;
