import React, { useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import WarningIcon from '@material-ui/icons/Warning';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { FirebaseContext } from '../../../contexts';
import {
  collectionName,
  COLOR_BLACK,
  COLOR_LIGHT_BLACK,
  COLOR_WARNING,
  PAGE_CARDS,
  PAGE_DESC,
  PAGE_GAME,
  PAGE_GAME_GROUP,
  PAGE_HOME,
} from '../../../consts';
import { deleteActiveGame } from '../../../firebase';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gameIcon: {
      fontSize: 30,
      color: COLOR_LIGHT_BLACK,
    },
    menu: {
      color: COLOR_BLACK,
      '& a': {
        color: COLOR_BLACK,
      },
    },
    warning: {
      color: COLOR_WARNING,
    },
    warningIcon: {
      marginRight: 5,
      fontSize: 22,
    },
  })
);

const ActiveGameMenu: React.FC = () => {
  const { user } = useContext(FirebaseContext);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const gameCollectionName =
    location.pathname === PAGE_GAME_GROUP
      ? collectionName.activeGroupGames
      : location.pathname === PAGE_GAME
      ? collectionName.activeGames
      : '';

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleClickToHome = () => {
    history.push(PAGE_HOME);
  };

  const handleClickDelete = () => {
    const check = window.confirm(
      'このゲームを削除して終了しますか？　※[OK]を押すとやり直しできません。'
    );
    if (check) {
      const deleteGame = async () => {
        await deleteActiveGame(gameCollectionName, user!.uid);
        history.go(0); // ページをreload
      };
      deleteGame();
    }
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      {location.pathname === PAGE_GAME && (
        <>
          <IconButton
            aria-label="single-menu"
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <PersonIcon className={classes.gameIcon} />
          </IconButton>
        </>
      )}
      {location.pathname === PAGE_GAME_GROUP && (
        <IconButton
          aria-label="group-menu"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <GroupIcon className={classes.gameIcon} />
        </IconButton>
      )}

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              // ～を含むかのチェック
              // https://qiita.com/kazu56/items/557740f398e82fc881df
              // https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/String/match
              transformOrigin: placement.match(/bottom/)
                ? 'center top'
                : 'center bottom',
            }}
          >
            <Paper elevation={3}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  className={classes.menu}
                >
                  <Link
                    to={PAGE_CARDS}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MenuItem onClick={handleClose}>カード一覧</MenuItem>
                  </Link>
                  <Link
                    to={PAGE_DESC}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MenuItem onClick={handleClose}>ゲーム説明</MenuItem>
                  </Link>
                  <MenuItem onClick={handleClickToHome}>
                    中断してホームに戻る
                  </MenuItem>
                  <MenuItem
                    onClick={handleClickDelete}
                    className={classes.warning}
                  >
                    <WarningIcon className={classes.warningIcon} />
                    削除して終了する
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default ActiveGameMenu;
