import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SortIcon from '@material-ui/icons/Sort';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

type Props = {
  sortNum: number;
  setSortNum: React.Dispatch<React.SetStateAction<number>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
      [theme.breakpoints.down('xs')]: {
        margin: '5px 15px',
      },
      [theme.breakpoints.up('sm')]: {
        margin: 15,
      },
    },
    icon: {
      fontSize: 18,
      marginRight: 5,
      // padding: 0,
      verticalAlign: 'text-bottom',
    },
    menu: {
      fontSize: 14,
    },
  })
);

const SortList: React.FC<Props> = ({ sortNum, setSortNum }) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSortNum(event.target.value as number);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <FormControl className={classes.formControl}>
        <Select
          labelId="format-label"
          id="format-id"
          value={sortNum}
          onChange={handleChange}
          label="Format"
          className={classes.menu}
        >
          <MenuItem value={2} className={classes.menu}>
            <SortIcon className={classes.icon} />
            名前
          </MenuItem>
          <MenuItem value={0} className={classes.menu}>
            <SortIcon className={classes.icon} />
            平均値
          </MenuItem>
          <MenuItem value={1} className={classes.menu}>
            <SortIcon className={classes.icon} />
            最終スコア
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default SortList;
