import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import BaseContainer from '../../../components/shared/BaseContainer';
import BlackButton from '../../../components/shared/BlackButton';
import CenterDiv from '../../../components/shared/CenterDiv';
import MaxWidthAdjust from '../../../components/shared/MaxWidthAdjust';
import {
  collectionName,
  COLOR_LINK,
  PAGE_MYPAGE,
  // PAGE_MYPAGE_MANAGER_DATAVIEW,
  PAGE_MYPAGE_MANAGER_GROUP,
} from '../../../consts';
import { FirebaseContext } from '../../../contexts';
import { db } from '../../../firebase';
import Loading from '../../../components/shared/Loading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: COLOR_LINK,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

const Manager: React.FC = () => {
  const { user } = useContext(FirebaseContext);
  const classes = useStyles();
  const history = useHistory();

  const [isManager, setIsManager] = useState<boolean>();

  useEffect(() => {
    if (user?.email && user.emailVerified) {
      db.collection(collectionName.manager)
        .where('email', '==', user.email)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length) {
            setIsManager(true);
          } else {
            setIsManager(false);
          }
        })
        .catch((error) => {
          alert(`Error getting documents: ${error}`);
        });
    }
    if (isManager === false) {
      history.push(PAGE_MYPAGE);
    }
  }, [history, isManager, user]);

  return (
    <>
      {isManager && (
        <BaseContainer title="管理者">
          <CenterDiv>
            <Link to={PAGE_MYPAGE_MANAGER_GROUP}>
              <Typography className={classes.link}>グループ管理</Typography>
            </Link>
          </CenterDiv>
          {/* <CenterDiv>
            <Link to={PAGE_MYPAGE_MANAGER_DATAVIEW}>
              <Typography className={classes.link}>データ閲覧</Typography>
            </Link>
          </CenterDiv> */}

          <CenterDiv marginTop="M">
            <MaxWidthAdjust>
              <BlackButton onClick="toMyPage">マイページに戻る</BlackButton>
            </MaxWidthAdjust>
          </CenterDiv>
        </BaseContainer>
      )}

      {!isManager && <Loading />}
    </>
  );
};

export default Manager;
