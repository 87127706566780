import React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  Legend,
} from 'recharts';
import { ActiveGames } from '../../../../models';
import { AGES, PARAM_COLORS, SCORE_KEYS } from '../../../../consts';
import { getAverage5Scores } from '../../../../functions';

type Props = {
  game: ActiveGames;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartWrapper: {
      fontSize: '.85rem',
    },
  })
);

const getColor = (key: string) => {
  switch (key) {
    case SCORE_KEYS[0].en:
      return PARAM_COLORS.money;
    case SCORE_KEYS[1].en:
      return PARAM_COLORS.health;
    case SCORE_KEYS[2].en:
      return PARAM_COLORS.performance;
    case SCORE_KEYS[3].en:
      return PARAM_COLORS.management;
    case SCORE_KEYS[4].en:
      return PARAM_COLORS.network;
    case SCORE_KEYS[5].en:
      return PARAM_COLORS.leisure;
    case SCORE_KEYS[6].en:
      return PARAM_COLORS.average;
    default:
      return PARAM_COLORS.warning;
  }
};

const ColorsLineChart: React.FC<Props> = ({ game }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const graphData = [
    {
      age: 'スタート',
      money: game?.scores0 ? game.scores0[0] : undefined,
      health: game?.scores0 ? game.scores0[1] : undefined,
      performance: game?.scores0 ? game.scores0[2] : undefined,
      management: game?.scores0 ? game.scores0[3] : undefined,
      network: game?.scores0 ? game.scores0[4] : undefined,
      leisure: game?.scores0 ? game.scores0[5] : undefined,
      average: game?.scores0 ? getAverage5Scores(game.scores0) : undefined,
    },
    {
      age: AGES[0],
      money: game?.scores1 ? game.scores1[0] : undefined,
      health: game?.scores1 ? game.scores1[1] : undefined,
      performance: game?.scores1 ? game.scores1[2] : undefined,
      management: game?.scores1 ? game.scores1[3] : undefined,
      network: game?.scores1 ? game.scores1[4] : undefined,
      leisure: game?.scores1 ? game.scores1[5] : undefined,
      average: game?.scores1 ? getAverage5Scores(game.scores1) : undefined,
    },
    {
      age: AGES[1],
      money: game?.scores2 ? game.scores2[0] : undefined,
      health: game?.scores2 ? game.scores2[1] : undefined,
      performance: game?.scores2 ? game.scores2[2] : undefined,
      management: game?.scores2 ? game.scores2[3] : undefined,
      network: game?.scores2 ? game.scores2[4] : undefined,
      leisure: game?.scores2 ? game.scores2[5] : undefined,
      average: game?.scores2 ? getAverage5Scores(game.scores2) : undefined,
    },
    {
      age: AGES[2],
      money: game?.scores3 ? game.scores3[0] : undefined,
      health: game?.scores3 ? game.scores3[1] : undefined,
      performance: game?.scores3 ? game.scores3[2] : undefined,
      management: game?.scores3 ? game.scores3[3] : undefined,
      network: game?.scores3 ? game.scores3[4] : undefined,
      leisure: game?.scores3 ? game.scores3[5] : undefined,
      average: game?.scores3 ? getAverage5Scores(game.scores3) : undefined,
    },
    {
      age: AGES[3],
      money: game?.scores4 ? game.scores4[0] : undefined,
      health: game?.scores4 ? game.scores4[1] : undefined,
      performance: game?.scores4 ? game.scores4[2] : undefined,
      management: game?.scores4 ? game.scores4[3] : undefined,
      network: game?.scores4 ? game.scores4[4] : undefined,
      leisure: game?.scores4 ? game.scores4[5] : undefined,
      average: game?.scores4 ? getAverage5Scores(game.scores4) : undefined,
    },
    {
      age: AGES[4],
      money: game?.scores5 ? game.scores5[0] : undefined,
      health: game?.scores5 ? game.scores5[1] : undefined,
      performance: game?.scores5 ? game.scores5[2] : undefined,
      management: game?.scores5 ? game.scores5[3] : undefined,
      network: game?.scores5 ? game.scores5[4] : undefined,
      leisure: game?.scores5 ? game.scores5[5] : undefined,
      average: game?.scores5 ? getAverage5Scores(game.scores5) : undefined,
    },
    {
      age: AGES[5],
      money: game?.scores6 ? game.scores6[0] : undefined,
      health: game?.scores6 ? game.scores6[1] : undefined,
      performance: game?.scores6 ? game.scores6[2] : undefined,
      management: game?.scores6 ? game.scores6[3] : undefined,
      network: game?.scores6 ? game.scores6[4] : undefined,
      leisure: game?.scores6 ? game.scores6[5] : undefined,
      average: game?.scores6 ? getAverage5Scores(game.scores6) : undefined,
    },
  ];

  // Recharts
  // https://recharts.org/en-US/examples/BiaxialLineChart
  // https://saitoxu.io/2018/02/recharts-intro
  return (
    <div className={classes.chartWrapper}>
      <ResponsiveContainer width="95%" height={isXsDown ? 330 : 400}>
        <LineChart
          data={graphData}
          margin={{ top: 5, right: 15, left: -10, bottom: 15 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={'age'} interval={0} angle={-30} dx={-15} dy={12} />
          <YAxis />
          <Tooltip />
          <Legend
            verticalAlign="top"
            wrapperStyle={{
              paddingLeft: isXsDown ? 20 : 30,
              paddingBottom: 20,
              // paddingRight: isXsDown ? 0 : 20,
              display: 'flex',
              alignItems: 'center',
            }}
            height={isXsDown ? 68 : 48}
          />
          {SCORE_KEYS.map((key) => {
            return (
              <Line
                // name属性を設定することで<Legend />の名称を意図的に設定できる。
                // https://recharts.org/en-US/api/Legend
                // https://stackoverflow.com/questions/44413185/custom-legend-labels-in-my-rechart-chart
                name={key.ja}
                dataKey={key.en}
                stroke={getColor(key.en)}
                fill={getColor(key.en)}
                key={key.en}
                type="monotone" // 曲線
                strokeWidth={key.en === 'average' ? 1.5 : 1} // 線の太さ
                // グラフのドット非表示の対応
                // https://github.com/recharts/recharts/issues/1426
                isAnimationActive={false}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ColorsLineChart;
