import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

type Props = {
  playerName: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '8px 10px 0 auto',
      maxWidth: '45vw',
      '& p': {
        textAlign: 'right',
        fontSize: 13,
        '& span': {
          fontSize: 12,
        },
      },
    },
  })
);

const PlayerName: React.FC<Props> = ({ playerName }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography>
        {playerName && playerName}&nbsp;{playerName && <span>さん</span>}
      </Typography>
    </div>
  );
};

export default PlayerName;
