import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import * as consts from '../../../consts';
import { sendEmailVerify } from '../../../firebase';

type BaseProps = {
  variant: 'contained' | 'text' | 'outlined';
  bgColor?: string;
  border?: string;
  padding?: number;
};

export type CustomProps = {
  square?: boolean;
  fontColor?: string;
  fontSize?: number;
  bold?: 'normal';
  marginRight?: string;
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
  disabled?: boolean;
  onClick:
    | 'toHome'
    | 'toRegister'
    | 'toLogin'
    | 'toTerms'
    | 'toHelp'
    | 'toMyPage'
    | 'toGameHistory'
    | 'toManager'
    | 'toManagerGroup'
    | 'toGame'
    | 'toGameGroup'
    | 'mailSendEmailVerify'
    | 'none';
  submit?: boolean;
};

type Props = BaseProps & CustomProps;

const BasicButton: React.FC<Props> = (props) => {
  const history = useHistory();

  const handleClick = (onClick: string) => {
    switch (onClick) {
      case 'toHome':
        history.push(consts.PAGE_HOME);
        break;
      case 'toRegister':
        history.push(consts.PAGE_REGISTER);
        break;
      case 'toLogin':
        history.push(consts.PAGE_LOGIN);
        break;
      case 'toTerms':
        history.push(consts.PAGE_TERMS);
        break;
      case 'toHelp':
        history.push(consts.PAGE_HELP);
        break;
      case 'toMyPage':
        history.push(consts.PAGE_MYPAGE);
        break;
      case 'toGameHistory':
        history.push(consts.PAGE_MYPAGE_GAMEHISTORY);
        break;
      case 'toManager':
        history.push(consts.PAGE_MYPAGE_MANAGER);
        break;
      case 'toManagerGroup':
        history.push(consts.PAGE_MYPAGE_MANAGER_GROUP);
        break;
      case 'toGame':
        history.push(consts.PAGE_GAME);
        break;
      case 'toGameGroup':
        history.push(consts.PAGE_GAME_GROUP);
        break;
      case 'mailSendEmailVerify':
        sendEmailVerify();
        break;
      case 'none':
        break;
      default:
        alert(`****** No Set onClick with [ ${onClick} ] ******`);
        break;
    }
  };

  return (
    <>
      <Button
        variant={props.variant}
        style={{
          backgroundColor: props.disabled ? consts.COLOR_GRAY : props.bgColor,
          borderRadius: props.square ? 8 : 9999,
          color: props.fontColor ?? consts.COLOR_WHITE,
          fontSize: props.fontSize ?? undefined,
          fontWeight: props.bold ?? 'bold',
          marginRight: props.marginRight ?? 0,
          border: props.border && `1px solid ${props.border}`,
          padding: props.padding + 'px 0' ?? undefined,
        }}
        size={props.size ?? 'large'}
        fullWidth={props.fullWidth ?? true}
        disabled={props.disabled ?? false}
        onClick={() => handleClick(props.onClick)}
        type={props.submit ? 'submit' : 'button'}
      >
        {props.children}
      </Button>
    </>
  );
};

export default BasicButton;
