import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  COLOR_LINK,
  MARGIN_L,
  MARGIN_S,
  PAGE_PASSRESET,
} from '../../../consts';
import { anonymouslyLogin } from '../../../firebase';

type Props = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: MARGIN_L,
      '& .centerDiv': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& .marginTopS': {
        marginTop: MARGIN_S,
      },
    },
    link: {
      fontSize: 14,
      color: COLOR_LINK,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

const LoginOption: React.FC<Props> = ({ setLoading }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.wrapper}>
      <div className="centerDiv" onClick={() => history.push(PAGE_PASSRESET)}>
        <Typography className={classes.link}>
          パスワードを忘れた場合はこちら
        </Typography>
      </div>
      <div
        className="centerDiv marginTopS"
        onClick={() => anonymouslyLogin(setLoading)}
      >
        <Typography className={classes.link}>
          匿名でログインする場合はこちら
        </Typography>
      </div>
    </div>
  );
};

export default LoginOption;
