import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import firebase from 'firebase/app';
import BaseContainerGame from '../../components/shared/BaseContainerGame';
import CenterDiv from '../../components/shared/CenterDiv';
import MaxWidthAdjust from '../../components/shared/MaxWidthAdjust';
import MainSelectButton from './components/MainSelectButton';
import {
  AGES,
  CARDS_HAP,
  CARDS_JOB,
  CARDS_LIF_MSG,
  CARDS_PRI_DECK,
  CARDS_SOC,
  CARD_HAP_BACK,
  CARD_SOC_BACK,
  collectionName,
  DEFAULT_GAME,
  DEFAULT_SCORE,
  MARGIN_XL,
  PAGE_GAME,
  PAGE_GAME_GROUP,
  PAGE_HOME,
  PATH_HAP,
  PATH_JOB,
  PATH_LIF_MSG,
  PATH_PRI,
  PATH_SOC,
  TURN_SCENE,
} from '../../consts';
import {
  addActiveGames,
  db,
  // addFinishedGames,
  // deleteActiveGame,
  updateActiveGames,
  finishThisGame,
} from '../../firebase';
import { FirebaseContext } from '../../contexts';
import { ActiveGames } from '../../models';
import CardsBlock from './components/CardsBlock';
import CardsBlockBack from './components/CardsBlockBack';
import CardCheck from './components/CardCheck';
import Information from './components/Information';
import GameDescription from './components/GameDescription';
import ScoreLineChart from './components/ScoreLineChart';
import TurnStartDialog from './components/TurnStartDialog';
import ResultGoal from './components/ResultGoal';
import Loading from '../../components/shared/Loading';
import Preparation from './components/Preparation';
import AchieveGoal from './components/AchieveGoal';
import CheckboxText from './components/CheckboxText';
import PlayerName from './components/PlayerName';
import CompareResult from './components/CompareResult';

const Game: React.FC = () => {
  const { user } = useContext(FirebaseContext);
  const history = useHistory();
  const location = useLocation();

  const [game, setGame] = useState<ActiveGames | null>(null);
  const [openTurnStart, setOpenTurnStart] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);

  const gameCollectionName =
    location.pathname === PAGE_GAME_GROUP
      ? collectionName.activeGroupGames
      : collectionName.activeGames;
  const titleTurn = game?.turn ? `${game?.turn}ターン目` : ' ';
  const titleAge = game?.turn ? AGES[game?.turn - 1] : ' ';
  const activeCardPrivate =
    game?.turn === 1
      ? game.cardPrivate1
      : game?.turn === 2
      ? game.cardPrivate2
      : game?.turn === 3
      ? game.cardPrivate3
      : game?.turn === 4
      ? game.cardPrivate4
      : game?.turn === 5
      ? game.cardPrivate5
      : game?.turn === 6
      ? game.cardPrivate6
      : null;
  const activeScores = game?.scores6
    ? game.scores6
    : game?.scores5
    ? game.scores5
    : game?.scores4
    ? game.scores4
    : game?.scores3
    ? game.scores3
    : game?.scores2
    ? game.scores2
    : game?.scores1
    ? game.scores1
    : game?.scores0
    ? game.scores0
    : DEFAULT_SCORE;
  const previousScores = game?.scores6
    ? game.scores5
    : game?.scores5
    ? game.scores4
    : game?.scores4
    ? game.scores3
    : game?.scores3
    ? game.scores2
    : game?.scores2
    ? game.scores1
    : game?.scores1
    ? game.scores0
    : DEFAULT_SCORE;
  const previousInfo = game?.turn ? `events${game?.turn}` : null;
  // result専用Info
  const resultInfo = game?.turn ? `events${game?.turn + 1}` : null;
  const socialCards =
    location.pathname === PAGE_GAME_GROUP && game?.turn === 2
      ? [game.options.groupSoc1]
      : location.pathname === PAGE_GAME_GROUP && game?.turn === 4
      ? [game.options.groupSoc2]
      : location.pathname === PAGE_GAME_GROUP && game?.turn === 6
      ? [game.options.groupSoc3]
      : CARDS_SOC;
  const onGaming = useCallback(async () => {
    const newGame = {
      ...DEFAULT_GAME,
      // スプレッド構文で展開後にプロパティを記載することで、上書き。
      // https://qiita.com/FumioNonaka/items/58358a29850afd7a0f37
      id: user!.uid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    await addActiveGames(gameCollectionName, newGame, setGame);
  }, [gameCollectionName, user]);

  const checkGaming = () => {
    if (user) {
      // onSnapshot() によって、Firestoreの更新を自動的にキャッチ。
      // https://qiita.com/ddpmntcpbr/items/57ef0619b7b29e4edf08#onsnapshot%E3%81%AB%E3%82%88%E3%82%8B%E3%83%AA%E3%82%A2%E3%83%AB%E3%82%BF%E3%82%A4%E3%83%A0%E5%90%8C%E6%9C%9F
      const unsubscribe = db
        .collection(gameCollectionName)
        .doc(user.uid)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const data = {
              id: doc.data()?.id,
              turn: doc.data()?.turn,
              turnScene: doc.data()?.turnScene,
              lifeGoal: doc.data()?.lifeGoal,
              scores0: doc.data()?.scores0,
              scores1: doc.data()?.scores1,
              scores2: doc.data()?.scores2,
              scores3: doc.data()?.scores3,
              scores4: doc.data()?.scores4,
              scores5: doc.data()?.scores5,
              scores6: doc.data()?.scores6,
              cardJob: doc.data()?.cardJob,
              cardPrivate1: doc.data()?.cardPrivate1,
              cardPrivate2: doc.data()?.cardPrivate2,
              cardPrivate3: doc.data()?.cardPrivate3,
              cardPrivate4: doc.data()?.cardPrivate4,
              cardPrivate5: doc.data()?.cardPrivate5,
              cardPrivate6: doc.data()?.cardPrivate6,
              cardHappening: doc.data()?.cardHappening,
              cardSocialChange: doc.data()?.cardSocialChange,
              events1: doc.data()?.events1,
              events2: doc.data()?.events2,
              events3: doc.data()?.events3,
              events4: doc.data()?.events4,
              events5: doc.data()?.events5,
              events6: doc.data()?.events6,
              events7: doc.data()?.events7,
              options: doc.data()?.options,
              createdAt: doc.data()?.createdAt,
              updatedAt: doc.data()?.updatedAt,
            };
            setGame(data);
          }
        });
      return () => unsubscribe();
    }
  };

  const SceneToHappening = useCallback(async () => {
    await updateActiveGames(gameCollectionName, game!.id, {
      turnScene: TURN_SCENE.happening,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }, [game, gameCollectionName]);

  const makeHappeningDeck = () => {
    let newDeck = [];
    if (game?.turn === 4) {
      newDeck = CARDS_HAP.filter((card, index) => index < 8);
    } else if (game?.turn === 5) {
      newDeck = CARDS_HAP.filter((card, index) => index < 7);
    } else if (game?.turn === 6) {
      newDeck = CARDS_HAP.filter((card, index) => index < 6);
    } else {
      newDeck = [...CARDS_HAP];
    }
    // 社会変化「1 医療技術の進歩」
    if (game?.cardSocialChange?.some((soc) => soc === CARDS_SOC[0])) {
      newDeck = [...newDeck, 'h-03', 'h-03', 'h-03'];
    }

    return newDeck;
  };

  const handleClickFinished = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);

      const result = await finishThisGame(game, user, gameCollectionName, setIsLoading);

      if (result) {
        history.push(PAGE_HOME);
      } else {
        alert(
          'ゲームの終了処理ができませんでした。少し時間を置いてから再実行してください。'
        );
      }
    },
    [game, gameCollectionName, history, user]
  );

  useEffect(() => {
    if (user && (user.emailVerified || user.providerData.length === 0)) {
      setIsActive(true);
      checkGaming();
      // checkGaming()が完了する間、loadingを表示。
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      history.push(PAGE_HOME);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [game?.turnScene]);

  useEffect(() => {
    if (
      game?.turnScene === 'private' &&
      // game.cardJob &&
      // game.cardJob.length === game.turn &&
      activeCardPrivate &&
      activeCardPrivate.length === 3
    ) {
      SceneToHappening();
    }
    // eslint-disable-next-line
  }, [game]);

  useEffect(() => {
    if (game !== null && game?.turn !== 0) {
      setOpenTurnStart(true);
    }
    // eslint-disable-next-line
  }, [game?.turn]);

  return (
    <>
      {isActive && !isLoading && game && (
        <PlayerName playerName={game.options.playerName} />
      )}

      {isActive && !isLoading && !game && location.pathname === PAGE_GAME && (
        <BaseContainerGame
          title={`ようこそ ${
            user?.displayName ? user.displayName : '匿名ユーザー'
          } さん`}
        >
          <Preparation
            collectionGame={gameCollectionName}
            mode="s"
            activeGameId={user?.uid || ''}
            playerName={user?.displayName || ''}
            onGaming={onGaming}
          />
        </BaseContainerGame>
      )}

      {isActive &&
        !isLoading &&
        !game &&
        location.pathname === PAGE_GAME_GROUP && (
          <BaseContainerGame
            title={`ようこそ ${
              user?.displayName ? user.displayName : '匿名ユーザー'
            } さん`}
          >
            <Preparation
              collectionGame={gameCollectionName}
              mode="g"
              activeGameId={user?.uid || ''}
              playerName={user?.displayName || ''}
              onGaming={onGaming}
            />
          </BaseContainerGame>
        )}

      {isActive &&
        !isLoading &&
        game &&
        game?.turn === 0 &&
        game.turnScene === 'start' && (
          <BaseContainerGame title="人生目標カード選択">
            <GameDescription>
              はじめに人生目標カードを1枚選んでください。
              <br />
              <span>カードをタッチすると拡大して表示されます。</span>
            </GameDescription>
            <CardsBlock
              cards={CARDS_LIF_MSG}
              path={PATH_LIF_MSG}
              game={game}
              activeScores={activeScores}
              gameCollectionName={gameCollectionName}
            />
          </BaseContainerGame>
        )}

      {isActive &&
        !isLoading &&
        game &&
        game?.turn === 0 &&
        game.turnScene === 'checkGoal' && (
          <BaseContainerGame title="人生目標カード確認">
            <GameDescription>
              あなたが選んだ人生目標カードは以下のとおりです。
              <br />
              達成目標を目指しましょう。
            </GameDescription>
            <CardCheck game={game} gameCollectionName={gameCollectionName} />
          </BaseContainerGame>
        )}

      {isActive &&
        !isLoading &&
        game &&
        game?.turn !== 0 &&
        (game?.turnScene === 'job' ||
          game?.turnScene === 'private' ||
          game.turnScene === 'result') && (
          <BaseContainerGame
            // keyがないと Each child ... の警告が出る
            title={
              game.turnScene === 'result'
                ? '最終結果'
                : [titleTurn, <br key="break" />, titleAge]
            }
            marginBottom="L"
            turnScene={game.turnScene}
          >
            {game.turnScene === 'result' && (
              <>
                <GameDescription center>お疲れ様でした！</GameDescription>
                <GameDescription>
                  ゲームの結果はいかがでしたでしょうか？
                  <br />
                  次回は、ぜひまた違った人生を選択してみてください。
                  <br />
                  MIRAIZ（ミライズ）をプレイしてくださりありがとうございます。
                </GameDescription>
              </>
            )}

            {game.turnScene === 'result' && <ResultGoal game={game} />}
            {game.turnScene !== 'result' &&
              previousInfo &&
              game[previousInfo] && (
                <Information game={game} infoLabel={previousInfo} />
              )}
            {game.turnScene === 'result' && resultInfo && game[resultInfo] && (
              <Information game={game} infoLabel={resultInfo} />
            )}
            {game.turnScene !== 'result' && (
              <AchieveGoal lifeGoal={game.lifeGoal!} />
            )}
            <ScoreLineChart
              game={game!}
              activeScores={activeScores}
              previousScores={previousScores!}
            />
            {game.options.mode === 'g' && game.turnScene === 'result' && (
              <CompareResult game={game} activeScores={activeScores} />
            )}
          </BaseContainerGame>
        )}

      {isActive &&
        !isLoading &&
        game &&
        game?.turnScene === 'job' &&
        (game.cardJob === null || game.cardJob.length < game.turn) && (
          <BaseContainerGame>
            <GameDescription>仕事カードを1枚選んでください。</GameDescription>
            <CardsBlock
              cards={CARDS_JOB}
              path={PATH_JOB}
              game={game}
              activeScores={activeScores}
              gameCollectionName={gameCollectionName}
            />
          </BaseContainerGame>
        )}

      {isActive &&
        !isLoading &&
        game &&
        game?.turnScene === 'private' &&
        (activeCardPrivate === null || activeCardPrivate.length < 3) && (
          <BaseContainerGame>
            <GameDescription center>
              プライベートカードを3枚選んでください。
              <br />
              <span>
                残り
                {activeCardPrivate === null ? 3 : 3 - activeCardPrivate.length}
                枚
              </span>
            </GameDescription>
            <CardsBlock
              cards={CARDS_PRI_DECK}
              path={PATH_PRI}
              game={game}
              activeScores={activeScores}
              activeCardPrivate={activeCardPrivate}
              gameCollectionName={gameCollectionName}
            />
          </BaseContainerGame>
        )}

      {isActive && !isLoading && game && game?.turnScene === 'happening' && (
        <BaseContainerGame
          // keyがないと Each child ... の警告が出る
          title={[titleTurn, <br key="break" />, titleAge]}
          marginBottom="L"
          turnScene={game.turnScene}
        >
          <GameDescription>
            ハプニングカードを1枚めくってください。
          </GameDescription>
          <CardsBlockBack
            cards={makeHappeningDeck()}
            path={PATH_HAP}
            file={CARD_HAP_BACK}
            game={game}
            gameCollectionName={gameCollectionName}
          />
        </BaseContainerGame>
      )}

      {isActive && !isLoading && game && game?.turnScene === 'checkHappening' && (
        <BaseContainerGame
          title={[titleTurn, <br key="break" />, titleAge]}
          turnScene={game.turnScene}
        >
          <GameDescription>
            今回起きたハプニングは以下のとおりです。
          </GameDescription>
          <CardCheck game={game} gameCollectionName={gameCollectionName} />
        </BaseContainerGame>
      )}

      {isActive && !isLoading && game && game?.turnScene === 'social' && (
        <BaseContainerGame
          // keyがないと Each child ... の警告が出る
          title={[titleTurn, <br key="break" />, titleAge]}
          marginBottom="L"
          turnScene={game.turnScene}
        >
          <GameDescription>
            {location.pathname === PAGE_GAME_GROUP
              ? `社会変化カードをめくってください。`
              : `社会変化カードを1枚めくってください。`}
          </GameDescription>
          <CardsBlockBack
            cards={socialCards}
            path={PATH_SOC}
            file={CARD_SOC_BACK}
            game={game}
            gameCollectionName={gameCollectionName}
          />
        </BaseContainerGame>
      )}

      {isActive && !isLoading && game && game?.turnScene === 'checkSocial' && (
        <BaseContainerGame
          title={[titleTurn, <br key="break" />, titleAge]}
          turnScene={game.turnScene}
        >
          <GameDescription>
            今回起きた社会変化は以下のとおりです。
          </GameDescription>
          <CardCheck game={game} gameCollectionName={gameCollectionName} />
        </BaseContainerGame>
      )}

      {isActive &&
        !isLoading &&
        game &&
        game?.turn === 6 &&
        game.turnScene === 'result' && (
          <BaseContainerGame>
            {game.options.mode === 's' ? (
              <CenterDiv marginTop="M">
                <MaxWidthAdjust>
                  <MainSelectButton padding={14} onClick={handleClickFinished}>
                    ゲームを終了する
                  </MainSelectButton>
                </MaxWidthAdjust>
              </CenterDiv>
            ) : (
              <>
                <CheckboxText
                  checked={checked}
                  setChecked={setChecked}
                  isResult
                  checked2={checked2}
                  setChecked2={setChecked2}
                />
                <CenterDiv>
                  <MaxWidthAdjust>
                    <MainSelectButton
                      padding={14}
                      onClick={handleClickFinished}
                      disabled={!checked || !checked2}
                    >
                      ゲームを終了する
                    </MainSelectButton>
                  </MaxWidthAdjust>
                </CenterDiv>
              </>
            )}
          </BaseContainerGame>
        )}

      {isActive &&
        !isLoading &&
        game !== null &&
        game.turn !== 0 &&
        (((game.turn === 2 || game.turn === 4 || game.turn === 6) &&
          game.turnScene === TURN_SCENE.social) ||
          ((game.turn === 1 || game.turn === 3 || game.turn === 5) &&
            game.turnScene === TURN_SCENE.job)) && (
          <TurnStartDialog
            open={openTurnStart}
            setOpen={setOpenTurnStart}
            titleTurn={titleTurn}
            titleAge={titleAge}
          />
        )}

      {(!isActive || isLoading) && <Loading />}

      <div style={{ marginBottom: MARGIN_XL }}></div>
    </>
  );
};

export default Game;
