import React from 'react';
import { COLOR_BLACK2 } from '../../consts';
import BasicButton, { CustomProps } from './atoms/BasicButton';

type Props = CustomProps;

const MainButton: React.FC<Props> = (props) => {
  return (
    <div>
      <BasicButton
        variant="contained"
        bgColor={COLOR_BLACK2}
        square
        padding={14}
        {...props}
      >
        {props.children}
      </BasicButton>
    </div>
  );
};

export default MainButton;
