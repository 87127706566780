import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

type Props = {
  isNamed: number;
  setIsNamed: React.Dispatch<React.SetStateAction<number>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 85,
      [theme.breakpoints.down('xs')]: {
        margin: '5px 15px',
      },
      [theme.breakpoints.up('sm')]: {
        margin: 15,
      },
    },
    menu: {
      fontSize: 14,
    },
  })
);

const NamedList: React.FC<Props> = ({ isNamed, setIsNamed }) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIsNamed(event.target.value as number);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          labelId="format-label"
          id="format-id"
          value={isNamed}
          onChange={handleChange}
          label="Format"
          className={classes.menu}
        >
          <MenuItem value={1} className={classes.menu}>
            名前あり
          </MenuItem>
          <MenuItem value={0} className={classes.menu}>
            名前なし
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default NamedList;
