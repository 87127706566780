import React, { useState, useCallback } from 'react';
import {
  Grid,
  Modal,
  Backdrop,
  Fade,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import {
  BIG_CARD_WIDTH,
  CARDS_JOB,
  CARDS_PRI,
  CARDS_SOC,
  CARD_BANKRUPT,
  CARD_DISABLE,
  CARD_SELECTED_1,
  CARD_SELECTED_2,
  CARD_SHADOW,
  EVENTS_INFO_KEYS,
  MARGIN_S,
  TURN_SCENE,
} from '../../../consts';
import TransparentButton from './TransparentButton';
import MainSelectButton from './MainSelectButton';
import { updateActiveGames } from '../../../firebase';
import { ActiveGames } from '../../../models';

type Props = {
  cards: string[];
  path: string;
  game: ActiveGames;
  activeScores: number[];
  activeCardPrivate?: string[] | null;
  gameCollectionName: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsBlock: {
      marginTop: MARGIN_S,
    },
    cardBox: {
      '& img': {
        cursor: 'pointer',
        width: '100%',
        // border: `solid 0.1px rgba(0, 0, 0, 0.1)`, // shadowに変更
        boxShadow: CARD_SHADOW,
        [theme.breakpoints.down('xs')]: {
          borderRadius: 5,
        },
        [theme.breakpoints.up('sm')]: {
          borderRadius: 10,
        },
      },
      '& .__noSelectBox': {
        position: 'relative',
        '& .__noSelect': {
          cursor: 'default',
        },
        '& .__noSelectImg': {
          cursor: 'default',
          position: 'absolute',
          zIndex: 3,
          boxShadow: 'none',
          width: '100%',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
        },
      },
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      '& .__paper': {
        outline: 0,
        width: '95%',
        maxWidth: BIG_CARD_WIDTH,
        maxHeight: '95%', // この設定によりoverflowが有効となる。
        overflow: 'auto',
        '& .__imgBox': {
          marginBottom: 10,
          '& .__img': {
            margin: 0,
            padding: 0,
            width: '100%',
            [theme.breakpoints.down('xs')]: {
              borderRadius: 10,
            },
            [theme.breakpoints.up('sm')]: {
              borderRadius: 15,
            },
          },
        },
        '& .__buttonsBox': {
          display: 'flex',
          '& .__button1': {
            flex: 1,
            marginRight: 5,
          },
          '& .__button2': {
            flex: 1,
            marginLeft: 5,
          },
        },
      },
    },
  })
);

const CardsBlock: React.FC<Props> = ({
  cards,
  path,
  game,
  activeScores,
  activeCardPrivate,
  gameCollectionName,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const [open, setOpen] = useState(false);
  const [card, setCard] = useState('');

  const activeCardPrivateKey = `cardPrivate${game.turn}`;
  const activeEventsKey = `events${game.turn}`;

  const makeJobEvents = (card: string, events: string[] | null) => {
    const jobEvents =
      card === CARDS_JOB[0] || card === CARDS_JOB[6] || card === CARDS_JOB[8]
        ? [EVENTS_INFO_KEYS.prohibitedPriSideJob]
        : card === CARDS_JOB[9]
        ? [
            EVENTS_INFO_KEYS.prohibitedPriSideJob,
            EVENTS_INFO_KEYS.prohibitedPriPartTimeJob,
          ]
        : null;

    if (events !== null && jobEvents !== null) {
      return [...jobEvents, ...events];
    } else if (events !== null && jobEvents === null) {
      return [...events];
    } else if (events === null && jobEvents !== null) {
      return [...jobEvents];
    } else {
      return null;
    }
  };

  const handleOpen = (card: string) => {
    setCard(card);
    setOpen(true);
  };

  const handleClose = () => {
    setCard('');
    setOpen(false);
  };

  const handleClick = useCallback(
    async (event) => {
      event.preventDefault();

      const check = window.confirm(
        'このカードにしますか？　※[OK]を押すとやり直しできません。'
      );
      if (check) {
        const updateObj =
          game.turnScene === 'start'
            ? {
                turnScene: TURN_SCENE.checkGoal,
                lifeGoal: card,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              }
            : card.split('-')[0] === 'j'
            ? {
                turnScene: TURN_SCENE.private,
                cardJob: game.cardJob ? [...game.cardJob, card] : [card],
                [activeEventsKey]: makeJobEvents(card, game[activeEventsKey]), // 仕事のイベントを登録
                options:
                  game.turn === 1
                    ? { ...game.options }
                    : game.cardJob![game.turn - 2] === card
                    ? { ...game.options, jobKeep: game.options.jobKeep + 1 }
                    : // 前職と異なる場合、数値を初期化。フラグ（jobChanged）をオン。転職回数（jobChange）を1追加。
                      {
                        ...game.options,
                        jobKeep: 1,
                        jobChanged: true,
                        jobChange: game.options.jobChange + 1,
                      },
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              }
            : card.split('-')[0] === 'p'
            ? {
                [activeCardPrivateKey]: activeCardPrivate
                  ? [...activeCardPrivate, card]
                  : [card],
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              }
            : {
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              };
        /**
         * 下記 updateActiveGames() によって自動的に本コンポーネントが破棄される。
         * 破棄後にstateを変更しようとすると、下記のメモリリークに関する警告が出るため、handleClose() を先に実行する。
         * Warning: Can't perform a React state update on an unmounted component.This is a no-op, but...
         */
        handleClose();
        await updateActiveGames(gameCollectionName, game.id, updateObj);
      }
    },
    [
      activeCardPrivate,
      activeCardPrivateKey,
      activeEventsKey,
      card,
      game,
      gameCollectionName,
    ]
  );

  return (
    <>
      <Grid
        container
        className={classes.cardsBlock}
        justify="center"
        spacing={isXsDown ? 2 : 4}
      >
        {cards.map((card, index) => {
          return (
            <Grid
              item
              xs={4}
              sm={3}
              md={2}
              key={card + index}
              className={classes.cardBox}
            >
              {
                // ========================================
                // 【カードルール】private「なにもしない」: 1枚選択済みの場合
                index === 0 &&
                activeCardPrivate &&
                activeCardPrivate.indexOf(CARDS_PRI[0]) >= 0 ? (
                  <div className="__noSelectBox">
                    <img
                      src={`${process.env.PUBLIC_URL}${path}${card}.svg`}
                      alt={card}
                      className="__noSelect"
                    />
                    <img
                      src={
                        activeCardPrivate.indexOf(CARDS_PRI[0]) === 0
                          ? CARD_SELECTED_1
                          : CARD_SELECTED_2
                      }
                      alt="card-selected"
                      className="__noSelectImg"
                    />
                  </div>
                ) : // ========================================
                // 【カードルール】private「なにもしない」: 2枚選択済みの場合
                index === 1 &&
                  activeCardPrivate &&
                  activeCardPrivate.filter((pri) => pri === CARDS_PRI[0])
                    .length === 2 ? (
                  <div className="__noSelectBox">
                    <img
                      src={`${process.env.PUBLIC_URL}${path}${card}.svg`}
                      alt={card}
                      className="__noSelect"
                    />
                    <img
                      src={CARD_SELECTED_2}
                      alt="card-selected"
                      className="__noSelectImg"
                    />
                  </div>
                ) : // ========================================
                // 【カードルール】private「なにもしない」以外: 選択済み（1枚目）
                card !== CARDS_PRI[0] &&
                  activeCardPrivate &&
                  activeCardPrivate.indexOf(card) === 0 ? (
                  <div className="__noSelectBox">
                    <img
                      src={`${process.env.PUBLIC_URL}${path}${card}.svg`}
                      alt={card}
                      className="__noSelect"
                    />
                    <img
                      src={CARD_SELECTED_1}
                      alt="card-selected"
                      className="__noSelectImg"
                    />
                  </div>
                ) : // ========================================
                // 【カードルール】private「なにもしない」以外: 選択済み（2枚目）
                card !== CARDS_PRI[0] &&
                  activeCardPrivate &&
                  activeCardPrivate.indexOf(card) === 1 ? (
                  <div className="__noSelectBox">
                    <img
                      src={`${process.env.PUBLIC_URL}${path}${card}.svg`}
                      alt={card}
                      className="__noSelect"
                    />
                    <img
                      src={CARD_SELECTED_2}
                      alt="card-selected"
                      className="__noSelectImg"
                    />
                  </div>
                ) : // ========================================
                // 【カードルール】job 倒産ルール
                game.options.bankrupt &&
                  card === game.cardJob![game.cardJob?.length! - 1] ? (
                  <div className="__noSelectBox">
                    <img
                      src={`${process.env.PUBLIC_URL}${path}${card}.svg`}
                      alt={card}
                      className="__noSelect"
                    />
                    <img
                      src={CARD_BANKRUPT}
                      alt="card-disable"
                      className="__noSelectImg"
                    />
                  </div>
                ) : // ========================================
                // 【カードルール】job 不可ルール
                // ◆社会変化「3 大不況」
                (game.turn !== 1 &&
                    game.turnScene === 'job' &&
                    game.cardSocialChange?.some(
                      (soc) => soc === CARDS_SOC[2]
                    ) &&
                    game.cardSocialChange[game.cardSocialChange.length - 1] ===
                      CARDS_SOC[2] &&
                    // 転職不可対象
                    (card === CARDS_JOB[1] ||
                      card === CARDS_JOB[2] ||
                      card === CARDS_JOB[3] ||
                      card === CARDS_JOB[4] ||
                      card === CARDS_JOB[9] ||
                      card === CARDS_JOB[10]) &&
                    // 前ターンと同じ仕事は対象外
                    card !== game.cardJob![game.cardJob?.length! - 1] &&
                    // パラメーターが条件を満たせば大不況の影響を受けない
                    (activeScores[3] < 30 * game.cardSocialChange?.length! ||
                      activeScores[4] < 30 * game.cardSocialChange?.length!)) ||
                  // ◆社会変化「4 AI技術の進歩」
                  (game.turn !== 1 &&
                    game.turnScene === 'job' &&
                    game.cardSocialChange?.some(
                      (soc) => soc === CARDS_SOC[3]
                    ) &&
                    // 転職不可対象
                    card !== CARDS_JOB[0] &&
                    card !== CARDS_JOB[6] &&
                    card !== CARDS_JOB[8] &&
                    // パラメーターが条件を満たせばAI技術の進歩の影響を受けない
                    activeScores[2] < 30 * game.cardSocialChange?.length!) ||
                  // --------------------
                  // 【カードルール】private 不可ルール
                  // ! ルール無効化
                  // // ◆「出産・育児」
                  // // 5ターン目以降不可
                  // // 海外・国内どちらの旅行とも併用不可
                  // (card === CARDS_PRI[4] &&
                  //   (game.turn > 4 ||
                  //     (activeCardPrivate &&
                  //       activeCardPrivate.some(
                  //         (pri) => pri === CARDS_PRI[7] || pri === CARDS_PRI[9]
                  //       )))) ||
                  // ! ルール無効化
                  // // ◆「海外旅行」「国内旅行」
                  // // 出産・育児と併用不可
                  // ((card === CARDS_PRI[7] || card === CARDS_PRI[9]) &&
                  //   activeCardPrivate &&
                  //   activeCardPrivate.some((pri) => pri === CARDS_PRI[4])) ||
                  // ◆「副業」
                  // 仕事カードが 0, 6, 8, 9 の場合は選択不可
                  (card === CARDS_PRI[21] &&
                    (game.cardJob![game.cardJob!.length - 1] === CARDS_JOB[0] ||
                      game.cardJob![game.cardJob!.length - 1] ===
                        CARDS_JOB[6] ||
                      game.cardJob![game.cardJob!.length - 1] ===
                        CARDS_JOB[8] ||
                      game.cardJob![game.cardJob!.length - 1] ===
                        CARDS_JOB[9])) ||
                  // ◆「パート・アルバイト」
                  // 仕事カードが 9 の場合は選択不可
                  (card === CARDS_PRI[22] &&
                    game.cardJob![game.cardJob!.length - 1] === CARDS_JOB[9]) ||
                  // ◆「住宅購入」
                  // お金が60未満の場合は選択不可
                  // 最大2回まで
                  (card === CARDS_PRI[2] &&
                    (activeScores[0] < 60 || game.options.boughtHomes >= 2)) ||
                  // ◆「自動車購入」
                  // お金が50未満の場合は選択不可
                  (card === CARDS_PRI[20] && activeScores[0] < 50) ? (
                  <div className="__noSelectBox">
                    <img
                      src={`${process.env.PUBLIC_URL}${path}${card}.svg`}
                      alt={card}
                      className="__noSelect"
                    />
                    <img
                      src={CARD_DISABLE}
                      alt="card-disable"
                      className="__noSelectImg"
                    />
                  </div>
                ) : (
                  // ルール適用なし
                  <img
                    src={`${process.env.PUBLIC_URL}${path}${card}.svg`}
                    alt={card}
                    onClick={() => handleOpen(card)}
                  />
                )
              }
            </Grid>
          );
        })}
      </Grid>

      <Modal
        aria-labelledby="card-modal"
        aria-describedby="card-modal-for-expand"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="__paper">
            <div className="__imgBox">
              <img
                className="__img"
                src={`${process.env.PUBLIC_URL}${path}${card}.svg`}
                alt={card}
              />
            </div>
            <div className="__buttonsBox">
              <div className="__button1">
                <TransparentButton onClick={handleClose}>
                  戻る
                </TransparentButton>
              </div>
              <div className="__button2">
                <MainSelectButton border="w1" onClick={handleClick}>
                  決定
                </MainSelectButton>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default CardsBlock;
