import React, { useState } from 'react';
import {
  Fade,
  Modal,
  Backdrop,
  IconButton,
  Grid,
  Paper,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { ActiveGames } from '../../../../models';
import {
  COLOR_LINK,
  COLOR_WHITE,
  PATH_LIF,
  AGES,
  COLOR_CARDS,
  BIG_CARD_WIDTH,
  COLOR_LIGHT_BLACK,
} from '../../../../consts';
import { getCardName } from '../../../../functions';
import TransparentButton from '../TransparentButton';

type Props = {
  game: ActiveGames;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gameLinks: {
      display: 'flex',
      justifyContent: 'space-evenly',
      marginBottom: 20,
      '& div': {
        color: COLOR_LINK,
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
        },
      },
    },
    // ie11のみに適用
    // https://www.softel.co.jp/blogs/tech/archives/6095
    '@media all and (-ms-high-contrast: none)': {
      gameLinks: {
        justifyContent: 'space-around',
      },
    },
    lifeCardModal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      '& .__paper': {
        outline: 0,
        width: '95%',
        maxWidth: BIG_CARD_WIDTH,
        maxHeight: '95%', // この設定によりoverflowが有効となる。
        overflow: 'auto',
        '& .__imgBox': {
          marginBottom: 10,
          '& .__img': {
            margin: 0,
            padding: 0,
            width: '100%',
            [theme.breakpoints.down('xs')]: {
              borderRadius: 10,
            },
            [theme.breakpoints.up('sm')]: {
              borderRadius: 15,
            },
          },
        },
      },
    },
    cardHistoryModal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      '& .__paper': {
        outline: 0,
        width: '90%',
        maxWidth: '1000px',
        maxHeight: '90%', // この設定によりoverflowが有効となる。
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        '& .__history': {
          margin: '10px auto 18px',
          width: '95%',
          '& .__item': {
            display: 'flex',
            justifyContent: 'center',
            '& .__turnBlock': {
              textAlign: 'center',
              fontSize: 14,
              [theme.breakpoints.down('xs')]: {
                padding: '5%',
                marginBottom: 17,
                width: '80%',
              },
              [theme.breakpoints.up('sm')]: {
                padding: '5%',
                marginBottom: 24,
                width: '80%',
              },
              '& .__title': {
                fontWeight: 'bold',
              },
              '& .__body': {
                marginTop: 12,
                '& .__cardTitle': {
                  borderRadius: 50,
                  color: COLOR_WHITE,
                  fontWeight: 'bold',
                },
                '& .__bgHap': {
                  backgroundColor: COLOR_CARDS.hap,
                },
                '& .__bgJob': {
                  backgroundColor: COLOR_CARDS.job,
                },
                '& .__bgPri': {
                  backgroundColor: COLOR_CARDS.pri,
                },
                '& .__bgSoc': {
                  backgroundColor: COLOR_CARDS.soc,
                },
                '& .__cardName': {
                  textAlign: 'left',
                },
              },
            },
          },
        },
      },
    },
    closeBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& .__close': {
        padding: 0,
        '& .__icon': {
          fontSize: 36,
          color: COLOR_LIGHT_BLACK,
          margin: 6,
        },
      },
    },
    buttonsBox: {
      display: 'flex',
      justifyContent: 'center',
      '& .__button': {
        flex: 1,
        maxWidth: BIG_CARD_WIDTH,
      },
      '& .__buttonHistory': {
        marginBottom: 36,
        width: '85%',
        maxWidth: BIG_CARD_WIDTH,
      },
    },
  })
);

const LinkModal: React.FC<Props> = ({ game }) => {
  const classes = useStyles();

  const [openLifeCard, setOpenLifeCard] = useState(false);
  const [openCardHistory, setOpenCardHistory] = useState(false);

  const handleOpenLifeCard = () => {
    setOpenLifeCard(true);
  };
  const handleCloseLifeCard = () => {
    setOpenLifeCard(false);
  };

  const handleOpenCardHistory = () => {
    setOpenCardHistory(true);
  };
  const handleCloseCardHistory = () => {
    setOpenCardHistory(false);
  };

  return (
    <>
      <div className={classes.gameLinks}>
        <div onClick={handleOpenLifeCard}>人生目標カード</div>
        <div onClick={handleOpenCardHistory}>カード履歴</div>
      </div>

      <Modal
        aria-labelledby="life-card"
        aria-describedby="life-card-desc"
        className={classes.lifeCardModal}
        open={openLifeCard}
        onClose={handleCloseLifeCard}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openLifeCard}>
          <div className="__paper">
            <div className="__imgBox">
              <img
                className="__img"
                src={`${process.env.PUBLIC_URL}${PATH_LIF}${game.lifeGoal}.svg`}
                alt={game.lifeGoal!}
              />
            </div>

            <div className={classes.buttonsBox}>
              <div className="__button">
                <TransparentButton onClick={handleCloseLifeCard}>
                  戻る
                </TransparentButton>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="card-history"
        aria-describedby="card-history-desc"
        className={classes.cardHistoryModal}
        open={openCardHistory}
        onClose={handleCloseCardHistory}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCardHistory}>
          <div className="__paper">
            <div className={classes.closeBox}>
              <IconButton
                aria-label="close"
                className="__close"
                onClick={handleCloseCardHistory}
              >
                <CloseIcon className="__icon" />
              </IconButton>
            </div>

            <Grid container className="__history" justify="center">
              {game.turn === 1 && (
                <Grid item xs={12} sm={6} md={4} className="__item">
                  <div>まだ履歴はありません。</div>
                </Grid>
              )}
              {game.turn >= 2 && (
                <Grid item xs={12} sm={6} md={4} className="__item">
                  <Paper elevation={3} className="__turnBlock">
                    <div className="__title">1ターン目 / {AGES[0]}</div>
                    <div className="__body">
                      <div className="__cardTitle __bgJob">仕事カード</div>
                      <div className="__cardName">
                        {getCardName(game.cardJob![0])}
                      </div>
                    </div>
                    <div className="__body">
                      <div className="__cardTitle __bgPri">
                        プライベートカード
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate1![0])}
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate1![1])}
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate1![2])}
                      </div>
                    </div>
                    <div className="__body">
                      <div className="__cardTitle __bgHap">
                        ハプニングカード
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardHappening![0])}
                      </div>
                    </div>
                  </Paper>
                </Grid>
              )}
              {game.turn >= 3 && (
                <Grid item xs={12} sm={6} md={4} className="__item">
                  <Paper elevation={3} className="__turnBlock">
                    <div className="__title">2ターン目 / {AGES[1]}</div>
                    <div className="__body">
                      <div className="__cardTitle __bgJob">仕事カード</div>
                      <div className="__cardName">
                        {getCardName(game.cardJob![1])}
                      </div>
                    </div>
                    <div className="__body">
                      <div className="__cardTitle __bgPri">
                        プライベートカード
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate2![0])}
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate2![1])}
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate2![2])}
                      </div>
                    </div>
                    <div className="__body">
                      <div className="__cardTitle __bgHap">
                        ハプニングカード
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardHappening![1])}
                      </div>
                    </div>
                    <div className="__body">
                      <div className="__cardTitle __bgSoc">社会変化カード</div>
                      <div className="__cardName">
                        {getCardName(game.cardSocialChange![0])}
                      </div>
                    </div>
                  </Paper>
                </Grid>
              )}
              {game.turn >= 4 && (
                <Grid item xs={12} sm={6} md={4} className="__item">
                  <Paper elevation={3} className="__turnBlock">
                    <div className="__title">3ターン目 / {AGES[2]}</div>
                    <div className="__body">
                      <div className="__cardTitle __bgJob">仕事カード</div>
                      <div className="__cardName">
                        {getCardName(game.cardJob![2])}
                      </div>
                    </div>
                    <div className="__body">
                      <div className="__cardTitle __bgPri">
                        プライベートカード
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate3![0])}
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate3![1])}
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate3![2])}
                      </div>
                    </div>
                    <div className="__body">
                      <div className="__cardTitle __bgHap">
                        ハプニングカード
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardHappening![2])}
                      </div>
                    </div>
                  </Paper>
                </Grid>
              )}
              {game.turn >= 5 && (
                <Grid item xs={12} sm={6} md={4} className="__item">
                  <Paper elevation={3} className="__turnBlock">
                    <div className="__title">4ターン目 / {AGES[3]}</div>
                    <div className="__body">
                      <div className="__cardTitle __bgJob">仕事カード</div>
                      <div className="__cardName">
                        {getCardName(game.cardJob![3])}
                      </div>
                    </div>
                    <div className="__body">
                      <div className="__cardTitle __bgPri">
                        プライベートカード
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate4![0])}
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate4![1])}
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate4![2])}
                      </div>
                    </div>
                    <div className="__body">
                      <div className="__cardTitle __bgHap">
                        ハプニングカード
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardHappening![3])}
                      </div>
                    </div>
                    <div className="__body">
                      <div className="__cardTitle __bgSoc">社会変化カード</div>
                      <div className="__cardName">
                        {getCardName(game.cardSocialChange![1])}
                      </div>
                    </div>
                  </Paper>
                </Grid>
              )}
              {game.turn >= 6 && (
                <Grid item xs={12} sm={6} md={4} className="__item">
                  <Paper elevation={3} className="__turnBlock">
                    <div className="__title">5ターン目 / {AGES[4]}</div>
                    <div className="__body">
                      <div className="__cardTitle __bgJob">仕事カード</div>
                      <div className="__cardName">
                        {getCardName(game.cardJob![4])}
                      </div>
                    </div>
                    <div className="__body">
                      <div className="__cardTitle __bgPri">
                        プライベートカード
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate5![0])}
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate5![1])}
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate5![2])}
                      </div>
                    </div>
                    <div className="__body">
                      <div className="__cardTitle __bgHap">
                        ハプニングカード
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardHappening![4])}
                      </div>
                    </div>
                  </Paper>
                </Grid>
              )}
              {game.turn >= 6 && game.turnScene === 'result' && (
                <Grid item xs={12} sm={6} md={4} className="__item">
                  <Paper elevation={3} className="__turnBlock">
                    <div className="__title">6ターン目 / {AGES[5]}</div>
                    <div className="__body">
                      <div className="__cardTitle __bgJob">仕事カード</div>
                      <div className="__cardName">
                        {getCardName(game.cardJob![5])}
                      </div>
                    </div>
                    <div className="__body">
                      <div className="__cardTitle __bgPri">
                        プライベートカード
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate6![0])}
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate6![1])}
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardPrivate6![2])}
                      </div>
                    </div>
                    <div className="__body">
                      <div className="__cardTitle __bgHap">
                        ハプニングカード
                      </div>
                      <div className="__cardName">
                        {getCardName(game.cardHappening![5])}
                      </div>
                    </div>
                    <div className="__body">
                      <div className="__cardTitle __bgSoc">社会変化カード</div>
                      <div className="__cardName">
                        {getCardName(game.cardSocialChange![2])}
                      </div>
                    </div>
                  </Paper>
                </Grid>
              )}
            </Grid>

            <div className={classes.buttonsBox}>
              <div className="__buttonHistory">
                <TransparentButton
                  color="black"
                  padding={14}
                  onClick={handleCloseCardHistory}
                >
                  戻る
                </TransparentButton>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default LinkModal;
