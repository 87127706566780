import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Modal,
  Backdrop,
  Fade,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  BIG_CARD_WIDTH,
  CARDS_HAP_LIST,
  CARDS_JOB,
  CARDS_LIF,
  CARDS_PRI,
  CARDS_SOC,
  CARD_SHADOW,
  collectionName,
  MARGIN_S,
  PAGE_HOME,
  PATH_HAP,
  PATH_JOB,
  PATH_LIF,
  PATH_PRI,
  PATH_SOC,
} from '../../consts';
import TransparentButton from '../Game/components/TransparentButton';
import { db } from '../../firebase';
import { FirebaseContext } from '../../contexts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 2% 60px',
    },
    cardsBlock: {
      marginTop: MARGIN_S,
    },
    cardBox: {
      '& img': {
        cursor: 'pointer',
        width: '100%',
        // border: `solid 0.1px rgba(0, 0, 0, 0.1)`, // shadowに変更
        boxShadow: CARD_SHADOW,
        [theme.breakpoints.down('xs')]: {
          borderRadius: 5,
        },
        [theme.breakpoints.up('sm')]: {
          borderRadius: 10,
        },
      },
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      '& .__paper': {
        outline: 0,
        width: '95%',
        maxWidth: BIG_CARD_WIDTH,
        maxHeight: '95%', // この設定によりoverflowが有効となる。
        overflow: 'auto',
        '& .__imgBox': {
          marginBottom: 10,
          '& .__img': {
            margin: 0,
            padding: 0,
            width: '100%',
            [theme.breakpoints.down('xs')]: {
              borderRadius: 10,
            },
            [theme.breakpoints.up('sm')]: {
              borderRadius: 15,
            },
          },
        },
        '& .__buttonsBox': {
          display: 'flex',
          '& .__button1': {
            flex: 1,
          },
        },
      },
    },
  })
);

const cardsArr = [
  [...CARDS_SOC],
  [...CARDS_LIF],
  [...CARDS_JOB],
  [...CARDS_PRI],
  [...CARDS_HAP_LIST],
];

const CardsList: React.FC = () => {
  const { user } = useContext(FirebaseContext);
  const classes = useStyles();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [card, setCard] = useState('');
  const [isManager, setIsManager] = useState<boolean>();

  const path =
    card.split('-')[0] === 'l'
      ? PATH_LIF
      : card.split('-')[0] === 'j'
      ? PATH_JOB
      : card.split('-')[0] === 'p'
      ? PATH_PRI
      : card.split('-')[0] === 'h'
      ? PATH_HAP
      : card.split('-')[0] === 's'
      ? PATH_SOC
      : '';

  const handleOpen = (card: string) => {
    setCard(card);
    setOpen(true);
  };

  const handleClose = () => {
    setCard('');
    setOpen(false);
  };

  useEffect(() => {
    if (user?.email && user.emailVerified) {
      db.collection(collectionName.manager)
        .where('email', '==', user.email)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length) {
            setIsManager(true);
          } else {
            setIsManager(false);
          }
        })
        .catch((error) => {
          alert(`Error getting documents: ${error}`);
        });
    }
    if (isManager === false) {
      history.push(PAGE_HOME);
    }
  }, [history, isManager, user]);

  if (!isManager) {
    return <></>;
  }

  return (
    <>
      {cardsArr.map((cards, index) => {
        const path =
          index === 0
            ? PATH_SOC
            : index === 1
            ? PATH_LIF
            : index === 2
            ? PATH_JOB
            : index === 3
            ? PATH_PRI
            : index === 4
            ? PATH_HAP
            : '';

        return (
          <div key={index} className={classes.root}>
            <Grid
              container
              className={classes.cardsBlock}
              spacing={isXsDown ? 2 : 4}
            >
              {cards.map((c) => {
                return (
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    md={2}
                    key={c}
                    className={classes.cardBox}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}${path}${c}.svg`}
                      alt={c}
                      onClick={() => handleOpen(c)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        );
      })}

      <Modal
        aria-labelledby="card-modal"
        aria-describedby="card-modal-for-expand"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="__paper">
            <div className="__imgBox">
              <img
                className="__img"
                src={`${process.env.PUBLIC_URL}${path}${card}.svg`}
                alt={card}
              />
            </div>
            <div className="__buttonsBox">
              <div className="__button1">
                <TransparentButton onClick={handleClose}>
                  閉じる
                </TransparentButton>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default CardsList;
