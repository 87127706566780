import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { pattern } from '../GroupActive';

type Props = {
  format: string;
  setFormat: React.Dispatch<React.SetStateAction<string>>;
  pattern: typeof pattern;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 110,
      [theme.breakpoints.down('xs')]: {
        margin: '5px 15px',
      },
      [theme.breakpoints.up('sm')]: {
        margin: 15,
      },
    },
    menu: {
      fontSize: 14,
    },
  })
);

const GroupGameList: React.FC<Props> = ({ format, setFormat, pattern }) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFormat(event.target.value as string);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          labelId="format-label"
          id="format-id"
          value={format}
          onChange={handleChange}
          label="Format"
          className={classes.menu}
        >
          <MenuItem value={pattern.tableList} className={classes.menu}>
            リスト
          </MenuItem>
          <MenuItem value={pattern.simple} className={classes.menu}>
            シンプル
          </MenuItem>
          <MenuItem value={pattern.graph} className={classes.menu}>
            折れ線グラフ
          </MenuItem>
          <MenuItem value={pattern.score} className={classes.menu}>
            スコア
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default GroupGameList;
