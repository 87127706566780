import React, { useContext, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { COLOR_BLACK } from '../../consts';
import { FirebaseContext } from '../../contexts';
import { logoutCheckAnonymous } from '../../firebase';

const MenuButtonLogout: React.FC = () => {
  const { user } = useContext(FirebaseContext);

  const handleClickLogout = useCallback(
    async (event) => {
      event.preventDefault();

      if (user) {
        logoutCheckAnonymous(user);
      } else {
        alert('Error: ログイン状態が無効です。');
      }
    },
    [user]
  );

  return (
    <div>
      <Button
        variant="text"
        size="small"
        fullWidth={false}
        style={{
          color: COLOR_BLACK,
          borderRadius: '3px',
          fontSize: 13,
        }}
        onClick={handleClickLogout}
      >
        ログアウト
      </Button>
    </div>
  );
};

export default MenuButtonLogout;
