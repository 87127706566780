import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { MARGIN_L, MARGIN_M, MARGIN_S, MARGIN_XS } from '../../consts';

type Props = {
  children?: React.ReactNode;
  marginTop?: 'L' | 'M' | 'XS' | 'min' | 'none';
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start', // iPhone Safariでの画像の縦伸び抑止
    },
  })
);

const CenterDiv: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        marginTop:
          props.marginTop === 'L'
            ? MARGIN_L
            : props.marginTop === 'M'
            ? MARGIN_M
            : props.marginTop === 'XS'
            ? MARGIN_XS
            : props.marginTop === 'min'
            ? 8
            : props.marginTop === 'none'
            ? 0
            : MARGIN_S,
      }}
    >
      {props.children}
    </div>
  );
};

export default CenterDiv;
