import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Hidden } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  COLOR_DARK_GRAY,
  COLOR_WHITE,
  MARGIN_S,
  standardScore,
  getRank,
} from '../../../consts';
import { ActiveGames } from '../../../models';
import ActiveScores from './ScoreLineChart/ActiveScores';
import { readFinishedScores } from '../../../firebase';

type Props = {
  game: ActiveGames;
  activeScores: number[];
  previousScores?: number[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: MARGIN_S,
      [theme.breakpoints.down('xs')]: {
        padding: '0 0 35px',
        borderRadius: 5,
      },
      [theme.breakpoints.up('sm')]: {
        padding: '0 2% 2%',
        borderRadius: 10,
      },
    },
    paperTitle: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 20,
      '& h6': {
        fontSize: 17,
        fontWeight: 'bold',
        color: COLOR_WHITE,
        backgroundColor: COLOR_DARK_GRAY,
        padding: '4px 24px',
        borderRadius: '0 0 10px 10px',
      },
    },
    tableGrid: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 30,
      },
    },
    scoresWrapper: {
      width: '100%',
    },
    scoresHead: {
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 'bold',
      '& span': {
        fontSize: 12,
        [theme.breakpoints.up('sm')]: {
          marginRight: 18,
        },
      },
    },
    scoresTableWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    countsGoalGrid: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 15,
      paddingRight: 15,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 32,
        paddingRight: 24,
      },
    },
    countsGoalWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 460,
    },
    countsGoal: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 14,
      padding: '3px 10px',
      borderRadius: 4,
    },
  })
);

const goals = [
  'お金を稼ぎ豊かな人生を送る',
  '特定の分野を極めてスペシャリストになる',
  '多くの人と出会いつながりを持つ',
  '自分の時間を確保しゆったりとした人生を送る',
  '健康第一で生涯心身ともに元気な生活を送る',
  '組織に属して働き続け豊かな老後を送る',
  '経験を重ねて人生の終盤で大きなことを成す',
  '自ら起業しこの世に無い新たな価値を生み出す',
  '部下や同僚に慕われ多くの人を率いる',
  '組織で働くことに拘らず自由な生き方をする',
  '様々なステージを渡り歩く人生を送る',
  'プライベートを最も大切にする',
];

const CompareResult: React.FC<Props> = ({
  game,
  activeScores,
  previousScores,
}) => {
  const classes = useStyles();

  const [countsGoal, setCountsGoal] = useState<number[]>();
  const [rank, setRank] = useState<number>();
  const [itemsRank, setItemsRank] = useState<number[]>();
  const [countFinished, setCountFinished] = useState<number>();
  const [sSResult, setSSResult] = useState<number>();
  const [sSMoney, setSSMoney] = useState<number>();
  const [sSHealth, setSSHealth] = useState<number>();
  const [sSPerformance, setSSPerformance] = useState<number>();
  const [sSManagement, setSSManagement] = useState<number>();
  const [sSNetwork, setSSNetwork] = useState<number>();
  const [sSLeisure, setSSLeisure] = useState<number>();

  const resultScore = game.options.resultScore;
  const goalIndex = Number(game.lifeGoal?.slice(-2)) - 1;

  useEffect(() => {
    const readData = async () => {
      const docData = await readFinishedScores();
      if (docData) {
        setCountsGoal(docData.countsGoal);
        setRank(getRank(docData.scoresResult, resultScore));
        setItemsRank([
          getRank(docData.scoresMoney, activeScores[0]),
          getRank(docData.scoresHealth, activeScores[1]),
          getRank(docData.scoresPerformance, activeScores[2]),
          getRank(docData.scoresManagement, activeScores[3]),
          getRank(docData.scoresNetwork, activeScores[4]),
          getRank(docData.scoresLeisure, activeScores[5]),
        ]);
        setCountFinished(
          docData.countsGoal.reduce(
            (prev: number, current: number) => prev + current,
            1 // 自身を加えるため初期値は1
          )
        );
        setSSResult(standardScore([...docData.scoresResult, resultScore]));
        setSSMoney(standardScore([...docData.scoresMoney, activeScores[0]]));
        setSSHealth(standardScore([...docData.scoresHealth, activeScores[1]]));
        setSSPerformance(
          standardScore([...docData.scoresPerformance, activeScores[2]])
        );
        setSSManagement(
          standardScore([...docData.scoresManagement, activeScores[3]])
        );
        setSSNetwork(
          standardScore([...docData.scoresNetwork, activeScores[4]])
        );
        setSSLeisure(
          standardScore([...docData.scoresLeisure, activeScores[5]])
        );
      }
    };
    readData();
  }, [activeScores, resultScore]);

  return (
    <>
      <Paper elevation={3} className={classes.paper}>
        <div className={classes.paperTitle}>
          <Typography variant="h6">他プレイヤーとの比較</Typography>
        </div>
        <Grid container justify="center">
          {countsGoal &&
            rank &&
            countFinished &&
            sSResult &&
            sSMoney &&
            sSHealth &&
            sSPerformance &&
            sSManagement &&
            sSNetwork &&
            sSLeisure && (
              <>
                <Grid item xs={12} md={6} className={classes.tableGrid}>
                  <div className={classes.scoresWrapper}>
                    <div
                      className={classes.scoresHead}
                    >{`あなたの最終スコア：${resultScore}`}</div>
                    <div
                      className={classes.scoresHead}
                      style={{
                        marginBottom: 14,
                      }}
                    >
                      {`順位 ${rank}位`}
                      <span>{`（プレイ人数 ${countFinished}人）`}</span>
                      <Hidden smUp>
                        <br />
                      </Hidden>
                      {`偏差値 ${sSResult}`}
                    </div>
                    <div className={classes.scoresTableWrapper}>
                      <ActiveScores
                        game={game}
                        activeScores={activeScores}
                        previousScores={previousScores}
                        isStandardScore
                        standardScores={[
                          sSMoney,
                          sSHealth,
                          sSPerformance,
                          sSManagement,
                          sSNetwork,
                          sSLeisure,
                        ]}
                        itemsRank={itemsRank}
                      />
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className={classes.countsGoalGrid}>
                  <div className={classes.countsGoalWrapper}>
                    {goals.map((goal, index) => (
                      <div
                        key={`${goal}-${index}`}
                        className={classes.countsGoal}
                        style={{
                          backgroundColor:
                            index === goalIndex ? '#DDE3F2' : undefined,
                        }}
                      >
                        <div>{goal}</div>
                        <div
                          style={{ whiteSpace: 'nowrap', paddingLeft: 10 }}
                        >{`${
                          index === goalIndex
                            ? countsGoal[index] + 1 // 人数に自身を追加
                            : countsGoal[index]
                        }人`}</div>
                      </div>
                    ))}
                  </div>
                </Grid>
              </>
            )}
        </Grid>
      </Paper>
    </>
  );
};

export default CompareResult;
