import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { ActiveGames } from '../../../../models';
import LinkModal from './LinkModal';
import { getAverage5Scores } from '../../../../functions';
import { COLOR_FAILURE, COLOR_SUCCESS, PARAM_COLORS } from '../../../../consts';

type Props = {
  game: ActiveGames;
  activeScores: number[];
  previousScores?: number[];
  isStandardScore?: boolean;
  standardScores?: number[];
  itemsRank?: number[];
};

const roundScore = (score: number) => {
  const roundNum = Math.round(score * 10) / 10;
  if (roundNum > 0) {
    return '+' + roundNum;
  } else {
    return String(roundNum);
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableWrapper: {
      width: '90%',
      maxWidth: '400px',
      '& .__iconWithText': {
        position: 'relative',
        top: 4,
        left: -5,
        fontSize: 19,
      },
      '& .__money': {
        color: PARAM_COLORS.money,
      },
      '& .__health': {
        color: PARAM_COLORS.health,
      },
      '& .__performance': {
        color: PARAM_COLORS.performance,
      },
      '& .__management': {
        color: PARAM_COLORS.management,
      },
      '& .__network': {
        color: PARAM_COLORS.network,
      },
      '& .__leisure': {
        color: PARAM_COLORS.leisure,
      },
    },
    tableCellHead: {
      fontWeight: 'bold',
    },
  })
);

const StyledTableCell = (props: {
  children: React.ReactNode;
  className?: string;
  align?: 'right';
  component?: 'th';
  scope?: 'row';
  pl?: number;
  pr?: number;
  style?: React.CSSProperties;
}) => {
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <TableCell
      className={props.className}
      align={props.align}
      component={props.component}
      scope={props.scope}
      style={{
        paddingLeft: isXsDown ? props.pl ?? 12 : undefined,
        paddingRight: isXsDown ? props.pr ?? 12 : undefined,
        ...props.style,
      }}
    >
      {props.children}
    </TableCell>
  );
};

const ActiveScores: React.FC<Props> = ({
  game,
  activeScores,
  previousScores,
  isStandardScore,
  standardScores,
  itemsRank,
}) => {
  const classes = useStyles();
  const riseFallMoney = previousScores
    ? roundScore(activeScores[0] - previousScores![0])
    : '';
  const riseFallHealth = previousScores
    ? roundScore(activeScores[1] - previousScores![1])
    : '';
  const riseFallPerformance = previousScores
    ? roundScore(activeScores[2] - previousScores![2])
    : '';
  const riseFallManagement = previousScores
    ? roundScore(activeScores[3] - previousScores![3])
    : '';
  const riseFallNetwork = previousScores
    ? roundScore(activeScores[4] - previousScores![4])
    : '';
  const riseFallLeisure = previousScores
    ? roundScore(activeScores[5] - previousScores![5])
    : '';
  const riseFallAverage = previousScores
    ? roundScore(
        getAverage5Scores(activeScores) - getAverage5Scores(previousScores!)
      )
    : '';

  return (
    <div className={classes.tableWrapper}>
      <TableContainer>
        {!isStandardScore && <LinkModal game={game} />}
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.tableCellHead}>
                項目
              </StyledTableCell>
              {!isStandardScore && previousScores && game.turn > 1 && (
                <StyledTableCell
                  align="right"
                  className={classes.tableCellHead}
                >
                  増減
                </StyledTableCell>
              )}
              <StyledTableCell align="right" className={classes.tableCellHead}>
                値
              </StyledTableCell>
              {isStandardScore && standardScores && itemsRank && (
                <>
                  <StyledTableCell
                    align="right"
                    className={classes.tableCellHead}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    順位
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    className={classes.tableCellHead}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    偏差値
                  </StyledTableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell component="th" scope="row">
                <FiberManualRecordIcon className="__iconWithText __money" />
                お金
              </StyledTableCell>
              {!isStandardScore && previousScores && game.turn > 1 && (
                <StyledTableCell
                  align="right"
                  style={{
                    color:
                      riseFallMoney.slice(0, 1) === '+'
                        ? COLOR_SUCCESS
                        : riseFallMoney.slice(0, 1) === '-'
                        ? COLOR_FAILURE
                        : undefined,
                  }}
                >
                  {riseFallMoney}
                </StyledTableCell>
              )}
              <StyledTableCell align="right">{activeScores[0]}</StyledTableCell>
              {isStandardScore && standardScores && itemsRank && (
                <>
                  <StyledTableCell align="right">{`${itemsRank[0]}位`}</StyledTableCell>
                  <StyledTableCell align="right">
                    {standardScores[0]}
                  </StyledTableCell>
                </>
              )}
            </TableRow>
            <TableRow>
              <StyledTableCell component="th" scope="row">
                <FiberManualRecordIcon className="__iconWithText __health" />
                健康
              </StyledTableCell>
              {!isStandardScore && previousScores && game.turn > 1 && (
                <StyledTableCell
                  align="right"
                  style={{
                    color:
                      riseFallHealth.slice(0, 1) === '+'
                        ? COLOR_SUCCESS
                        : riseFallHealth.slice(0, 1) === '-'
                        ? COLOR_FAILURE
                        : undefined,
                  }}
                >
                  {riseFallHealth}
                </StyledTableCell>
              )}
              <StyledTableCell align="right">{activeScores[1]}</StyledTableCell>
              {isStandardScore && standardScores && itemsRank && (
                <>
                  <StyledTableCell align="right">{`${itemsRank[1]}位`}</StyledTableCell>
                  <StyledTableCell align="right">
                    {standardScores[1]}
                  </StyledTableCell>
                </>
              )}
            </TableRow>
            <TableRow>
              <StyledTableCell component="th" scope="row">
                <FiberManualRecordIcon className="__iconWithText __performance" />
                業務遂行能力
              </StyledTableCell>
              {!isStandardScore && previousScores && game.turn > 1 && (
                <StyledTableCell
                  align="right"
                  style={{
                    color:
                      riseFallPerformance.slice(0, 1) === '+'
                        ? COLOR_SUCCESS
                        : riseFallPerformance.slice(0, 1) === '-'
                        ? COLOR_FAILURE
                        : undefined,
                  }}
                >
                  {riseFallPerformance}
                </StyledTableCell>
              )}
              <StyledTableCell align="right">{activeScores[2]}</StyledTableCell>
              {isStandardScore && standardScores && itemsRank && (
                <>
                  <StyledTableCell align="right">{`${itemsRank[2]}位`}</StyledTableCell>
                  <StyledTableCell align="right">
                    {standardScores[2]}
                  </StyledTableCell>
                </>
              )}
            </TableRow>
            <TableRow>
              <StyledTableCell component="th" scope="row">
                <FiberManualRecordIcon className="__iconWithText __management" />
                マネジメント能力
              </StyledTableCell>
              {!isStandardScore && previousScores && game.turn > 1 && (
                <StyledTableCell
                  align="right"
                  style={{
                    color:
                      riseFallManagement.slice(0, 1) === '+'
                        ? COLOR_SUCCESS
                        : riseFallManagement.slice(0, 1) === '-'
                        ? COLOR_FAILURE
                        : undefined,
                  }}
                >
                  {riseFallManagement}
                </StyledTableCell>
              )}
              <StyledTableCell align="right">{activeScores[3]}</StyledTableCell>
              {isStandardScore && standardScores && itemsRank && (
                <>
                  <StyledTableCell align="right">{`${itemsRank[3]}位`}</StyledTableCell>
                  <StyledTableCell align="right">
                    {standardScores[3]}
                  </StyledTableCell>
                </>
              )}
            </TableRow>
            <TableRow>
              <StyledTableCell component="th" scope="row">
                <FiberManualRecordIcon className="__iconWithText __network" />
                人脈・評判
              </StyledTableCell>
              {!isStandardScore && previousScores && game.turn > 1 && (
                <StyledTableCell
                  align="right"
                  style={{
                    color:
                      riseFallNetwork.slice(0, 1) === '+'
                        ? COLOR_SUCCESS
                        : riseFallNetwork.slice(0, 1) === '-'
                        ? COLOR_FAILURE
                        : undefined,
                  }}
                >
                  {riseFallNetwork}
                </StyledTableCell>
              )}
              <StyledTableCell align="right">{activeScores[4]}</StyledTableCell>
              {isStandardScore && standardScores && itemsRank && (
                <>
                  <StyledTableCell align="right">{`${itemsRank[4]}位`}</StyledTableCell>
                  <StyledTableCell align="right">
                    {standardScores[4]}
                  </StyledTableCell>
                </>
              )}
            </TableRow>
            <TableRow>
              <StyledTableCell component="th" scope="row">
                <FiberManualRecordIcon className="__iconWithText __leisure" />
                自由な時間
              </StyledTableCell>
              {!isStandardScore && previousScores && game.turn > 1 && (
                <StyledTableCell
                  align="right"
                  style={{
                    color:
                      riseFallLeisure.slice(0, 1) === '+'
                        ? COLOR_SUCCESS
                        : riseFallLeisure.slice(0, 1) === '-'
                        ? COLOR_FAILURE
                        : undefined,
                  }}
                >
                  {riseFallLeisure}
                </StyledTableCell>
              )}
              <StyledTableCell align="right">{activeScores[5]}</StyledTableCell>
              {isStandardScore && standardScores && itemsRank && (
                <>
                  <StyledTableCell align="right">{`${itemsRank[5]}位`}</StyledTableCell>
                  <StyledTableCell align="right">
                    {standardScores[5]}
                  </StyledTableCell>
                </>
              )}
            </TableRow>
            {!isStandardScore && (
              <TableRow>
                <StyledTableCell component="th" scope="row">
                  【平均値】
                </StyledTableCell>
                {previousScores && game.turn > 1 && (
                  <StyledTableCell
                    align="right"
                    style={{
                      color:
                        riseFallAverage.slice(0, 1) === '+'
                          ? COLOR_SUCCESS
                          : riseFallAverage.slice(0, 1) === '-'
                          ? COLOR_FAILURE
                          : undefined,
                    }}
                  >
                    {riseFallAverage}
                  </StyledTableCell>
                )}
                <StyledTableCell align="right">
                  {getAverage5Scores(activeScores)}
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ActiveScores;
