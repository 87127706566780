import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import BaseContainer from '../../../components/shared/BaseContainer';
import CenterDiv from '../../../components/shared/CenterDiv';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descImg: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '75%',
      },
    },
  })
);

const DescContents: React.FC = () => {
  const classes = useStyles();

  return (
    <BaseContainer title="ゲーム説明" marginTop="L">
      <CenterDiv>
        <Typography variant="h6">1. ゲームの流れ</Typography>
      </CenterDiv>
      <CenterDiv>
        <Typography>
          カードを選択し、より高いスコアを目指すゲームです。
        </Typography>
      </CenterDiv>
      <CenterDiv>
        <img
          alt="desc1"
          src={`${process.env.PUBLIC_URL}/assets/game-desc/desc1.png`}
          className={classes.descImg}
        ></img>
      </CenterDiv>

      <CenterDiv marginTop="L">
        <Typography variant="h6">2. 目標選択</Typography>
      </CenterDiv>
      <CenterDiv>
        <Typography>
          複数の目標の中で気に入ったものを選びましょう（選んだカードが、ゲーム内でのあなたの目標になります）。
        </Typography>
      </CenterDiv>
      <CenterDiv>
        <img
          alt="desc2"
          src={`${process.env.PUBLIC_URL}/assets/game-desc/desc2.jpg`}
          className={classes.descImg}
        ></img>
      </CenterDiv>

      <CenterDiv marginTop="L">
        <Typography variant="h6">3. カードの種類とカード選択</Typography>
      </CenterDiv>
      <CenterDiv>
        <Typography>
          選択できるカードの種類は2種類。仕事カード1枚、プライベートは3枚まで選択できます。
        </Typography>
      </CenterDiv>
      <CenterDiv>
        <img
          alt="desc3"
          src={`${process.env.PUBLIC_URL}/assets/game-desc/desc3.png`}
          className={classes.descImg}
        ></img>
      </CenterDiv>

      <CenterDiv marginTop="L">
        <Typography variant="h6">4. カードの見方</Typography>
      </CenterDiv>
      <CenterDiv>
        <Typography>選ぶカードによって効果が異なります。</Typography>
      </CenterDiv>
      <CenterDiv>
        <img
          alt="desc4"
          src={`${process.env.PUBLIC_URL}/assets/game-desc/desc4.png`}
          className={classes.descImg}
        ></img>
      </CenterDiv>

      <CenterDiv marginTop="L">
        <Typography variant="h6">
          5. 社会変化カード・ハプニングカード
        </Typography>
      </CenterDiv>
      <CenterDiv>
        <Typography>
          毎ターンランダムで、ハプニングが起きます。また、2ターンに一度社会変化が起こります。社会変化は、ゲームになんらかの変化をもたらします。
        </Typography>
      </CenterDiv>
      <CenterDiv>
        <img
          alt="desc5"
          src={`${process.env.PUBLIC_URL}/assets/game-desc/desc5.png`}
          className={classes.descImg}
        ></img>
      </CenterDiv>

      <CenterDiv marginTop="L">
        <Typography variant="h6">6. パラメーター</Typography>
      </CenterDiv>
      <CenterDiv>
        <Typography>
          パラメーターは、カード選択などに影響されて変化します。以下の6つのスコアの合計が人生の充実度を表しています。
        </Typography>
      </CenterDiv>
      <CenterDiv>
        <img
          alt="desc6"
          src={`${process.env.PUBLIC_URL}/assets/game-desc/desc6.png`}
          className={classes.descImg}
        ></img>
      </CenterDiv>

      <CenterDiv marginTop="L">
        <Typography variant="h6">7. 最終スコア</Typography>
      </CenterDiv>
      <CenterDiv>
        <Typography>
          最終スコアは以下の3つのスコアを合計したものになります。
        </Typography>
      </CenterDiv>
      <CenterDiv>
        <img
          alt="desc7"
          src={`${process.env.PUBLIC_URL}/assets/game-desc/desc7.png`}
          className={classes.descImg}
        ></img>
      </CenterDiv>

      <CenterDiv marginTop="L">
        <Typography variant="h6">8. 影響しあうパラメーター</Typography>
      </CenterDiv>
      <CenterDiv>
        <Typography>
          パラメーターとカードが影響し合い、下記のようなことが起こり得ます。影響する要素は、「影響するパラメーター」や「影響を受けるもの」という項目でカードに記載されています。
        </Typography>
      </CenterDiv>
      <CenterDiv>
        <img
          alt="desc8"
          src={`${process.env.PUBLIC_URL}/assets/game-desc/desc8.png`}
          className={classes.descImg}
        ></img>
      </CenterDiv>

      <CenterDiv marginTop="L">
        <Typography variant="h6">9. パラメーターに影響を与える要因</Typography>
      </CenterDiv>
      <CenterDiv>
        <Typography>
          パラメーターは、カード選択以外の要因にも影響を受けます。
        </Typography>
      </CenterDiv>
      <CenterDiv>
        <img
          alt="desc9"
          src={`${process.env.PUBLIC_URL}/assets/game-desc/desc9.png`}
          className={classes.descImg}
        ></img>
      </CenterDiv>

      <CenterDiv marginTop="L">
        <Typography variant="h6">10. 推奨されるゲームの仕方</Typography>
      </CenterDiv>
      <CenterDiv>
        <Typography>
          複数名で、ビデオ通話などによるコミュニケーションを取りながらゲームを行うことを推奨します。
          <br />
          カードを選んだ理由や、それぞれのパラメーター変化の傾向などを共有しながら進めると、
          より楽しく学びの深いものとなるでしょう。
        </Typography>
      </CenterDiv>
      <CenterDiv>
        <img
          alt="desc10"
          src={`${process.env.PUBLIC_URL}/assets/game-desc/desc10.png`}
          className={classes.descImg}
        ></img>
      </CenterDiv>
    </BaseContainer>
  );
};

export default DescContents;
