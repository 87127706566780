import React from 'react';
import { Typography, Grid, Hidden } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { BODY_PADDING, MARGIN_XL, MARGIN_M, MARGIN_L } from '../../consts';

type Props = {
  // React TypeScript で Propsに HTMLタグを入れる方法
  // https://qiita.com/hiropy0123/items/e94a9c784e1d192145cd
  title: string | Array<string | JSX.Element>;
  marginTop?: 'L';
  marginBottom?: 'L';
  children?: React.ReactNode;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: MARGIN_M,
      padding: BODY_PADDING,
    },
    rootMarginL: {
      marginTop: MARGIN_XL,
      padding: BODY_PADDING,
    },
    title: {
      marginBottom: MARGIN_M,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  })
);

const BaseContainer: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div
      className={props.marginTop ? classes.rootMarginL : classes.root}
      style={{
        marginBottom: props.marginBottom === 'L' ? MARGIN_L : undefined,
      }}
    >
      <Grid container>
        <Hidden smDown>
          <Grid item md={2}></Grid>
        </Hidden>
        <Grid item xs={12} md>
          <Typography variant="h5" className={classes.title}>
            {props.title}
          </Typography>
          {props.children}
        </Grid>
        <Hidden smDown>
          <Grid item md={2}></Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default BaseContainer;
