import React from 'react';
import { COLOR_BLACK } from '../../consts';
import BasicButton, { CustomProps } from './atoms/BasicButton';

type Props = CustomProps;

const MenuButton: React.FC<Props> = (props) => {
  return (
    <div>
      <BasicButton
        variant="text"
        square={true}
        fontColor={COLOR_BLACK}
        bold="normal"
        size="small"
        fullWidth={false}
        {...props}
      >
        {props.children}
      </BasicButton>
    </div>
  );
};

export default MenuButton;
