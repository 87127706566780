import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import BaseContainer from '../../components/shared/BaseContainer';
import BlackButton from '../../components/shared/BlackButton';
import CenterDiv from '../../components/shared/CenterDiv';
import MaxWidthAdjust from '../../components/shared/MaxWidthAdjust';
import {
  COLOR_GHOST_WHITE,
  COLOR_LIGHT_GRAY,
  COLOR_LINK,
  COLOR_MAIN,
  COLOR_WARNING,
  FONT_SIZE_M,
  MARGIN_S,
  MARGIN_XS,
  PAGE_CANCEL,
} from '../../consts';
import { Link } from 'react-router-dom';

const faqs = [
  {
    id: 1,
    question: '匿名でのログインとはどのような機能でしょうか？',
    answer:
      'ご利用環境など、何かしらの理由により新規登録できないユーザーの方に向けたログイン機能です。\nニックネームやメールアドレスを登録することなく、ログインできます。\nただし、ご自身でプレイした過去のゲーム履歴を見ることができまませんので、支障がなければ通常の新規登録・ログインをお勧めします。',
  },
  {
    id: 2,
    question: '退会処理の方法を教えてください。',
    answer: 'ログインした状態で下記リンクを開くとお手続きいただけます。',
  },
  // {
  //   id: ●,
  //   question: '',
  //   answer: '',
  // },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    faq: {
      marginTop: MARGIN_S,
    },
    questionWrap: {
      display: 'flex',
      padding: '7px 10px',
      backgroundColor: COLOR_GHOST_WHITE,
      borderRadius: '10px 10px 0 0',
      border: '1px solid ' + COLOR_LIGHT_GRAY,
    },
    answerWrap: {
      display: 'flex',
      padding: '7px 10px',
      borderRadius: '0 0 10px 10px',
      border: '1px solid ' + COLOR_LIGHT_GRAY,
      borderTop: 'none',
    },
    contentSub: {
      color: COLOR_MAIN,
      fontWeight: 'bold',
      fontSize: 16,
      marginRight: '0.6rem',
    },
    text: {
      // padding: BODY_PADDING,
      fontSize: FONT_SIZE_M,
      whiteSpace: 'pre-wrap', // \nによる改行を有効化
      textAlign: 'justify',
    },
    textWarning: {
      // padding: BODY_PADDING,
      marginTop: MARGIN_XS,
      fontSize: FONT_SIZE_M,
      color: COLOR_WARNING,
      fontWeight: 'bold',
      whiteSpace: 'pre-wrap', // \nによる改行を有効化
      textAlign: 'justify',
    },
    link: {
      fontSize: FONT_SIZE_M,
      color: COLOR_LINK,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

const Help: React.FC = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BaseContainer title="ヘルプ">
      {faqs.map((faq) => (
        <div key={faq.id} className={classes.faq}>
          <div className={classes.questionWrap}>
            <div className={classes.contentSub}>Q.</div>
            <Typography className={classes.text}>{faq.question}</Typography>
          </div>
          <div className={classes.answerWrap}>
            <div className={classes.contentSub}>A.</div>
            <div>
              <Typography className={classes.text}>{faq.answer}</Typography>
              {faq.id === 2 && (
                <>
                  <div>
                    <Link to={PAGE_CANCEL}>
                      <span className={classes.link}>退会処理</span>
                    </Link>
                  </div>
                  <Typography className={classes.textWarning}>
                    匿名でのログインの場合、退会処理は不要ですので、ログアウトをして終了してください。
                  </Typography>
                </>
              )}
            </div>
          </div>
        </div>
      ))}

      <CenterDiv marginTop="M">
        <MaxWidthAdjust>
          <BlackButton onClick="toHome">ホームに戻る</BlackButton>
        </MaxWidthAdjust>
      </CenterDiv>
    </BaseContainer>
  );
};

export default Help;
