import React from 'react';
import { CircularProgress } from '@material-ui/core';

const Loading: React.FC = () => {
  return (
    <div
      style={{
        marginTop: 30,
        textAlign: 'center',
      }}
    >
      <CircularProgress size={30} />
    </div>
  );
};

export default Loading;
