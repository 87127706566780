import React, { useState, useEffect, useContext } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import BaseContainer from '../../components/shared/BaseContainer';
import BlackButton from '../../components/shared/BlackButton';
import CenterDiv from '../../components/shared/CenterDiv';
import MaxWidthAdjust from '../../components/shared/MaxWidthAdjust';
import {
  collectionName,
  COLOR_LINK,
  PAGE_MYPAGE_GAMEHISTORY,
  PAGE_MYPAGE_MANAGER,
  PAGE_MYPAGE_USERINFO,
} from '../../consts';
import { FirebaseContext } from '../../contexts';
import { db } from '../../firebase';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: COLOR_LINK,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

const MyPage: React.FC = () => {
  const { user } = useContext(FirebaseContext);
  const classes = useStyles();

  const [isManager, setIsManager] = useState<boolean>();

  useEffect(() => {
    if (user?.email && user.emailVerified) {
      db.collection(collectionName.manager)
        .where('email', '==', user.email)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length) {
            setIsManager(true);
          } else {
            setIsManager(false);
          }
        })
        .catch((error) => {
          alert(`Error getting documents: ${error}`);
        });
    }
  }, [isManager, user]);

  return (
    <BaseContainer title="マイページ">
      <CenterDiv>
        <Link to={PAGE_MYPAGE_USERINFO}>
          <Typography className={classes.link}>登録情報</Typography>
        </Link>
      </CenterDiv>
      <CenterDiv>
        <Link to={PAGE_MYPAGE_GAMEHISTORY}>
          <Typography className={classes.link}>ゲーム履歴</Typography>
        </Link>
      </CenterDiv>
      {isManager && (
        <CenterDiv>
          <Link to={PAGE_MYPAGE_MANAGER}>
            <Typography className={classes.link}>管理者</Typography>
          </Link>
        </CenterDiv>
      )}

      <CenterDiv marginTop="M">
        <MaxWidthAdjust>
          <BlackButton onClick="toHome">ホームに戻る</BlackButton>
        </MaxWidthAdjust>
      </CenterDiv>
    </BaseContainer>
  );
};

export default MyPage;
