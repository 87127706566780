import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_DARK_GRAY, COLOR_WHITE, MARGIN_S } from '../../../consts';
import { ActiveGames } from '../../../models';
import ActiveScores from './ScoreLineChart/ActiveScores';
import ColorsLineChart from './ScoreLineChart/ColorsLineChart';

type Props = {
  game: ActiveGames;
  activeScores: number[];
  previousScores?: number[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: MARGIN_S,
      [theme.breakpoints.down('xs')]: {
        padding: '0 0 35px',
        borderRadius: 5,
      },
      [theme.breakpoints.up('sm')]: {
        padding: '0 2% 2%',
        borderRadius: 10,
      },
    },
    paperTitle: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 20,
      '& h6': {
        fontSize: 17,
        fontWeight: 'bold',
        color: COLOR_WHITE,
        backgroundColor: COLOR_DARK_GRAY,
        padding: '4px 24px',
        borderRadius: '0 0 10px 10px',
      },
    },
    tableGrid: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 30,
      },
    },
  })
);

const ScoreLineChart: React.FC<Props> = ({
  game,
  activeScores,
  previousScores,
}) => {
  const classes = useStyles();

  return (
    <>
      <Paper elevation={3} className={classes.paper}>
        <div className={classes.paperTitle}>
          <Typography variant="h6">パラメーター</Typography>
        </div>
        <Grid container>
          <Grid item xs={12} md={6} className={classes.tableGrid}>
            <ActiveScores
              game={game}
              activeScores={activeScores}
              previousScores={previousScores}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ColorsLineChart game={game} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ScoreLineChart;
