import React from 'react';
import { useLocation } from 'react-router-dom';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden, useTheme, useMediaQuery } from '@material-ui/core';
import MenuButton from './MenuButton';
import {
  BODY_PADDING,
  MARGIN_M,
  MARGIN_XL,
  PAGE_PASSRESET,
  PAGE_LOGIN,
  PAGE_REGISTER,
  PAGE_GAME,
  PAGE_GAME_GROUP,
  PAGE_CARDS_LIST,
  PAGE_CARDS,
  PAGE_DESC,
} from '../../consts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: MARGIN_XL,
      marginBottom: MARGIN_XL,
      padding: BODY_PADDING,
      [theme.breakpoints.down('xs')]: {
        marginTop: 40,
      },
    },
    logo: {
      display: 'flex',
      justifyContent: 'center',
      // imgの縦伸び抑止
      // https://mym0978.com/blog/flexbox
      height: 'auto',
      alignItems: 'flex-start',
      '& img': {
        width: '30%',
        maxWidth: '110px',
      },
    },
    menu: {
      marginTop: MARGIN_M,
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
        justifyContent: 'flex-start',
      },
    },
    // ie11のみに適用
    // https://www.softel.co.jp/blogs/tech/archives/6095
    '@media all and (-ms-high-contrast: none)': {
      menu: {
        justifyContent: 'space-around',
      },
    },
    menuItem: {
      flex: 1,
    },
  })
);

const Footer: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  if (
    location.pathname === PAGE_REGISTER ||
    location.pathname === PAGE_LOGIN ||
    location.pathname === PAGE_PASSRESET ||
    location.pathname === PAGE_GAME ||
    location.pathname === PAGE_GAME_GROUP ||
    location.pathname === PAGE_CARDS_LIST ||
    location.pathname === PAGE_CARDS ||
    location.pathname === PAGE_DESC
  ) {
    return <></>;
  }

  const CompanyLink = () => (
    <a
      href="https://new-one.co.jp/company/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <MenuButton onClick="none" fontSize={13}>
        運営会社
      </MenuButton>
    </a>
  );

  const TermsLink = () => (
    <MenuButton onClick="toTerms" fontSize={13}>
      利用規約
    </MenuButton>
  );

  const PrivacyLink = () => (
    <a
      href="https://new-one.co.jp/privacypolicy/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <MenuButton onClick="none" fontSize={13}>
        プライバシーポリシー
      </MenuButton>
    </a>
  );

  const HelpLink = () => (
    <MenuButton onClick="toHelp" fontSize={13}>
      ヘルプ
    </MenuButton>
  );

  return (
    <>
      <footer className={classes.root}>
        <Grid container>
          <Hidden smDown>
            <Grid item md={2}></Grid>
          </Hidden>
          <Grid item xs={12} md>
            <div className={classes.logo}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/svg/newone-logo-v.svg`}
                alt={'newone-logo'}
              />
            </div>
            {isSmDown ? (
              <>
                <div
                  className={classes.menu}
                  style={{
                    marginTop: MARGIN_M,
                  }}
                >
                  <div className={classes.menuItem}>
                    <CompanyLink />
                  </div>
                  <div className={classes.menuItem}>
                    <TermsLink />
                  </div>
                </div>
                <div
                  className={classes.menu}
                  style={{
                    marginTop: 8,
                  }}
                >
                  <div className={classes.menuItem}>
                    <PrivacyLink />
                  </div>
                  <div className={classes.menuItem}>
                    <HelpLink />
                  </div>
                </div>
              </>
            ) : (
              <div className={classes.menu}>
                <CompanyLink />
                <TermsLink />
                <PrivacyLink />
                <HelpLink />
              </div>
            )}
          </Grid>
          <Hidden smDown>
            <Grid item md={2}></Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
};

export default Footer;
