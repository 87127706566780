import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CenterDiv from '../../../components/shared/CenterDiv';
import MainSelectButton from './MainSelectButton';
import MaxWidthAdjust from '../../../components/shared/MaxWidthAdjust';
import {
  COLOR_DARK_GRAY,
  COLOR_DARK_GRAY2,
  MARGIN_M,
  COLOR_WHITE,
  PAGE_HOME,
  MARGIN_S,
  collectionName,
  VALIDATIONS,
} from '../../../consts';
import { Paper, TextField, Typography } from '@material-ui/core';
import TransparentButton from './TransparentButton';
import GameDescription from './GameDescription';
import { readGroupFromGame, startSingleGame } from '../../../firebase';

type Props = {
  collectionGame: string;
  mode: 's' | 'g';
  activeGameId: string;
  playerName: string;
  onGaming: () => Promise<void>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.down('xs')]: {
        padding: '0 5% 7%',
        borderRadius: 5,
      },
      [theme.breakpoints.up('sm')]: {
        padding: '0 2% 4%',
        borderRadius: 10,
      },
    },
    paperTitle: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 20,
      '& h6': {
        fontSize: 17,
        fontWeight: 'bold',
        color: COLOR_WHITE,
        backgroundColor: COLOR_DARK_GRAY,
        padding: '4px 24px',
        borderRadius: '0 0 10px 10px',
      },
    },
    content: {
      fontSize: 18,
      textAlign: 'center',
    },
    contentSub: {
      fontSize: 13,
      color: COLOR_DARK_GRAY2,
    },
    descBlock: {
      marginTop: MARGIN_M,
    },
    idBlock: {
      marginTop: MARGIN_S,
      display: 'flex',
      alignItems: 'center',
    },
    buttonBlock: {
      marginTop: MARGIN_M,
      display: 'flex',
      justifyContent: 'space-between',
      '& .buttonWrap': {
        flex: 1,
        width: '90%',
      },
      '& .leftBtn': {
        marginRight: 7,
      },
      '& .rightBtn': {
        marginLeft: 7,
      },
    },
  })
);

const Preparation: React.FC<Props> = ({
  collectionGame,
  mode,
  activeGameId,
  playerName,
  onGaming,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [name, setName] = useState(playerName);
  const [idInc, setIdInc] = useState<string>('');
  const [idNum, setIdNum] = useState<string>('');
  const [idChar, setIdChar] = useState<string>('');
  const [accessCode, setAccessCode] = useState<string>('');
  // 多重クリック抑止
  // https://www.aizulab.com/blog/react-multiple-click-prevention/
  const processing = useRef(false);

  const nameFormat = {
    maxStr: VALIDATIONS.maxNickname,
  };
  const nameHelperText =
    nameFormat.maxStr < name.length
      ? '24文字以内でご入力ください'
      : '今回のプレイでのみ有効な名前です';
  const nameError = name === '' ? false : nameFormat.maxStr < name.length;

  const handleClickBack = useCallback(
    (event) => {
      event.preventDefault();

      history.push(PAGE_HOME);
    },
    [history]
  );

  const handleClickSingle = useCallback(
    async (event) => {
      event.preventDefault();

      // 多重クリック抑止: 処理中(true)なら非同期処理せずに抜ける
      if (processing.current) return;
      // 多重クリック抑止: 処理中フラグを上げる
      processing.current = true;

      await startSingleGame(collectionGame, activeGameId, name, onGaming);

      // 多重クリック抑止: 処理中フラグを下げる
      processing.current = false;
    },
    [activeGameId, collectionGame, name, onGaming]
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      // 多重クリック抑止: 処理中(true)なら非同期処理せずに抜ける
      if (processing.current) return;
      // 多重クリック抑止: 処理中フラグを上げる
      processing.current = true;

      const { idIncEl, idNumEl, idCharEl, accessCodeEl } =
        event.target.elements;
      const groupId =
        String(idIncEl.value) + String(idNumEl.value) + String(idCharEl.value);
      const accessCode = accessCodeEl.value;
      await readGroupFromGame(
        collectionName.gameGroups,
        collectionGame,
        groupId,
        accessCode,
        activeGameId,
        name,
        onGaming
      );

      // 多重クリック抑止: 処理中フラグを下げる
      processing.current = false;
    },
    [activeGameId, collectionGame, name, onGaming]
  );

  // start button for single play
  const singleStartDisabled = name === '' || name.length > 24;

  // submit button disabled
  const submitBtnDisabled =
    singleStartDisabled ||
    idInc === '' ||
    idNum === '' ||
    idChar === '' ||
    accessCode === '' ||
    processing.current;

  return (
    <>
      <CenterDiv marginTop="M">
        <MaxWidthAdjust>
          <Paper elevation={3} className={classes.paper}>
            <div className={classes.paperTitle}>
              <Typography variant="h6">ゲームモード</Typography>
            </div>

            <Typography className={classes.content}>
              {mode === 's' ? 'シングルプレイ' : 'グループプレイ'}
            </Typography>
            <CenterDiv marginTop="none">
              <Typography className={classes.contentSub}>
                {mode === 's'
                  ? 'ひとりでプレイするモードです。'
                  : 'ファシリテーターの案内にそってプレイするモードです。'}
              </Typography>
            </CenterDiv>
          </Paper>

          {!playerName && (
            <>
              <div className={classes.descBlock}>
                <GameDescription>
                  プレイヤー名を入力してください。
                </GameDescription>
              </div>

              <CenterDiv>
                <TextField
                  name="playerNameEl"
                  type="text"
                  fullWidth
                  label="プレイヤー名（24文字以内）"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  helperText={nameHelperText}
                  error={nameError}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              </CenterDiv>
            </>
          )}

          {mode === 's' && (
            <div className={classes.buttonBlock}>
              <div className="buttonWrap leftBtn">
                <TransparentButton color="gray" onClick={handleClickBack}>
                  キャンセル
                </TransparentButton>
              </div>
              <div className="buttonWrap rightBtn">
                <MainSelectButton
                  onClick={handleClickSingle}
                  disabled={singleStartDisabled}
                >
                  スタート
                </MainSelectButton>
              </div>
            </div>
          )}

          {mode === 'g' && (
            <>
              <div className={classes.descBlock}>
                <GameDescription>
                  参加するグループのIDとアクセスコードを入力してください。
                </GameDescription>
              </div>

              <form onSubmit={handleSubmit}>
                <div className={classes.idBlock}>
                  <TextField
                    // autoFocus={true}
                    name="idIncEl"
                    type="number"
                    fullWidth
                    label="ID"
                    value={idInc}
                    onChange={(e) => {
                      setIdInc(e.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                  <div>&ensp;-&ensp;</div>
                  <TextField
                    name="idNumEl"
                    type="number"
                    fullWidth
                    // label=""
                    value={idNum}
                    onChange={(e) => {
                      setIdNum(e.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                  <div>&ensp;-&ensp;</div>
                  <TextField
                    name="idCharEl"
                    type="text"
                    fullWidth
                    // label=""
                    value={idChar}
                    onChange={(e) => {
                      setIdChar(e.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </div>

                <div className={classes.idBlock}>
                  <TextField
                    name="accessCodeEl"
                    type="number"
                    fullWidth
                    label="アクセスコード"
                    value={accessCode}
                    onChange={(e) => {
                      setAccessCode(e.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </div>

                <div className={classes.buttonBlock}>
                  <div className="buttonWrap leftBtn">
                    <TransparentButton color="gray" onClick={handleClickBack}>
                      キャンセル
                    </TransparentButton>
                  </div>
                  <div className="buttonWrap rightBtn">
                    <MainSelectButton
                      submit={true}
                      disabled={submitBtnDisabled}
                    >
                      スタート
                    </MainSelectButton>
                  </div>
                </div>
              </form>
            </>
          )}
        </MaxWidthAdjust>
      </CenterDiv>
    </>
  );
};

export default Preparation;
