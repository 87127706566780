import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { MARGIN_M, MARGIN_S } from '../../consts';

type Props = {
  children?: React.ReactNode;
  marginTop?: 'S' | 'M';
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
  })
);

const LeftDiv: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        marginTop:
          props.marginTop === 'M'
            ? MARGIN_M
            : props.marginTop === 'S'
            ? MARGIN_S
            : 0,
      }}
    >
      {props.children}
    </div>
  );
};

export default LeftDiv;
