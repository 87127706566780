import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  BODY_PADDING,
  COLOR_LINK,
  MARGIN_S,
  MARGIN_XS,
  PAGE_MYPAGE_MANAGER_GROUP,
} from '../../consts';
import CenterDiv from './CenterDiv';

type Props = {
  // React TypeScript で Propsに HTMLタグを入れる方法
  // https://qiita.com/hiropy0123/items/e94a9c784e1d192145cd
  title?: string | Array<string | JSX.Element>;
  marginTop?: 'L' | 'XL';
  marginBottom?: 'L' | 'XL';
  children?: React.ReactNode;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: MARGIN_S,
      padding: BODY_PADDING,
    },
    link: {
      color: COLOR_LINK,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    title: {
      marginTop: MARGIN_XS,
      marginBottom: MARGIN_S,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  })
);

const BaseContainerGroupActive: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CenterDiv marginTop="none">
        <Link to={PAGE_MYPAGE_MANAGER_GROUP}>
          <Typography className={classes.link}>グループ管理に戻る</Typography>
        </Link>
      </CenterDiv>

      <Typography variant="h5" className={classes.title}>
        {props.title}
      </Typography>
      {props.children}
    </div>
  );
};

export default BaseContainerGroupActive;
