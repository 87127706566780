import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Fade,
  Modal,
  Backdrop,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Group, Person } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import MainButton from '../components/shared/MainButton';
import {
  COLOR_LIGHT_BLACK,
  COLOR_LINK,
  MARGIN_S,
  MARGIN_XS,
  PAGE_GAME,
  PAGE_GAME_GROUP,
} from '../consts';
import { FirebaseContext } from '../contexts';
import CenterDiv from '../components/shared/CenterDiv';
import MainFreeButton from '../components/shared/MainFreeButton';
import WhiteButton from '../components/shared/WhiteButton';
import MiraizTop from '../components/html/pages/MIRAIZTOP/MiraizTop';
import Recommend from '../components/shared/Recommend';
import BackToTop from '../components/shared/BackToTop';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      '& .__topHead': {
        position: 'relative',
        '& .__topImg': {
          '& img': {
            width: '100%',
          },
        },
        '& .__startButton': {
          position: 'absolute',
          [theme.breakpoints.down('xs')]: {
            width: '80%',
            maxWidth: 220,
            top: '72%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          },
          [theme.breakpoints.up('sm')]: {
            width: '80%',
            maxWidth: 350,
            top: '68%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          },
          [theme.breakpoints.up('md')]: {
            width: '26%',
            maxWidth: 'none',
            top: '56%',
            left: '4.6%',
            transform: 'none',
          },
          '& span': {
            display: 'block',
          },
          '& .__topDesc': {
            [theme.breakpoints.down('xs')]: {
              fontSize: 12,
            },
            [theme.breakpoints.up('sm')]: {
              fontSize: 14,
            },
          },
          '& .__mainDesc': {
            fontWeight: 'bold',
            [theme.breakpoints.down('xs')]: {
              fontSize: 18,
            },
            [theme.breakpoints.up('sm')]: {
              fontSize: 20,
            },
          },
          '& .__icon': {
            marginRight: 5,
          },
        },
        '& .__singleButton': {
          position: 'absolute',
          [theme.breakpoints.down('xs')]: {
            width: '80%',
            maxWidth: 220,
            top: '68%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          },
          [theme.breakpoints.up('sm')]: {
            width: '80%',
            maxWidth: 350,
            top: '65%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          },
          [theme.breakpoints.up('md')]: {
            width: '26%',
            maxWidth: 'none',
            top: '56%',
            left: '4.6%',
            transform: 'none',
          },
          '& .__icon': {
            marginRight: 5,
            width: 24,
            height: 24,
          },
        },
        '& .__groupButton': {
          position: 'absolute',
          [theme.breakpoints.down('xs')]: {
            width: '80%',
            maxWidth: 220,
            top: '87%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          },
          [theme.breakpoints.up('sm')]: {
            width: '80%',
            maxWidth: 350,
            top: '77.5%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          },
          [theme.breakpoints.up('md')]: {
            width: '26%',
            maxWidth: 'none',
            top: '70.3%',
            left: '4.6%',
            transform: 'none',
          },
          '& .__icon': {
            marginRight: 5,
            width: 24,
            height: 24,
          },
        },
      },
    },

    link: {
      color: COLOR_LINK,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },

    waitVerify: {
      marginTop: MARGIN_S,
      width: '90%',
      maxWidth: 380,
      margin: '0 auto',
      '& p': {
        fontSize: 14,
        marginBottom: MARGIN_XS,
      },
      '& button': {},
    },

    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .__paper': {
        position: 'relative',
        outline: 0,
        width: '80%',
        maxWidth: 460,
        maxHeight: '90%', // この設定によりoverflowが有効となる。
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        padding: '45px 20px 40px',
        '& .__topButton': {
          marginBottom: 28,
        },
        '& .__marginBottom': {
          marginBottom: 10,
          fontSize: 12,
        },
      },
    },
    closeBox: {
      position: 'absolute',
      top: '0.7%',
      right: '0.7%',
      '& .__close': {
        padding: 0,
        '& .__icon': {
          fontSize: 36,
          color: COLOR_LIGHT_BLACK,
          margin: 6,
        },
      },
    },
    video: {
      position: 'relative',
      width: '94%',
      maxWidth: 1000,
      paddingTop: '56.25%',
      marginTop: 40,
      '& iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
    },
  })
);

const Home: React.FC = () => {
  const { user } = useContext(FirebaseContext);
  const classes = useStyles();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();

  const topAnchor = 'top-anchor';

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickToSingle = () => {
    history.push(PAGE_GAME);
  };
  const handleClickToGroup = () => {
    history.push(PAGE_GAME_GROUP);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  // 匿名ログインは user.providerData.length が空配列となるので、対象外となる。
  if (user && user.providerData.length && !user?.emailVerified) {
    return (
      <div className={classes.waitVerify}>
        <Typography>
          ご登録のメールアドレスに認証用のメールを送信しました。
          <br />
          メール本文のリンクを選択して認証を完了してください。
        </Typography>
        <Typography style={{ textAlign: 'center' }}>
          ご登録のメールアドレス
          <br />
          {user?.email}
        </Typography>
        <Typography>
          リンクの期限切れ等の場合、下記ボタンからメールを再送信できます。
        </Typography>
        <CenterDiv>
          <WhiteButton fullWidth={false} onClick="mailSendEmailVerify">
            再送信
          </WhiteButton>
        </CenterDiv>
      </div>
    );
  }

  return (
    <>
      <div className={classes.top}>
        <div className="__topHead" id={topAnchor}>
          <div className="__topImg">
            <img
              src={
                isSmDown
                  ? `${process.env.PUBLIC_URL}/assets/svg/miraiz-top-sp.svg`
                  : `${process.env.PUBLIC_URL}/assets/svg/miraiz-top.svg`
              }
              alt="top-img"
            />
          </div>

          {user?.uid ? (
            <>
              <div className="__singleButton">
                <MainFreeButton
                  fontSize={isXsDown ? 16 : 20}
                  border={isSmDown ? 'w1' : undefined}
                  onClick={handleClickToSingle}
                >
                  <Person className="__icon" />
                  シングルプレイ
                </MainFreeButton>
              </div>
              <div className="__groupButton">
                <MainFreeButton
                  fontSize={isXsDown ? 16 : 20}
                  border={isSmDown ? 'w1' : undefined}
                  onClick={handleClickToGroup}
                >
                  <Group className="__icon" />
                  グループプレイ
                </MainFreeButton>
              </div>
            </>
          ) : (
            <>
              <div className="__startButton">
                <MainFreeButton
                  border={isSmDown ? 'w1' : undefined}
                  onClick={handleOpen}
                >
                  <span className="__topDesc">新規登録・ログインをして</span>
                  <span className="__mainDesc">ゲームを始める</span>
                </MainFreeButton>
              </div>
            </>
          )}
        </div>

        <Modal
          aria-labelledby="1on1"
          aria-describedby="text-for-1on1"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          className={classes.modal}
        >
          <Fade in={open}>
            <div className="__paper">
              <div className={classes.closeBox}>
                <IconButton
                  aria-label="close"
                  className="__close"
                  onClick={handleClose}
                >
                  <CloseIcon className="__icon" />
                </IconButton>
              </div>

              <div className="__topButton">
                <CenterDiv marginTop="none">
                  <Typography variant="body2" className="__marginBottom">
                    初めての方はこちらからアカウントをご登録ください。
                  </Typography>
                </CenterDiv>
                <MainButton onClick="toRegister" fontSize={14}>
                  新規登録
                </MainButton>
              </div>
              <div>
                <CenterDiv marginTop="min">
                  <Typography variant="body2" className="__marginBottom">
                    すでにアカウントをお持ちの方はこちらからログインしてください。
                  </Typography>
                </CenterDiv>
                <MainButton onClick="toLogin" fontSize={14}>
                  ログイン
                </MainButton>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>

      <CenterDiv marginTop="none">
        <div className={classes.video}>
          <iframe
            title="MIRAIZ説明動画"
            src="https://player.vimeo.com/video/687058810?h=f5c711c40e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            // src="https://player.vimeo.com/video/693786706?h=e6c7d8e843" // ショートバージョン
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen"
          ></iframe>
        </div>
      </CenterDiv>

      {!loading && (
        <>
          <MiraizTop />
          <Recommend />
          <BackToTop anchorId={topAnchor} />
        </>
      )}
    </>
  );
};

export default Home;
