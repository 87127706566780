import React, { useEffect, useContext } from 'react';
import { Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import BaseContainer from '../../components/shared/BaseContainer';
import BlackButton from '../../components/shared/BlackButton';
import CenterDiv from '../../components/shared/CenterDiv';
import MaxWidthAdjust from '../../components/shared/MaxWidthAdjust';
import { collectionName, COLOR_WARNING } from '../../consts';
import { FirebaseContext } from '../../contexts';
import MainFreeButton from '../../components/shared/MainFreeButton';
import { cancelMiraiz, deleteActiveGame } from '../../firebase';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warningText: {
      color: COLOR_WARNING,
      fontWeight: 'bold',
    },
  })
);

const Cancel: React.FC = () => {
  const { user } = useContext(FirebaseContext);
  const classes = useStyles();

  const handleClick = () => {
    if (user) {
      const check = window.confirm(
        '最終確認です。\nアカウントを削除し、退会処理を実行しますか？　※[OK]を押すとやり直しできません。'
      );
      if (check) {
        const cancel = async () => {
          await deleteActiveGame(collectionName.activeGames, user.uid);
          await deleteActiveGame(collectionName.activeGroupGames, user.uid);
          await cancelMiraiz(user, user.uid);
        };
        cancel();
      }
    } else {
      alert('Error: ログイン状態が無効です。');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BaseContainer title="退会処理">
      <CenterDiv marginTop="none">
        <MaxWidthAdjust>
          <Typography className={classes.warningText}>
            退会しますと、ご登録のアカウントは削除され、ご自身でプレイした過去のゲーム履歴は二度と閲覧できなくなります。
            <br />
            ご了承いただけましたら、下記ボタンを押してお手続きください。
          </Typography>
        </MaxWidthAdjust>
      </CenterDiv>

      <CenterDiv marginTop="XS">
        <MainFreeButton onClick={handleClick}>退会する</MainFreeButton>
      </CenterDiv>

      <CenterDiv marginTop="L">
        <MaxWidthAdjust>
          <BlackButton onClick="toHome">ホームに戻る</BlackButton>
        </MaxWidthAdjust>
      </CenterDiv>
    </BaseContainer>
  );
};

export default Cancel;
