import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import BaseContainer from '../../components/shared/BaseContainer';
import BlackButton from '../../components/shared/BlackButton';
import CenterDiv from '../../components/shared/CenterDiv';
import MaxWidthAdjust from '../../components/shared/MaxWidthAdjust';
import { COLOR_MAIN, FONT_SIZE_M } from '../../consts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontSize: FONT_SIZE_M,
    },
    contentNum: {
      color: COLOR_MAIN,
      fontWeight: 'bold',
      fontSize: 16,
      marginRight: '0.6rem',
    },
  })
);

const Terms: React.FC = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BaseContainer title="利用規約">
      <CenterDiv marginTop="M">
        <Typography className={classes.text}>
          株式会社NEWONE（以下「当社」といいます。）は当社が運営するWEBサービス「MIRAIZ（ミライズ）」（以下「当サービス」といいます。）の利用について以下のとおり定めます。
          <br />
          <br />
          <span className={classes.contentNum}>1.</span>利用規約の適用範囲
          <br />
          当社が定める「利用規約」（以下「本規約」といいます。）は、当サービス内すべての利用について適用されます。当サービスを利用いただく際は、本規約すべてにご同意いただいたものとみなします。
          <br />
          <br />
          <span className={classes.contentNum}>2.</span>個人情報の取得・管理
          <br />
          個人情報の取得・管理は、別途定める当社のプライバシーポリシーの定めるところにより、適正かつ適法に取り扱うものとします。
          <br />
          <br />
          <span className={classes.contentNum}>3.</span>
          ログイン名・パスワードの管理
          <br />
          当サービスでは「会員」を設け、会員は本人が登録時に任意に設定するログイン名とパスワードで本人の認証をするものとし、認証後の結果において、万一利用者に不利益等が生じた場合でも当社は一切責任を負わないものとします。ログイン名・パスワードは利用者の責任において設定・管理するものとし、ログイン名・パスワードは第三者に利用させたり、貸与、譲渡、売買等をすることはできないものとします。ログイン名・パスワードの管理不十分、使用上の過誤、また、利用後のログアウト操作忘却、第三者の使用等による損害の責任は利用者が負うものとし、当社は一切責任を負わないものとします。
          <br />
          <br />
          <span className={classes.contentNum}>4.</span>著作権について
          <br />
          当サービスに含まれるすべてのコンテンツ（テロップ、BGM、スタンプなど）の著作権は、当社またはコンテンツ提供者に帰属します。当サービス機能内の動画装飾での利用や著作権法で定められている一定の例外的な場合を除き、著作権者の承諾を得ず、当サービスが提供する情報を使用または公開することはできません。
          <br />
          <br />
          <span className={classes.contentNum}>5.</span>サービスの変更・停止
          <br />
          当社は、当サービスの全てまたは一部のサービスをいつでも、変更または停止することができるものとします。サービス変更・停止の際、当社はできうる限りの方法で、利用者に対してその旨を事前に告知するものとします。但し、天災などやむを得ぬ場合は事前に告知することなく、サービスを変更・停止できるものとします。
          <br />
          <br />
          <span className={classes.contentNum}>6.</span>保証・免責事項
          <br />
          1.本サービスの一時的な中断・停止、終了、利用不能、本規約の変更その他本サービスに関して利用者に損害が発生した場合でも、法令に定める場合または当社の故意または重大な過失を原因とする場合を除き、利用者に対して責任を負わず、かつ、一切の損害賠償を行いません。当社が何らかの理由により責任を負う場合であっても、当社は利用者に生じた直接的な損害に限り賠償に応じるものとし、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については賠償する責任を負わないものとします。
          <br />
          <br />
          2.利用者は、本サービスから得た情報に基づく行為および行為の結果について、当該行為を自己が行ったか否かを問わず、一切の責任を負うものとし、当社に対し、異議またはいかなる請求も行わないものとします。
          <br />
          <br />
          3.当社のサービスにおいて、広告などにより当社以外の第三者が独自に個人情報を収集する場合がございます。このような場合のお客様の個人情報の取り扱いにつきましても、当社では責任を負いかねますのでご注意ください。
          <br />
          <br />
          <span className={classes.contentNum}>7.</span>通知または連絡
          <br />
          お客様が当社への連絡を希望される場合には、お問い合わせページまたは当社が指定するメールアドレスあてのメールによって行っていただくものとします。当社は、お客様からのお問い合わせに対する回答を原則としてメールのみで行います。
          <br />
          <br />
          <span className={classes.contentNum}>8.</span>本規約等の変更
          <br />
          当社は、登録会員への予告なく、本規約等を変更することができるものとします。なお、変更後の本規約等は、変更事項を本件サービス上で告知した時点、又は当社が別途指定した時点から効力を生じるものとし、登録会員はこの旨、予め異議なく承諾するものとします。
          <br />
          <br />
          <span className={classes.contentNum}>9.</span>損害賠償
          <br />
          利用者が本規約に反した行為、もしくは不正・違法な行為により当社に損害を与えた場合は、当社は利用者に対して相応の損害賠償の請求ができるものとします。また利用者が本規約等に違反したと認められる場合、その他当社が必要と認める場合は、当社は当該利用者に対しサービスの停止・IDや資格のはく奪を実施することができるものとします。また、これらの措置により利用者に不利益・損害が発生した場合においても当社はその責任を負いません。
          <br />
          <br />
          2021年8月1日
        </Typography>
      </CenterDiv>
      <CenterDiv marginTop="M">
        <MaxWidthAdjust>
          <BlackButton onClick="toHome">ホームに戻る</BlackButton>
        </MaxWidthAdjust>
      </CenterDiv>
    </BaseContainer>
  );
};

export default Terms;
