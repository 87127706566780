import React from 'react';
import { COLOR_BLACK, COLOR_GHOST_WHITE } from '../../consts';
import BasicButton, { CustomProps } from './atoms/BasicButton';

type Props = CustomProps;

const WhiteButton: React.FC<Props> = (props) => {
  return (
    <div>
      <BasicButton
        variant="outlined"
        bgColor={COLOR_GHOST_WHITE}
        fontColor={COLOR_BLACK}
        {...props}
      >
        {props.children}
      </BasicButton>
    </div>
  );
};

export default WhiteButton;
